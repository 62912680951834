import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TwinCard from "../../Components/Cards/TwinCard/TwinCard";

import "./PainelTwins.css";
import {
 
  setDigitalTwin,
} from "../../store/slices/digitalTwinSlice";
import {
  getDigitalTwinsList,
  
  
} from "../../store/slices/digitalTwinsListSlice";

import paths from "../../router/paths";


const PainelTwins = () => {

  const navigate = useNavigate();
 
  const dispatch = useDispatch();

  const digitalTwinsList = useSelector(getDigitalTwinsList);
 


 

  useEffect(() => {
    digitalTwinsList.length === 1 && dispatch(setDigitalTwin(digitalTwinsList[0])) && navigate(paths.PANEL_MONITORING)
    
    }
  , [digitalTwinsList, dispatch, navigate]);



  return (
    <Box
      sx={{
      display: "flex",
      padding: "10px",
      gap: "10px",
     
    }}
    >
      {digitalTwinsList
          .map((digitalTwin, i) => {
            return <TwinCard key={digitalTwin.id} digitalTwin={digitalTwin} />
          })
          .reverse()}
    </Box>
  );
};

export default PainelTwins;
