import { createSlice } from "@reduxjs/toolkit";

interface Sensors {
  data: Array<any>;
}

const initialState: Sensors = {
  data: [],
};

export const sensorsSlice = createSlice({
  name: "sensors",
  initialState,
  reducers: {
    setSensors: (state, action) => {
      state.data = action.payload;
    },
    resetSensors: (state) => {
      state.data = { ...initialState.data };
    },
  },
});

export const getSensors = (state) => state.sensors.data;
export const getIsSensorsDefined = (state) => state.sensors.data.length > 0;

export const { setSensors, resetSensors } = sensorsSlice.actions;
export default sensorsSlice.reducer;
