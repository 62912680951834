import axios from "axios"
import moment from "moment"

export const checkAPIStatus = async (sensorID: number, dataObject: any) => {
  //Criando data atual
  const currentDate = moment()
  //Checando se o objeto é válido e tem timestamp
  if(dataObject && dataObject.timestamp){
    const lastDataPoint = moment(dataObject.timestamp)
    const diff = currentDate.diff(lastDataPoint, 'hours')
    //Calculando diferença entre o último timestamp e a data atual
    if(diff>=1){
      //Se a diferença for maior que 1 hora, mando email
      const axiosResponse = await axios.post('/apistatus/sendalert', {delay: diff, lastDataPoint, currentDate, sensorID})
      return axiosResponse.status
    }
  }else{
    //Se não tem objeto, quer dizer que não tem dados dentro das 48 hrs, então nem me preocupo em dizer quando o foi o último ponto de dado
    const axiosResponse = await axios.post('/apistatus/sendalert', {moreThan48Hours: true,currentDate, sensorID})
    return axiosResponse.status
  }
  return 'undefined object'

}