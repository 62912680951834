import { createSlice } from "@reduxjs/toolkit";

interface Company {
  id: string;
  name: string;
}

interface Companies {
  data: Array<Company>;
}
const initialState: Companies = {
  data: [],
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.data = action.payload;
    },
    resetCompanies: (state) => {
      state.data = { ...initialState.data };
    },
  },
});

export const getCompanies = (state) => state.companies.data;
export const getIsCompaniesDefined = (state) => state.companies.data.length > 0;

export const { setCompanies, resetCompanies } = companiesSlice.actions;
export default companiesSlice.reducer;
