import CssBaseline from "@mui/material/CssBaseline";

import { useCallback, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "./i18n/i18n";
import ConditionalRoute from "./router/ConditionalRoute";
import paths from "./router/paths";
import useRoutes from "./router/useRoutes";
import { useDispatch } from "react-redux";
import { setToken } from "./store/slices/userSlice";
import { ThemeProvider } from '../src/Components/ThemeContext.jsx';



const App: React.FC = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const loginControl = useCallback(() => {
    if (location.pathname === paths.RESET_PASSWORD) {
      dispatch(
        setToken(
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYWU1OWQyYjc1Y2JiOTY5ZDQzMzIyNSIsImlhdCI6MTYzODgxNjIxMSwiZXhwIjoxNjM4OTAyNjExfQ.aehfXrNHcuBHJaXSZSfetiNWMLBl2xIkKKk9q83PSb0"
        )
      );
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    loginControl();
  }, [loginControl]);

  return (
    <ThemeProvider>
   
      <CssBaseline />
      <Routes>
        {useRoutes().map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ConditionalRoute {...route} />}
          />
        ))}
      </Routes>
   
    </ThemeProvider>
  );
};

export default App;
