import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import AlertsAlarmsService from "../Services/AlertsAlarmsService";

interface GetAlarmedChartAreaProps {
  chart: any;
  setStatus: Dispatch<
  SetStateAction<{
    status: string;
    css: string;
  }>
  >;
}

const GetAlarmedChartArea = ({
  chart,
  setStatus,
}: GetAlarmedChartAreaProps): Promise<any[]> | undefined => {
  let alerts_alarms: any = [];
  

  alerts_alarms = chart.alerts_alarms.map((element) => {
    return {
      id: element.id,
      name: element.name,
      number: element.number,
      deviceId: element.device_id,
      data: [],
      unit: element.unit.abbreviation,
    };
  });

  if (chart.default_visualization === "line") {
    //checa se o eixo x deve ser o tempo
    if (chart.x_axis_sensor_id === null) {
      return fetchAlertsAlarms(alerts_alarms, setStatus);
    }
  }
};

const fetchAlertsAlarms = async (data: any, setStatus): Promise<any[]> => {
  let markedAlarms: any = [];
  let markedAlerts: any = [];
  let notOnAlert = true;
  let notOnAlarm = true;

  

  for (const element of data) {
    const alerts = await AlertsAlarmsService.getAlertsHours(element.id, 6);
    if (alerts.findIndex((e) => e.finished === false) !== -1) {
      if (alerts[0].finished === false) {
        notOnAlert = false;
        setStatus({ status: "alert", css: "solid 4px yellow" });
      }
      markedAlerts = getMarked(alerts, "rgba(247, 232, 99, 0.4)");
    }

    const alarms = await AlertsAlarmsService.getAlarmsHours(element.id, 6);

    if (alarms.length > 0) {
      if (alarms.findIndex((e) => e.finished === false) !== -1) {
        notOnAlarm = false;
        setStatus({ status: "alarm", css: "solid 4px #C04040" });
      }

      markedAlarms = getMarked(alarms, "rgba(255, 173, 177, 0.4)");
    }

    if (notOnAlarm && notOnAlert) {
      
      setStatus({ status: "normal", css:"0px #E7E7E7 solid" });
    }
  }
  return markedAlarms.concat(markedAlerts);
};

const getMarked = (alerts: any, color: string) => {
  if (alerts.length > 0) {
    const aux = alerts.map((element) => {
      if (element !== undefined) {
        if (element.finished) {
          return [
            {
              xAxis: moment(element.first_alert_time)
                .add(-3, "hours")
                .toJSON()
                .slice(0, -5),
              itemStyle: {
                color: color,
              },
            },
            {
              xAxis: moment(element.last_alert_time)
                .add(-3, "hours")
                .toJSON()
                .slice(0, -5),
            },
          ];
        } else {
          return [
            {
              xAxis: moment(element.first_alert_time)
                .add(-3, "hours")
                .toJSON()
                .slice(0, -5),
              itemStyle: {
                color: color,
              },
            },
            {
              xAxis: "max",
            },
          ];
        }
      }
      return element;
    });
    return aux;
  }
};

export default GetAlarmedChartArea;
