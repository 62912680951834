// import { CatchingPokemon } from "@mui/icons-material";
import axios from "axios";
import { Moment } from "moment";

const LocalDataService = {
  getAlertsFromDB: async function (id: number, dates: any) {
    if (dates) {
      const axiosResponse = await axios.get(
        `/alerts?digital_twin_id=${id}&gt=${dates[0].toISOString()}&lt=${dates[1].toISOString()}`
      );
      //Esse reverse() é pra retornar os dados do mais recente pro mais antigo. O padrão é o contrário.
      return axiosResponse.data.reverse();
    }
  },
  getAlertFromDBById: async function (id: number) {
    const axiosResponse = await axios.get(`/alerts/${id}`);
    // console.log(axiosResponse.data);
    return axiosResponse.data;
  },
  getAlertForToday: async function (id: number) {
    const axiosResponse = await axios.get(`/alerts/today/${id}`);
    return axiosResponse.data;
  },

  get_groups: async function () {
    const axiosResponse = await axios.get(`/groups/`);
    return axiosResponse.data;
  },
  get_group: async function (id: string) {
    const axiosResponse = await axios.get(`/groups/${id}`);
    return axiosResponse.data;
  },
  get_users_from_company: async function (company_id: string) {
    const axiosResponse = await axios.get(`/companies/users/${company_id}`);
    // console.log(axiosResponse.data.users);
    return axiosResponse.data.users;
  },
  get_sensors_from_company: async function (company_id: string) {
    const axiosResponse = await axios.get(`/companies/${company_id}/sensors`);
    // // console.log(axiosResponse.data.users);
    return axiosResponse.data;
  },
  get_digitalTwins: async function (company_id: string) {
    const axiosResponse = await axios.get(`/digitalTwin/company/${company_id}`);
    return axiosResponse.data;
  },

  get_digitalTwin: async function (dt_id: any) {
    // console.log("00000000000000");
    const axiosResponse = await axios.get(`/digitalTwin/${dt_id}`);
    // // console.log(axiosResponse.data);
    return axiosResponse.data;
  },

  get_graphs: async function (sensor_id: string) {
    // console.log("111111111111111111");

    const axiosResponse = await axios.get(`/digitalTwin/${sensor_id}`);
    return axiosResponse.data;
  },
  store_group: async (name: string, logo?: any) => {
    const axiosResponse = await axios.post(`/groups`, {
      name,
      logo,
    });
    return axiosResponse.data;
  },

  store_country: async function (
    name: string,
    continent: string,
    date: number
  ) {
    const axiosResponse = await axios.post("/countries", {
      name,
      acronym: name.substring(0, 3).toUpperCase(),
      date,
      continent,
    });
    return axiosResponse;
  },
  store_state: async function (name: string, date: number, country_id: number) {
    const axiosResponse = await axios.post(`/states/${country_id}`, {
      name,
      acronym: name.substring(0, 2).toUpperCase(),
      date,
    });
    return axiosResponse;
  },
  store_city: async function (name: string, date: number, state_id: number) {
    const axiosResponse = await axios.post(`/cities/${state_id}`, {
      name,
      date,
    });
    return axiosResponse;
  },
  store_company: async function (
    name: string,
    group_id: number,
    city_id: number,
    cnpj: string,
    address: string,
    number: string,
    complement: string,
    neighborhood: string,
    zipcode: string,
    date: number
  ) {
    try {
      const axiosResponse = await axios.post(
        `/companies/${group_id}/${city_id}`,
        {
          name,
          cnpj,
          address,
          number,
          complement,
          neighborhood,
          zipcode,
          date,
        }
      );
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  update_company: async function (
    id: number,
    name: string,
    cnpj: string,
    address: string,
    number: string,
    complement: string,
    neighborhood: string,
    zipcode: string,
    date: number
  ) {
    try {
      const axiosResponse = await axios.put(`/companies/${id}`, {
        name,
        cnpj,
        address,
        number,
        complement,
        neighborhood,
        zipcode,
        date,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_dt: async function (
    name: string,
    input_utility: string,
    output_utility: string,
    company_id: number,
    date: number,
    parent_id: number | null
  ) {
    try {
      const axiosResponse = await axios.post(`/digitalTwin/${company_id}/`, {
        name,
        input_utility,
        output_utility,
        date,
        parent_id,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  edit_dt: async function (
    id: number,
    name: string,
    input_utility: string,
    output_utility: string,
    parent_id: number | null
  ) {
    try {
      const axiosResponse = await axios.put(`/digitalTwin/${id}/`, {
        name,
        input_utility,
        output_utility,
        parent_id,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_user: async function (
    name: string,
    email: string,
    company_id: number,
    date: number,
    permition_level: string
  ) {
    try {
      const axiosResponse = await axios.post(`/auth/createNewCompanyUser`, {
        name,
        email,
        user: name,
        date,
        permition_level,
        company_id,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  edit_user: async function (
    user_id: number,
    name: string,
    email: string,
    permition_level: string
  ) {
    try {
      const axiosResponse = await axios.put(`/users/${user_id}`, {
        name,
        email,
        permition_level,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_sensor: async function (
    dt_id: number,
    name: string,
    date: number,
    number: number,
    device_id: string,
    data_type_metadata: string,
    alert_detail: boolean,
    variable: string,
    type_id,
    unit_id,
    resolution: number,
    precedence: number,
    variable_type: string,
    sensor_limits: string,
    sensor_limits_min_value: number | null,
    sensor_limits_max_value: number | null,
    sensor_limits_fixed_value: number | null,

    operation_limits: string,
    operation_limits_min_value: number | null,
    operation_limits_max_value: number | null,
    operation_limits_fixed_value: number | null
  ) {
    try {
      const axiosResponse = await axios.post(`/sensors/${dt_id}/`, {
        name,
        date,
        number,
        device_id,
        data_type_metadata,
        variable,
        alert_detail,
        type_id,
        unit_id,
        resolution,
        precedence,
        variable_type,
        sensor_limits,
        sensor_limits_min_value,
        sensor_limits_max_value,
        sensor_limits_fixed_value,

        operation_limits,
        operation_limits_min_value,
        operation_limits_max_value,
        operation_limits_fixed_value,
      });
      // console.log(axiosResponse.status);
      // console.log(axiosResponse);
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_graph: async function (
    dt_id: number,
    name: string,
    position: string,
    time1: string,
    time2: number,

    //
    x_axis_sensor_id: number | null,
    y_axis_1: Array<number> | null,
    y_axis_2: Array<number> | null,
    z_axis_sensor_id: number | null,
    tooltip: Array<number> | null,
    alertsAlarms: Array<number> | null,
    areas: Array<number> | null,

    mainSensorSpeedometer: number | null,
    secondarySensorsSpeedometer: Array<number> | null,
    speedometerMin: number | null,
    speedometerMax: number | null,
    speedometerBreak1: number | null,
    speedometerBreak2: number | null,
    //pie
    pieSensors: Array<number> | null,
    //bar
    xSensorBar: number | null,
    ySensors1Bar: Array<number> | null,

    default_visualization: string,

    line_visualization: boolean,
    pie_visualization: boolean,
    bar_visualization: boolean,
    speedometer_visualization: boolean
  ) {
    try {
      //  console.log("store_graph");
      const axiosResponse = await axios.post(`/graphs/${dt_id}/`, {
        name,
        position,
        time1,
        time2,

        //line
        x_axis_sensor_id,
        y_axis_1,
        y_axis_2,
        z_axis_sensor_id,
        tooltip,
        alertsAlarms,
        areas,

        //speed
        mainSensorSpeedometer,
        secondarySensorsSpeedometer,
        speedometerMax,
        speedometerMin,
        speedometerBreak1,
        speedometerBreak2,
        //pie
        pieSensors,
        //bar
        xSensorBar,
        ySensors1Bar,
        //
        default_visualization,

        line_visualization,
        pie_visualization,
        bar_visualization,
        speedometer_visualization,
      });
      return axiosResponse;
    } catch (e: any) {
      // console.log(e.response.data);
      return e.response.data;
    }
  },
  deleteGraph: async (grpah_id) => {
    const axiosResponse = await axios.delete(`/graphs/${grpah_id}/`);
    return axiosResponse.data;
  },
  deleteSensor: async (sensor_id) => {
    const axiosResponse = await axios.delete(`/sensors/${sensor_id}/`);
    return axiosResponse.data;
  },
  deleteUser: async (id) => {
    const axiosResponse = await axios.delete(`/users/${id}/`);
    return axiosResponse.data;
  },
  deleteCompany: async (id) => {
    const axiosResponse = await axios.delete(`/companies/${id}/`);
    return axiosResponse.data;
  },
  deleteDigitalTwin: async (id) => {
    const axiosResponse = await axios.delete(`/digitalTwin/${id}`);
    return axiosResponse.data;
  },

  editSensor: async (
    sensorId: number,
    name: string,
    date: number,
    number: number,
    device_id: string,
    alert_detail: boolean,
    variable: string,
    type_id: number,
    unit_id: number,
    resolution: number,
    precedence: number,
    variable_type: string,
    sensor_limits: string,
    sensor_limits_min_value: number | null,
    sensor_limits_max_value: number | null,
    sensor_limits_fixed_value: number | null,

    operation_limits: string,
    operation_limits_min_value: number | null,
    operation_limits_max_value: number | null,
    operation_limits_fixed_value: number | null
  ) => {
    try {
      const axiosResponse = await axios.put(`/sensors/${sensorId}/`, {
        name,
        date,
        number,
        device_id,
        alert_detail,
        variable,
        type_id,
        unit_id,
        resolution,
        precedence,
        variable_type,
        sensor_limits,
        sensor_limits_min_value,
        sensor_limits_max_value,
        sensor_limits_fixed_value,
        operation_limits,
        operation_limits_min_value,
        operation_limits_max_value,
        operation_limits_fixed_value,
      });
      return axiosResponse.data;
    } catch (e: any) {
      return e.response.data;
    }
  },

  editChart: async (chart: {
    id: number;
    name: string;
    time_range: string;
    time: number;
    position_metadata: string;
    default_visualization: string;
    line_visualization: boolean;
    pie_visualization: boolean;
    bar_visualization: boolean;
    speedometer_visualization: boolean;
  }) => {
    try {
      const axiosResponse = await axios.put(`/graphs/${chart.id}/`, {
        name: chart.name,
        time_range: chart.time_range,
        time: chart.time,
        position_metadata: chart.position_metadata,
        default_visualization: chart.default_visualization,
        line_visualization: chart.line_visualization,
        pie_visualization: chart.pie_visualization,
        bar_visualization: chart.bar_visualization,
        speedometer_visualization: chart.speedometer_visualization,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },

  editGraphSensors: async (
    id: number,
    name: string,

    x_axis_sensor_id: number | null,
    y_axis_1: Array<number> | null,
    y_axis_2: Array<number> | null,
    z_axis_sensor_id: number | null,

    default_visualization: string,

    line_visualization: boolean,
    pie_visualization: boolean,
    bar_visualization: boolean,
    speedometer_visualization: boolean
  ) => {
    try {
      const axiosResponse = await axios.put(`/graphs/${id}/`, {
        name,
        x_axis_sensor_id,
        z_axis_sensor_id,
        default_visualization,
        line_visualization,
        pie_visualization,
        bar_visualization,
        speedometer_visualization,
        y_axis_1,
        y_axis_2,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  getAnalyticsTest: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const axiosResponse = await axios.get(
        `/analytics/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );
      return axiosResponse.data;
    } catch (e) {}
  },
  getAnalyticsKpiBars: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const axiosResponse = await axios.get(
        `/analytics/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );
      return axiosResponse.data;
    } catch (e) {
      console.log(e);
    }
  },

  alarmsInfoPeriod: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    const axiosResponse = await axios.get(
      `/analytics/time/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
    return axiosResponse.data;
  },
};

export default LocalDataService;
