import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_LAYOUT = {
    x: 0,
    y: Infinity,
    w: 12,
    h: 1,
  };
interface MonitoringData {
    
    charts: any[];
    layouts: any[];
    selected: any;
    isfullscreen: boolean;
}

interface MonitoringState {
  data: MonitoringData;
}

const initialState: MonitoringState = {
  data: {
    charts: [],
    layouts: [],
    selected: {},
    isfullscreen: false,
  },
};

export const MonitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    setCharts: (state, action) => {
      state.data.charts = action.payload;
      state.data.layouts = action.payload.map((chart, index) => {
        if (chart.position_metadata && chart.position_metadata.length > 0) {
          return {
            i: `${index}`,
            ...JSON.parse(chart.position_metadata),
          };
        } else {
          return DEFAULT_LAYOUT;
        }
      });
    },  
    setLayouts: (state, action) => {
      state.data.layouts = action.payload;
    },
    setSelected: (state, action) => {
      state.data.selected = action.payload;
    },
    setIsFullscreen: (state, action) => {
      state.data.isfullscreen = action.payload;
    },
    resetMonitoring: (state) => {
      state.data = {
        charts: [],
        layouts: [],
        selected: {},
        isfullscreen: false,
      };
    },
  },
});

export const {
  setCharts,
  setLayouts,
  setSelected,
  setIsFullscreen,
  resetMonitoring,
} = MonitoringSlice.actions; // escritas

export const getCharts = (state) => state.monitoring.data.charts;

export const getLayouts = (state) => state.monitoring.data.layouts;

export const getSelected = (state) => state.monitoring.data.selected;

export const getIsFullscreen = (state) => state.monitoring.data.isfullscreen;

export const getMonitoring = (state) => state.monitoring.data;

export const getMonitoringLength = (state) => state.monitoring.data.charts.length;

export const getIsMonitoringDefined = (state) => state.monitoring.data.charts.length > 0;

export const getIsLayoutsDefined = (state) => state.monitoring.data.layouts.length > 0;

export const getIsSelectedDefined = (state) => state.monitoring.data.selected.length > 0;

export const getIsFullscreenDefined = (state) => state.monitoring.data.isfullscreen;


export default MonitoringSlice.reducer; // leituras e escritas

