import { createRef, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { sensorName } from "../../Utils/formatString";
import { showLoading } from "../../Utils/showLoading";
import { hideLoading } from "../../Utils/hideLoading";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import recomendationGraphOptions from "./recomendationGraphOptions";
import moment from "moment";
import { getIsDarkTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import { Box, List, ListItem, useTheme } from "@mui/material";


const AssociatedGraphsBoard = ({
  selected,
  kpi,
  type,
  startTime,
  endTime,
  registeredSensorData,
  finished,
}) => {
  // console.log(registeredSensorData)
  const graphData = registeredSensorData.map((sensor) => {
    sensor.ref = createRef<any>();
    return sensor;
  });
  const theme = useTheme();
  useEffect(() => {
    const graphStart = moment(startTime.slice(0, 19))
      .add(-5, "minutes")
      .add(-3, "hours");
    const graphEnd = moment(endTime.slice(0, 19))
      .add(finished ? 5 : 0, "minutes")
      .add(-3, "hours");

    showLoading(graphData.map((e) => e.ref));
    for (const graph of graphData) {
      NewDi2winDataService.getRawInfoForAlert(
        graph.id,
        graph.deviceId,
        graphStart.toISOString(),
        graphEnd.toISOString()
      ).then((rawData) => {
        if (rawData && rawData.name !== "Error") {
          const data = rawData.map((element) => {
            const timestamp = moment(element.timestamp.slice(0, 19))
              .subtract(3, "hours")
              .format("YYYY-MM-DDTHH:mm:ss");
            if (typeof element.value === "string") {
              return [timestamp, parseFloat(element.value).toFixed(2)];
            }
            if (typeof element.value === "number") {
              return [timestamp, element.value.toFixed(2)];
            }
            return [timestamp, element.value];
          });
          if (graph.id === "1025" && graph.deviceId === "925XX") {
            graph.ref.current
              ?.getEchartsInstance()
              .setOption(
                recomendationGraphOptions(
                  type,
                  data,
                  graph.name,
                  graph.unit,
                  graphStart.toISOString(),
                  graphEnd.toISOString(),
                  moment(startTime).toISOString(),
                  moment(endTime).toISOString(),
                  theme.palette.mode === "dark" ? true : false
                )
              );
          } else {
            graph.ref.current
              ?.getEchartsInstance()
              .setOption(
                recomendationGraphOptions(
                  type,
                  data,
                  sensorName(graph.name !== undefined ? graph.name : " ") + "",
                  graph.unit,
                  graphStart.toISOString(),
                  graphEnd.toISOString(),
                  moment(startTime).toISOString(),
                  moment(endTime).toISOString(),
                  theme.palette.mode === "dark" ? true : false
                )
              );
          }
          hideLoading([graph.ref]);
        }
      });
    }
    // eslint-disable-next-line
  }, [selected]);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start",justifyContent: "start"}}>

      <Box
        sx={{
          opacity: started ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
         
          fontFamily: "Roboto",
          fontWeight: 400,
          
          fontSize: "18px",
         
        }}
      >
        {kpi ? "Impacto do" : "Associados ao"} {type === "alert" ? "Alerta" : "Alarme"}
      </Box>


      <List sx={{
        width: "100%",
        height: "calc(100vh - 410px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        
       }}>

        {graphData.map((chart: any, i: number) => {
          return  <ListItem key={i} sx={{}}>
              <ReactEcharts
                ref={chart.ref}
                theme={theme.palette.mode === "dark" ? "dark" : "light"}
                style={{  height: "260px", width: "100%"}}
                option={{ backgroundColor: "transparent" }}
                
              />
            </ListItem>
          
        })}

      </List>
    </Box>
  );
};

export default AssociatedGraphsBoard;
