import EditUserForm from "../../Forms/NewUserForm/EditUserForm";

import LocalDataService from "../../../Services/LocalDataService";
import DeleteConfirmationDialog from "../../DeleteConfirmatioDialog/DeleteConfirmationDialog";
import { useState } from "react";

const GraphDetailsCard = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleDelete = async () => {
    setModal(true);
  };

  const handleAction = async (event: any) => {
    const { name } = event.target;

    if (name === "confirm") {
      await LocalDataService.deleteUser(props.user.id);
      props.handleResponse();
    } else {
      setModal(false);
    }
  };

  return (
    <div className="GraphDetailsCard">
      <EditUserForm
        user={props.user}
        onClickDelete={handleDelete}
        handleResponse={props.handleResponse}
        cancel={props.cancel}
        activateUser={props.activateUser}
      />
      {modal && (
        <DeleteConfirmationDialog
          text="Certeza que quer deletar?"
          handleAction={handleAction}
        />
      )}
    </div>
  );
};
export default GraphDetailsCard;
