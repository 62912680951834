import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Paper, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import configurableGraphOneYAxis from "../../GraphOptions/configurableGraphOneYAxis";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import DataFormatService from "../../Services/DataFormatService";
import getTurnedOffSensorArea from "../../GraphOptions/GetTurnedOffSensorArea";
import getAlarmedChartArea from "../../GraphOptions/GetAlarmedChartArea";
import GetChartTooltips from "../../GraphOptions/GetChartTooltips";
import { DraggableHandle } from "../../styles";
import { useDispatch, useSelector } from "react-redux";

import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";
import DataService from "../../Services/DataService";



interface CardGraficoProps {

  chart: any;
  width?: number;
  height?: number;

}

const CardGrafico = ({
  
  chart,
  width,
  height,
  
}: CardGraficoProps) => {
 

  // const [lastUpdate, setLastUpdate] = useState(new Date());
  
  const digitalTwin = useSelector(getDigitalTwin);
 
  
  const eChartsRef = useRef(null as any);
  const [started, setStarted] = useState(false);

  const firstRender = useRef(false);
  const [status, setStatus] = useState({
    status: "normal",
    css:  "0px #E7E7E7 solid" 
  });

  const timePreText = useRef("");
  const timeRangeText = useRef("");

  switch (chart.time_range) {
    case "minutes":
      timeRangeText.current = "minutos";
      timePreText.current = "Ultimos";
      if (chart.time === 1) {
        timePreText.current = "Ultimo";
        timeRangeText.current = "minuto";
      }
      break;
    case "hours":
      timePreText.current = "Ultimas";
      timeRangeText.current = "horas";
      if (chart.time === 1) {
        timePreText.current = "Ultima";
        timeRangeText.current = "hora";
      }
      break;
    case "days":
      timeRangeText.current = "dias";
      timePreText.current = "Ultimos";
      if (chart.time === 1) {
        timePreText.current = "Ultimo";
        timeRangeText.current = "dia";
      }
      break;
    default:
  }

  const options = {
  
    backgroundColor: "transparent",
    toolbox: {
      top: 0,
      right: 0,
      show: true,
      feature: {
        saveAsImage: {
          title: "Baixar .png",
          name: `${chart.name}`,
          pixelRatio: 4,
        },
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Voltar",
          },
          yAxisIndex: "all",
          brushStyle: {
            borderWidth: 1,
            color: "rgba(255, 173, 17, 0.4)",
            borderColor: "rgba(255, 173, 17, 1)",
          },
        },
        magicType: {
          title: {
            line: "Linha",
            bar: "Barra",
          },
          type: ["line", "bar"],
        },
        tooltip: {
          show: true,
          backgroundColor: "#222",
          textStyle: {
            fontSize: 12,
          },
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);", // user-defined CSS styles
        },
      },
    },
    title: {
      text: ` ${chart.name}`,
      // subtext: `${lastUpdate.toLocaleDateString()} - ${lastUpdate.toLocaleTimeString()} (${
      //   timePreText.current
      // } ${chart.time} ${timeRangeText.current})`, // Usando a data e hora da última atualização
      left: 0,
      top: 0,
      textStyle: {
        fontWeight: 400,
        fontSize: 15,
        fontFamily: "Roboto",
        whiteSpace:'nowrap',
        // wordWrap: "break-word",
      },
      subtextStyle: {
        fontWeight: 400,
        fontSize: 12,
        align: "left",
        fontFamily: "Roboto",
      },
    },
    series: {
      animation: true,
      animationDuration: 5000,
      animationEasing: " linear",
      animationDelay: 500,
      animationDurationUpdate: 1000,
    },
  };

  const y_axis_1: any = useMemo(
    () =>
      chart.y_axis_1.map((element) => {
        return {
          name: element.name,
          number: element.number,
          deviceId: element.device_id,
          data: [],
          unit: element.unit.abbreviation,
          max: element.sensor_limits_max_value,
          min: element.sensor_limits_min_value,
        };
      }),
    [chart]
  );

  // const updateLastUpdate = () => {
  //   setLastUpdate(new Date());
  // };

  const findLastIndex = (data: any, timestamp: any) => {
    return data.findLastIndex(
      (element: any) => element.timestamp === timestamp
    );
  };

  const processDataElement = (dataElement: any, element: any) => {
    if (element.deviceId === "64d2480e6de9c524" && element.number === "24") {
      const divider = 0.37;
      const valueWithDivider = dataElement.value / divider;
      return [
        dataElement.timestamp,
        DataFormatService.floatInput(valueWithDivider, 2),
      ];
    }
    if (element.deviceId === "b86de0652035eaac" && element.number === "666") {
      const valueWithDivider = 0;
      return [
        dataElement.timestamp,
        DataFormatService.floatInput(valueWithDivider, 2),
      ];
    }
    return [
      dataElement.timestamp,
      DataFormatService.floatInput(dataElement.value, 2),
    ];
  };

  const processHistoricData = useCallback(
    (historicData: any, dataElement: any) => {
      let lastIndex = -1;
      const processedData: Array<any> = [];
      for (let i = 0; i < historicData.length; i++) {
        //ignoring timestamp differences of less than 10s
        //(by setting seconds unit to '0')
        const adjustedTimestamp = Object.assign(
          [],
          historicData[i].timestamp.split(""),
          { 18: "0" }
        ).join("");
        lastIndex = findLastIndex(historicData, historicData[i].timestamp);
        historicData[lastIndex].timestamp = adjustedTimestamp;
        processedData.push(
          processDataElement(historicData[lastIndex], dataElement)
        );
        i = lastIndex;
      }
      return processedData;
    },
    []
  );

  const fetchAuxtData = async () => {
    const offAreaPromise = getTurnedOffSensorArea({
      chart,
    });

    const markAreaPromise = getAlarmedChartArea({
      chart,
      setStatus,
    });

    const tooltipPromise = GetChartTooltips({
      chart,
    });

    await Promise.all([offAreaPromise, markAreaPromise, tooltipPromise]).then(
      (values) => {
        const options = configurableGraphOneYAxis({
          
          yAxis1: y_axis_1,
          gemeo: digitalTwin.name,
          offArea: values[0],
          markArea: values[1],
          tooltip: values[2],
          theme: theme.palette.mode === "dark" ? true : false,
        });

        eChartsRef.current?.getEchartsInstance().setOption(options);
      }
    );
  };

  const fetchHistoricData = async (dataElement: any) => {
    const data: any = await NewDi2winDataService.fetchMonitoringHistoricData(
      dataElement.number,
      dataElement.deviceId,
      chart.time,
      chart.time_range
    );
    return data;
  };

  const theme = useTheme();

  const fetchAxisData = async () => {
    !firstRender.current
      ? eChartsRef.current?.getEchartsInstance().showLoading("default", {
          text: "Carregando...",
          maskColor: "transparent",
        
          
        })
      : eChartsRef.current?.getEchartsInstance().hideLoading();

    for (const dataElement of y_axis_1) {
      const historicData: any = await fetchHistoricData(dataElement);
      if (historicData.length > 0) {
        dataElement.data = processHistoricData(historicData, dataElement);
      }
    }

   

    !firstRender.current && eChartsRef.current?.getEchartsInstance().hideLoading();

    

    firstRender.current = true;
  };

  useEffect(() => {
    fetchAxisData();
    fetchAuxtData();

    const intervalId = setInterval(() => {
      fetchAxisData();
      fetchAuxtData();
    }, 60000); // Repeat every 1 minute

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 1000);
  }, []);



  return (
    <Paper
      elevation={0}
      sx={{
        // opacity: started ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
       
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexWrap: "wrap",
        p: "10px",
        m: "0px",
        
        height: '100%',
        width: '100%',
      }}
    >
      <DraggableHandle className="draggableHandle" title={`${chart.name}`} />

      <ReactEcharts
        ref={eChartsRef}
        theme={theme.palette.mode === "dark" ? "dark" : "light"}
        
        style={{
          
          marginTop: "-50px",
          height: '100%',
          width: '100%',
         
        }}
        option={options}
      />
    </Paper>
  );
};

export default CardGrafico;
