import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Button, Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ReactEcharts from "echarts-for-react";
import { t } from "i18next";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-dates";
import { END_DATE, START_DATE } from "react-dates/constants";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import HistoricDetailsGraph from "../../GraphOptions/historicDetailsGraph";
import LocalDataService from "../../Services/LocalDataService";

import newDi2winDataService from "../../Services/NewDi2winDataService";
import { hideLoading } from "../../Utils/hideLoading";
import { showLoading } from "../../Utils/showLoading";
import { syncGraphsTooltip, syncGraphsZoom } from "../../Utils/syncGraphs";
import {
  getDigitalTwinId,
  getIsDigitalTwinDefined,
} from "../../store/slices/digitalTwinSlice";
import { getDigitalTwinsList } from "../../store/slices/digitalTwinsListSlice";
import "./HistoricDetailsView.css";
import { getIsDarkTheme } from "../../store/slices/themeSlice";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../RecommendationHistoryView/DateRangePickerStyles.css";
import downloadCsvHelper from "../../Utils/downloadCsvHelper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Sensor {
  id: number;
  name: string;
  unit: {
    abbreviation: string;
  };
  status?: boolean;
}

let zoomControl = false;

const HistoricDetailsView = () => {
  const digitalTwinId = useSelector(getDigitalTwinId);
  const isDigitalTwinDefined = useSelector(getIsDigitalTwinDefined);
  const digitalTwins = useSelector(getDigitalTwinsList);

  const [days, setDays] = useState(1);
  const [selectedDigitalTwinId, setSelectedDigitalTwinId] = useState<number>(
    isDigitalTwinDefined ? digitalTwinId : -1
  );

  const [dataToDownload, setDataToDownload] = useState<any>([]);
  const [sensors, setSensors] = useState<any>([]);
  const [selectedSensors, setSelectedSensors] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [focusedInput, setFocusedInput] = useState();
  const chart1Ref = useRef(null as any);
  const chart2Ref = useRef(null as any);
  const chart3Ref = useRef(null as any);
  const [showChart, setShowChart] = useState([false, false, false]);
  const arrayOfCharts = [chart1Ref, chart2Ref, chart3Ref];
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const [dataList, setDataList] = useState<any[]>([]);

  useEffect(() => {
    if (selectedDigitalTwinId !== -1) {
      if (selectedDigitalTwinId !== -1) {
        LocalDataService.get_digitalTwin(selectedDigitalTwinId).then((res) => {
          setSensors(res.sensors);
        });
      }
    }
    return () => {};
  }, [selectedDigitalTwinId]);

  useEffect(() => {
    if (dataList.length != 0) {
      handleDownloadCSV();
    }
  }, [dataList])

  const getAxisLabelFormatter = (element) => {
    if (element.type.name === "booleano") {
      const labels = element.unit.abbreviation.split("/");
      return function (value) {
        if (value === 1) return labels[0];
        if (value === 0) return labels[1];
        else return "";
      };
    }
    return "{value}";
  };

  const reloadDataOnFormSubmit = async () => {
    setDataToDownload([]);
    const dataListAux: any[] = [];

    const days = Math.abs(startDate.diff(endDate, "days"));

    setDays(days + 1);
    if (days === 0) {
      setShowChart([true, false, false]);
    } else if (days === 1) {
      setShowChart([true, true, false]);
    } else if (days === 2) {
      setShowChart([true, true, true]);
    }

    //Filtrando checkboxes marcadas
    const activeSensors = selectedSensors.filter((e) => e.status === true);

    if (selectedSensors.length > 0) {
      await chart1Ref.current?.getEchartsInstance().setOption({}, true);
      await chart2Ref.current?.getEchartsInstance().setOption({}, true);
      await chart3Ref.current?.getEchartsInstance().setOption({}, true);
      showLoading([chart1Ref, chart2Ref, chart3Ref]);
      const showGraphCopy = showChart.slice();
      if (selectedSensors.length > 0) {
        for (let i = 0; i < days + 1; i++) {
          let graphDatas: Array<any> = [];

          const aux = startDate
            .clone()
            .add(i, "days")
            .set({ hour: 0, minute: 0, second: 0 });
          const start = aux.clone();
          const end = aux.clone().endOf("day");

          for (const activeSensor of activeSensors) {
            const isBooleanSensor = activeSensor.type.name === "booleano";
            const activeSensorChartData = await newDi2winDataService.getRawInfo(
              parseInt(activeSensor.number),
              activeSensor.device_id,
              start.add(3, "hours"),
              end.add(3, "hours")
            );

            dataListAux.push(activeSensorChartData);

            const formattedChartData = activeSensorChartData.map(
              (chartDataElement) => {
                if (isBooleanSensor) {
                  const booleanSensorValues = activeSensor.unit.name.split("/");
                  return [
                    moment(chartDataElement.timestamp).toISOString(),
                    chartDataElement.value === false ? 0 : 1,
                    chartDataElement.value === false
                      ? booleanSensorValues[1]
                      : booleanSensorValues[0],
                  ];
                } else {
                  return [
                    moment(chartDataElement.timestamp).toISOString(),
                    chartDataElement.value,
                  ];
                }
              }
            );
            graphDatas.push({
              series: {
                name: activeSensor.name,
                type: "line",
                data: formattedChartData,
                encode: isBooleanSensor && {
                  x: 0,
                  y: 1,
                  tooltip: 2,
                },
                step: isBooleanSensor,
              },
              yAxis: {
                name: activeSensor.unit.abbreviation,
                type: "value",
                alignTicks: true,
                scale: true,
                axisLabel: {
                  formatter: getAxisLabelFormatter(activeSensor),
                  
                },
                axisTick: {
                  show: true,
                  alignWithLabel: true,
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                   
                  },
                },
              },
            });
          }
          setDataList(dataListAux);

          arrayOfCharts[i].current
            ?.getEchartsInstance()
            .setOption(
              HistoricDetailsGraph(
                graphDatas,
                start.subtract(3, "hours").toISOString(),
                end.subtract(3, "hours").toISOString(),
                new Date(
                  startDate.clone().add(i, "days").toISOString()
                ).toLocaleDateString("pt-br"),
                isDarkTheme
              )
            );

          hideLoading([arrayOfCharts[i]]);
        }
        for (let j = 0; j > days + 1; j++) {
          showGraphCopy[j] = true;
        }
        chart1Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart2Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart3Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
        chart2Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart1Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart3Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
        chart3Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart1Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart2Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
      }
      chart1Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;

          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart2Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart3Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
          if (zoomControl === false) {
            if (end - start < 5) {
              zoomControl = true;
              chart1Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
              chart2Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
              chart3Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
            }
          } else {
            if (end - start >= 5) {
              zoomControl = false;
              chart1Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
              chart2Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
              chart3Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
            }
          }
        });
      chart2Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;
          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart1Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart3Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
        });
      chart3Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;
          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart1Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart2Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
        });
    }
  };

  const handleDownloadCSV = () => {
    const localStartDate = startDate.clone().startOf("day");
    const localEndDate = endDate.clone().endOf("day");
    // Lógica para consultar os dados dos sensores selecionados no intervalo de tempo selecionado
    const dataToFetch = selectedSensors.map((sensor) => {
      return {
        name: sensor.name,
        device: sensor.device_id,
        id: sensor.id,
        number: sensor.number,
        startDate: localStartDate,
        endDate: localEndDate,
        unit: sensor.unit.abbreviation,
      };
    });

    dataToFetch.forEach((sensor, index) => {
      setDataToDownload((dataToDownload) => [
        ...dataToDownload,
        {
          name: sensor.name,
          sensor: sensor.id,
          startDate: sensor.startDate.startOf("day"),
          endDate: sensor.endDate,
          unit: sensor.unit,
          rawData: dataList[index],
        },
      ]);
    });
  };

  useEffect(() => {
    syncGraphsZoom([chart1Ref, chart2Ref, chart3Ref], "datazoom");
    return () => {};
  }, [showChart]);

  useEffect(() => {
    syncGraphsTooltip([chart1Ref, chart2Ref, chart3Ref], "mousemove");
    return () => {};
  }, [showChart]);

  const isOutsideRange = (day) => {
    if (focusedInput === END_DATE) {
      const x = day.isAfter(startDate.clone().add(2, "days"));
      const y = day.isBefore(startDate.clone().subtract(3, "days"));
      return x || y || day.isAfter(moment().add(0, "days"));
    }
    return day.isAfter(moment().add(0, "days"));
  };

  const checkMultipleUnitsSelected = (sensors) => {
    const selectedUnits = new Set();
    for (let i = 0; i < sensors.length; i++) {
      const sensor = sensors[i];
      selectedUnits.add(sensor.unit.name);
    }
  };

  useEffect(() => {
    const activeSensors = sensors.filter((e) => e.status === true);
    checkMultipleUnitsSelected(activeSensors);
    return () => {};
  }, [sensors]);

  const compareNames = (a: any, b: any) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  const handleToggleSensor = (sensor: Sensor) => {
    const sensorUnit = sensor.unit.abbreviation;

    if (selectedUnits.includes(sensorUnit) || selectedUnits.length < 2) {
      setSelectedSensors((prevSensors) =>
        prevSensors.map((prevSensor) =>
          prevSensor.id === sensor.id
            ? {
                ...prevSensor,
                status: !prevSensor.status,
              }
            : prevSensor
        )
      );
      const units = (prevUnits) =>
        selectedUnits.includes(sensorUnit)
          ? prevUnits.filter((unit) => unit !== sensorUnit)
          : [...prevUnits, sensorUnit];

      setSelectedUnits(units);
    }
  };

  const handleSensorSelection = (event, value) => {
    const updatedSensors = value.map((sensor) => ({
      ...sensor,
      status: true, // Define como verdadeiro, pois o sensor foi selecionado
    }));
    setSelectedSensors(updatedSensors); // Atualiza o estado dos sensores selecionados
    setSelectedUnits(updatedSensors.map((sensor) => sensor.unit.abbreviation));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        listStyle: "none",
       
        height: "calc(100vh - 127px)",
        width: "calc(100vw - 74px)",
      }}
    >
      <Paper
        elevation={0}
        id="formulario"
        sx={{
         
          gridArea: "Filter",
          height: "calc(100vh - 150px)",
          
          alignContent: "center",
          justifyContent: "space-between",
          padding: "10px",
          m: "10px 10px 0px 10px",
          display: "flex",
          flexDirection: "column",
          width: "400px",
        }}
      >
        <div>
          <div
            id="inputDate"
            style={{
              display: "flex-column",
              justifyContent: "center",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            <div id="inputDateLabel">
              <div
                style={{
                  
                  
                  position: "relative",
                  paddingTop: "20px",
                  width: "auto",
                  pointerEvents: "none",
                  left: "5px",
                  transition: "all .1s linear",
                  backgroundColor: "white",
                  padding: "5px",
                  boxSizing: "border-box",
                }}
              >
                {`${t("historic_data:filterPanelPeriod")}`} :
              </div>
            </div>

            <div
              id="setDate"
              style={{
               
               
                width: "100%",
                height: "58px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
               
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <div >
                <DateRangePicker
                  noBorder
                  minimumNights={0}
                  startDate={startDate}
                  startDateId={START_DATE}
                  endDate={endDate}
                  endDateId={END_DATE}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  isOutsideRange={(day) => isOutsideRange(day)}
                  displayFormat={() => "DD/MM/YYYY"}
                  startDatePlaceholderText="Data Inicial"
                  endDatePlaceholderText="Data Final"
                />
              </div>
            </div>
          </div>

          <div
            id="inputFactory"
            style={{
             
              width: "100%",
              height: "auto",
              justifyContent: "center",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "18px",
              
            }}
          >
            <div
              style={{
                
                
                position: "relative",
                paddingTop: "20px",
                width: "auto",
                pointerEvents: "none",
                left: "5px",
                transition: "all .1s linear",
                backgroundColor: "white",
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              {`${t("historic_data:filterPanelFactory")}`} :
            </div>

            <select
              style={{
                
                
                width: "100%",
                height: "60px",
                
              
                position: "relative",
                paddingLeft: "15px",
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: 300,
              }}
              id="demo-simple-select"
              defaultValue="RecifePE"
            >
              <option value="null"></option>
              <option value="RecifePE">Recife (PE)</option>
            </select>
          </div>

          <div
            id="inputSector"
            style={{
             
             
              width: "100%",
              height: "auto",
              justifyContent: "center",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            <div
              style={{
               
                position: "relative",
                paddingTop: "20px",
                width: "auto",
                pointerEvents: "none",
                left: "5px",
                transition: "all .1s linear",
               
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              {`Gêmeo :`}
            </div>
            {isDigitalTwinDefined && (
              <select
                style={{
                 
                  
                  width: "100%",
                  height: "60px",
                  

                  position: "relative",
                  paddingLeft: "15px",
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
                id="demo-simple-select"
                defaultValue={digitalTwinId}
                onChange={async (e) => {
                  const intIndex = parseInt(e.target.value);
                  setSensors([]);
                  setSelectedDigitalTwinId(intIndex);
                  setSelectedSensors([]);
                  setSelectedUnits([]);
                }}
              >
                {digitalTwins &&
                  digitalTwins.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>

          <div
            id="inputOptions"
            style={{
             
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "space-between",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            <div
              style={{
                
                position: "relative",
                paddingTop: "20px",
                width: "auto",
                pointerEvents: "none",
                left: "5px",
                transition: "all .1s linear",

                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              Sensores:
            </div>
            <Autocomplete
              value={selectedSensors}
              sx={{
                width: "100%",
               
              }}
              multiple
              disableCloseOnSelect
              options={sensors
                .filter(
                  (sensor) =>
                    !selectedSensors.some((selectedSensor) => {
                      return selectedSensor.id === sensor.id;
                    })
                )
                .sort(compareNames)}
              noOptionsText="Carregando..."
              getOptionLabel={(option: any) =>
                option.name + " (" + option.unit.abbreviation + ")"
              }
              renderOption={(props, option, { selected }) =>
                selectedUnits.length >= 2 ? (
                  selectedUnits.includes(option.unit.abbreviation) && (
                    <li
                      {...props}
                      style={{
                        width: "100%",
                       
                      }}
                    >
                      <Checkbox
                        disabled={
                          selectedUnits.length >= 2 &&
                          !selectedUnits.includes(option.unit.abbreviation)
                        }
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={() =>
                          selectedUnits.includes(option.unit.abbreviation) &&
                          handleToggleSensor(option)
                        }
                      />
                      {option.name + " (" + option.unit.abbreviation + ")"}
                    </li>
                  )
                ) : (
                  <li
                    {...props}
                    style={{
                      width: "100%",
                      
                    }}
                  >
                    <Checkbox
                      disabled={
                        selectedUnits.length === 2 &&
                        !selectedUnits.includes(option.unit.abbreviation)
                      }
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      onChange={() =>
                        selectedUnits.includes(option.unit.abbreviation) &&
                        handleToggleSensor(option)
                      }
                    />
                    {option.name + " (" + option.unit.abbreviation + ")"}
                  </li>
                )
              }
              renderInput={(params) => (
                <TextField {...params} label="" placeholder="Buscar" />
              )}
              onChange={handleSensorSelection}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            opacity: `${selectedSensors.length === 0 ? "0.5" : "1"}`,
          }}
        >
          <Button
            type="button"
            disabled={selectedSensors.length === 0}
            onClick={
              selectedSensors.length > 0 ? reloadDataOnFormSubmit : () => {}
            }
            style={{
              
              padding: "10px",
              borderRadius: "6px",
              flexGrow: "1",
              cursor: `${
                selectedSensors.length === 0 ? "not-allowed" : "pointer"
              }`,
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
                margin: "0px",
             
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              Aplicar
            </p>
          </Button>

          {selectedSensors.length > 0 && dataToDownload.length > 0 && (
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
                margin: "0px",

                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                flexWrap: "nowrap",
                
                padding: "10px",
                borderRadius: "6px",
                flexGrow: "1",
                cursor: `${
                  selectedSensors.length === 0 ? "not-allowed" : "pointer"
                }`,
              }}
              onClick={
                dataToDownload.length === 0
                  ? () => {}
                  : () => {
                      downloadCsvHelper(dataToDownload);
                    }
              }
              disabled={
                dataToDownload.length === 0 && selectedSensors.length === 0
              } // Desabilita o botão se nenhum sensor estiver selecionado
            >
              Baixar CSV
            </Button>
          )}
        </div>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          
          gridArea: "Graphs1",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 150px)",
          m: "10px 10px 10px 0px",
          padding: "10px",

          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "18px",

          width: "calc(100vw - 495px)",
        }}
      >
        {showChart[0] === false &&
          showChart[1] === false &&
          showChart[2] === false && (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <span>Consulte as opções ao lado para visualização</span>
            </div>
          )}

        {showChart[0] === true && (
          <ReactEcharts
            theme={isDarkTheme ? "dark" : "light"}
            ref={chart1Ref}
            style={{ height: `calc((100vh - 190px)/${days})` }}
            option={{}}
          />
        )}
        {showChart[1] && (
          <ReactEcharts
            theme={isDarkTheme ? "dark" : "light"}
            ref={chart2Ref}
            style={{ height: `calc((100vh - 190px)/${days})` }}
            option={{}}
          />
        )}
        {showChart[2] === true && (
          <ReactEcharts
            theme={isDarkTheme ? "dark" : "light"}
            ref={chart3Ref}
            style={{ height: `calc((100vh - 190px)/${days})` }}
            option={{}}
          />
        )}
      </Paper>
    </div>
  );
};
export default HistoricDetailsView;
