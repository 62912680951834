
const FaqView = (props:any) => {
  

  return (
    <>
     
      <iframe  src="https://docs.google.com/document/d/1e4s7tKSAyyeq31JO2MQLtKwqYCjyyYogTy8nd4Fg1YE/" title="FAQ" style={{
        height: 'calc(100vh - 72px)',
        width: 'calc(100vw - 70px)',
        border: 'none',
        // backgroundColor:  'red',
      }}
      
      />

    
      
    </>
  );
};

export default FaqView;
