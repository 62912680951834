import moment from "moment";
import NewDi2winDataService from "../Services/NewDi2winDataService";

interface GetTurnedOffSensorAreaProps {
  chart: any;
}

const GetTurnedOffSensorArea = async ({
  chart,
}: GetTurnedOffSensorAreaProps): Promise<any[]> => {
  let areas: any = [];

  areas = chart.areas.map((element) => {
    return {
      name: element.name,
      number: element.number,
      deviceId: element.device_id,
      data: [],
      newData: [],
      unit: element.unit.abbreviation,
    };
  });

  return await fetchAreas(areas, chart);
};

const fetchAreas = async (data: any, chart) => {
  for (const element of data) {
    const res = await NewDi2winDataService.fetchMonitoringHistoricData(
      element.number,
      element.deviceId,
      chart.time,
      chart.time_range
    );
    element.data = res;
  }

  const markedAreas = data.map((e) => {
    return filterOffRanges(e.data);
  });
  return markedAreas;
};

const filterOffRanges = (data: any) => {
  let markedOff: any = [];
  let on = true;
  let offRanges: any = [];
  let range = {
    begin: "",
    end: "",
  };
  for (let i = 0; i < data.length; i++) {
    if (on) {
      //esta ligado
      //desligou - novo range
      if (data[i].value === false) {
        range.begin = data[i].timestamp;
        on = false;
      }
    } else {
      //esta desligado
      //ligou - finaliza range
      if (data[i].value === true) {
        range.end = data[i - 1].timestamp;
        offRanges.push(range);
        on = true;
        range = {
          begin: "",
          end: "",
        };
      }
    }
  }
  if (on === false) {
    range.end = "max";
    offRanges.push(range);
  }

  markedOff = offRanges.map((element) => {
    if (element.end === "max") {
      return [
        {
          xAxis: moment(element.begin)
            .add(-3, "hours")
            .toJSON()
            .replace("000Z", "0-0300"),
          itemStyle: {
            color: "rgba(0, 0, 0, 0.4)",
            broderColor: "rgba(0, 0, 0)",
            borderType: "dashed",
            borderWidth: 2,
          },
        },
        {
          xAxis: "max",
        },
      ];
    } else {
      return [
        {
          xAxis: moment(element.begin)
            .add(-3, "hours")
            .toJSON()
            .replace("000Z", "0-0300"),
          itemStyle: {
            color: "rgba(0, 0, 0, 0.4)",
            broderColor: "rgba(0, 0, 0)",
            borderType: "dashed",
            borderWidth: 2,
          },
        },
        {
          xAxis: moment(element.end)
            .add(-3, "hours")
            .toJSON()
            .replace("000Z", "0-0300"),
        },
      ];
    }
  });

  return markedOff;
};

export default GetTurnedOffSensorArea;
