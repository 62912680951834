import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import loginBackground from "../../Assets/Images/loginBackground2.png";
import util_logo from "../../Assets/Images/logo_fundo_claro.png";
import RecoveryModal from "../../Components/Modals/RecoveryModal";
import makeLogin from "../../Utils/LoginHelper";
import "./LoginView.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const LoginView = () => {
  const dispatch = useDispatch();

  const [isEdge, setIsEdge] = useState(false);
  const [error, setError] = useState(0);
  const [recovery, setRecovery] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const { t } = useTranslation(["login"]);
  useEffect(() => {
    setIsEdge(navigator.userAgent.includes("Edge"));
  }, []);
  const navigate = useNavigate();

  const loginButton = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setStarted(false)
    try {
      //Redirecionando usuário
      navigate(await makeLogin(email, password, dispatch));

      //Esse any aqui é pra funcionar, não faço ideia do pq.
      //Acho que tem algo errado nos typings do Axios
    } catch (err: any) {
      setStarted(true)
      //Selecionando erro adequado para mostrar ao usuário
      let code = 0;
      if (err.response) {
        code = err.response.status;
      }
      switch (code) {
        case 404:
          setError(1);
          break;
        case 457:
          setError(2);
          break;
        default:
          setError(3);
      }

      //Apagando msg de erro após 5 segundos
      setTimeout(() => {
        setError(0);
      }, 5000);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  
  const [started,setStarted] = useState(false)

  useEffect(()=>{
    setTimeout(()=>{
      setStarted(true)

    },Math.random() * 500)}
  ,[])
  
  return (
    <div
      className="loginPage"
      style={{
        backgroundImage: `url(${loginBackground}) `,
        backgroundSize: "cover",
      }}
    >
      <div className="content" style={{
        opacity: started ? 1 :0, 
        transform: `scale(${started ? 1 : 0.95})`,
        transition: "opacity 0.2s ease, transform 0.5s ease" }}>
        <img src={util_logo} alt="Logo Util" className="utilLogo" />

        <form onSubmit={loginButton} className="loginForm">
          <FormControl sx={{}} variant="outlined">
            <TextField
              id="outlined-basic"
              margin="normal"
              label="E-mail"
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormControl>

          <FormControl sx={{}} margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              endAdornment={
                !isEdge ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              label="Senha"
            />
          </FormControl>
          <div className="buttonRow">
            <Link
              underline="hover"
              color={"inherit"}
              onClick={() => {
                setRecovery(true);
              }}
              style={{ cursor: "pointer" }}
            >
              Esqueci minha Senha
            </Link>

            {/* <Button id="loginButton" type="submit">
              Acessar
            </Button> */}
            <button
              type="submit"
              style={{
                border: "0.50px solid #EBEBEB",
                backgroundColor: "#124251",
                color: "#FFF",
                marginTop: "50px",
                padding: "10px",
                borderRadius: "6px",
                flexGrow: "1",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    margin: "0px",
                    color: "#FFF",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    wordWrap: "break-word",
                  }}
                >
                  Acessar
                </p>
              </div>
            </button>
          </div>
          {error === 1 && (
            <span className="error-login">{t("login:nonExistentUser")}</span>
          )}
          {error === 2 && (
            <span className="error-login">{t("login:incorrectUser")}</span>
          )}
          {error === 3 && (
            <span className="error-login">{t("login:internalError")}</span>
          )}
          {error === 4 && (
            <span className="error-login">{t("login:passwordFieldEmpty")}</span>
          )}
        </form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "100px",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </div>

      {recovery && (
        <RecoveryModal
          cancel={() => {
            setRecovery(false);
          }}
        />
      )}
    </div>
  );
};

export default LoginView;
