import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";
import "./SensorCard.css";

const SensorCard = (props: any) => {
  let variableType: string;
  let color: string;

  // console.log(props.sensor)
  switch (props.sensor.variable_type) {
    case "kpi":
      variableType = "KPI";
      color = "#F48117";
      break;
    case "monitoring":
      variableType = "monitoramento";
      color = "#C4C4C4";
      break;
    case "control":
      variableType = "controle";
      color = "#009E73";
      break;
    default:
      variableType = "error";
      color = "#FFF";
      break;
  }

  return (
    <div className="SensorCard">
      <div>
        <div className="line">
          <p>{props.sensor.name} </p>
          <p className="variableType" style={{ backgroundColor: color }}>
            {variableType}
          </p>
        </div>
        <div className="line">
          <p>{props.sensor.variable}</p>
          <p className="unit">({props.sensor.unit.abbreviation})</p>
        </div>
      </div>
      <div onClick={props.onClick} className="icon">
        <Icon />
      </div>
    </div>
  );
};
export default SensorCard;
