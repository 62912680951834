import { Badge, Box, Chip, CircularProgress, List, ListItem, Paper, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import { sensorName } from "../../Utils/formatString";
import { useSelector } from "react-redux";
import { getIsAdmin } from "../../store/slices/userSlice";
import { ReactComponent as CienteOk } from "../../Assets/Icons/cienteOk.svg";
import { ReactComponent as Justify } from "../../Assets/Icons/justify.svg";
import DataService from "../../Services/DataService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";
import moment from "moment";
import unitDictionary from "../../Utils/unitDictionary";

import { getIsDarkTheme } from "../../store/slices/themeSlice";

const RecomendationDetailsBoard = ({
  selected,
  id,
  kpi,
  message,
  type,
  firstValue,
  startTime,
  registeredUser,
  registeredTime,
  socketAddresses,
  ack,
  PrescriptionSensors,
  recomendation,
}: any) => {
 const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);
  const digitalTwin = useSelector(getDigitalTwin);

  const [icon, setIcon] = useState(<></>);
  const [justify, setJustify] = useState(null);
  const [loadingAck, setLoadingAck] = useState(false);
  let recomendationTreated = useMemo<any>(() => [], []);

  const [scenarioVariables, setScenarioVariables] = useState<Array<any>>([]);
  const [scenarioDataList, setScenarioDataList] = useState<Array<any>>([]);
  const arrayOfPrescriptions = PrescriptionSensors[digitalTwin.id];

  useEffect(() => {
    let isComponentMounted = true; // Flag to track mounting status

    setScenarioDataList([]);
    setScenarioVariables([]);

    const fetchData = async () => {
      if (!isComponentMounted) return; // Prevents state update if component is unmounted

      let fetchedDataList: any[] = [];

      if (startTime) {
        const sensors = await DataService.getSensorsWithAlarmDetails(
          digitalTwin.id
        );

        setScenarioVariables(sensors);

        for (let variable of sensors) {
          NewDi2winDataService.getLastNInfoInRange(
            variable.number,
            variable.device_id,
            moment(startTime),
            moment(startTime).add(30, "minute"),
            "1"
          ).then((scenarioData) => {
            let lastData = scenarioData && scenarioData.pop();

            if (lastData && lastData.sensorId === 34) {
              lastData.value = lastData.value ? "Em uso" : "Não está em uso";
            }

            lastData = {
              ...lastData,
              name: variable.name,
              unit:
                variable.type.name === "booleano"
                  ? ""
                  : variable.unit.abbreviation,
            };
            fetchedDataList.push(lastData);
            setScenarioDataList(fetchedDataList);
          });
        }
      }
    };

    fetchData();

    // Cleanup function to update mount status
    return () => {
      isComponentMounted = false;
    };
  }, [startTime, digitalTwin]);

  if (type === "alarme") {
    const jsonRecommendation = JSON.parse(message);
    recomendationTreated = Object.entries(jsonRecommendation).map(
      (element: any) => {
        const aux = socketAddresses.find((e) => e.name === element[0]);
        if (aux !== undefined) {
          return {
            name: element[1].name,
            id: aux.metadata.sensorId,
            deviceId: aux.metadata.deviceId,
            readValue: element[1]["Valor atual"],
            recomendedValue: element[1]["Valor recomendado"],
            unit: element[1].unit,
          };
        } else {
          return {};
        }
      }
    );
  }
  if (type === "alerta") {
    const jsonRecommendation = recomendation;

    recomendationTreated = Object.entries(jsonRecommendation).map(
      (element: any) => {
        const aux = Object.keys(element[1]).find((e) => e === element[1]);
        if (aux !== undefined) {
          return {
            name: element[1].name,
            readValue: element[1]["Valor atual"],
            recomendedValue: element[1]["Valor recomendado"],
            unit: element[1].unit,
          };
        } else {
          return {};
        }
      }
    );

    recomendationTreated =
      jsonRecommendation &&
      Object.entries(jsonRecommendation).map((element: any) => {
        const aux = socketAddresses.find((e) => e.name === element[0]);
        if (aux !== undefined) {
          return {
            name: element[0],
            id: element.sensorId,
            unit: element.unit,
            deviceId: element.deviceId,
            readValue: element[1][0].value,
            recomendedValue: element[1][4].value,
          };
        } else {
          return {};
        }
      });
  }

  const period = `${new Date(startTime).toLocaleDateString()}, ${new Date(
    startTime
  ).toLocaleTimeString()} `;

  let registered = "Não registrado";
  if (registeredUser && registeredTime) {
    registered = `${registeredUser}, ${new Date(
      registeredTime
    ).toLocaleDateString()}, ${new Date(registeredTime).toLocaleTimeString()}`;
  }

  const getTransformedValue = (scenarioData) => {
    const floatValue = parseFloat(scenarioData.value).toFixed(2);
    
    if (scenarioData.sensorId === 17) {
      return (parseFloat(scenarioData.value) / 0.37).toFixed(2);
    }
    

    return floatValue;
  };

  useEffect(() => {
    selected.selected.justification !== null
      ? setJustify(selected.selected.justification)
      : setJustify(null);
  }, [selected.selected.justification]);
  useEffect(() => {
    setIcon(
      (selected.selected.acknowledged = true ? (
        justify === null ? (
          <CienteOk
            style={{ marginLeft: "10px", position: "relative", top: "3px" }}
          />
        ) : (
          <Justify
            style={{ marginLeft: "10px", position: "relative", top: "3px" }}
          />
        )
      ) : (
        <></>
      ))
    );
  }, [selected, justify]);
  return (
    <Paper
      elevation={0}
      sx={{
        
       
       
        
        alignItems: "start",
        
        listStyle: "none",
        
        
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "10px",
        gap: "10px",
       
      }}
    >
      
       
          <Box
            
          >
            <Box
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
                
              }}
            >
              {type === "alarme" ? "Alarme" : type === "alerta" ? "Alerta" : ""}
            </Box>

            <List
              sx={{

                alignItems: "start",

                height: "150px",
                overflow: "auto",
                
                
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                
              }}
            >
              <ListItem
                
              >
                Descrição: {`${kpi.name} fora do intervalor desejável `} {`(${parseFloat(
                  type === "alarme"
                    ? firstValue
                    : type === "alerta"
                    ? selected.recomendation["eficiência atual"].toFixed(2)
                    : null
                ).toFixed(2)} ${kpi.unit})`}
              </ListItem>

              <ListItem
               
              >
                Início: {period}
              </ListItem>

              <ListItem
                
              >
                Ciente: {registered} {registered !== "Não registrado" && icon}
              </ListItem>
              {justify && (
                <ListItem
                  sx={{
                    width: "100%",
                    lineHeight: "25px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                 Justificativa: {justify}
                </ListItem>
              )}
              {registered === "Não registrado" && !isAdmin && (
                <LoadingButton
                  text={"Registrar Ciência"}
                  loading={loadingAck}
                  onClick={() => {
                    setLoadingAck(true);
                    ack(id);
                    setTimeout(() => {
                      setLoadingAck(false);
                    }, 2000);
                  }}
                />
              )}
            </List>
          </Box>

          <Box
           sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
           }}
          >
            <Box
               sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
               }}
            >
              Prescrição:
            </Box>

            
              <List
               sx={{
                
                alignItems: "start",

                height: "150px",
                overflow: "auto",
                
                
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                
              }}
              >
                {arrayOfPrescriptions.map((e: any) => {
                  const s: any = Object.fromEntries(
                    Object.entries(JSON.parse(message)).filter(([chave]) =>
                      [String(e)].includes(chave)
                    )
                  );

                  return (
                    <ListItem key={e + "prescription"} sx={{ width: "100%",color: theme.palette.mode === "dark" ? "white" : "black" }} >
                        <Box sx={{display: "flex",flexDirection:'row' ,justifyContent:'space-between',gap:'10px'}}>
                          {sensorName(String(e))}:
                          <Box sx={{display: "flex",flexDirection:'row',gap:'10px' }}>
                          <Box sx={{display: "flex",flexDirection:'column'}}>
                          Atual
                          <Chip label={s[String(e)]["Valor atual"].toFixed(2) + " " +  unitDictionary[String(e)]}  />
                          </Box>
                          <Box  sx={{display: "flex",flexDirection:'column'}}>
                          Recomendado
                          <Chip label={s[String(e)]["Valor recomendado"].toFixed(2) + " " + unitDictionary[String(e)]} />
                          </Box>
                          </Box>
                        </Box>
                  

                    </ListItem>
                  );
                })}

{arrayOfPrescriptions.length === 0 && 
                <ListItem sx={{ width: "100%" }}>
                  
                  <CircularProgress size={20} color="inherit" /> 
                  </ListItem>
                }
                
              </List>
           
          </Box>

          <Box
            
          >
            <Box
               sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
               }}
            >
              Cenário:
            </Box>

           
              <List
               sx={{
                
                height: "150px",
                overflow: "auto",
                
                
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                
              }}
              >
                {scenarioDataList.map((scenarioData, index) => {
                  console.log(scenarioData)
                  const label = getTransformedValue(scenarioData) + scenarioData.unit !== undefined ? scenarioData.unit : "%"

                  return (
                    <ListItem key={index} sx={{ width: "100%" }}>

                      {scenarioData.name}: <Chip label={label} />
                      

                    </ListItem>
                  );
                })}

                {scenarioDataList.length === 0 && 
                <ListItem sx={{ width: "100%" }}>
                  
                  <CircularProgress size={20} color="inherit" /> 
                  </ListItem>
                }
                
              </List>
            
          </Box>
        
    </Paper>
  );
};
export default RecomendationDetailsBoard;
