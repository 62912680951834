// interface series {
//   data: Array<any>;
//   unit: string;
//   name: string;
//   startArea: string;
//   endArea: string;
// }

const PROVISORY = (
  type: string,
  data: Array<any>,
  name: string,
  unit: string,
  startTime: any,
  endTime: any,
  startArea: any,
  endArea: any,
  theme: boolean
) => {

  const COLORS_DARK = [ "#F0E442","#7852E0", "#D55E00", "#009E73", "#fff"];
  
  
  const COLORS =  [
    "rgb(18, 66, 81)",
    "#98CBFF",
    "#548BBB",
    "#009E73",
    "#000000",
    "#F0E442",
    "#0072B2",
    "#D55E00",
    "#CC79A7",
  ] 




  

  return {
    backgroundColor: "transparent",
    title: {
      text: name,
      textStyle: {
       
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
      },
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      // bottom: 0,
      // left: 0,
      right: 10,
      top: -5,
      feature: {
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Voltar",

          },
          
          yAxisIndex: "all",
          brushStyle: {
            borderWidth: 1,
            color: 'rgba(255, 173, 17, 0.4)',
            borderColor: 'rgba(255, 173, 17, 1)',
          }


        },
        // restore: {
        //   title: "Reset",

        // },
        saveAsImage: {
          pixelRatio: 4,
          
          title: "Baixar .png",
          // backgroundColor: "rgb(18, 66, 81)",
          // excludeComponents: ["dataZoom", "brush", "magicType", "dataView"],
          name: "Dados Históricos",

        },
        // dataView: {
        //   title: "Ver Dados",
        //   readOnly: true,
          
         

        // },
        magicType: {
          title: {
            line: "Linha",
            bar: "Barra",
          },
          type: ["line", "bar"],
           
        },
        // brush: {
          
        //   type: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
        //   title: {
        //     rect: "Selecão retangular", 
        //     polygon: "Selecão poligonal",
        //     lineX: "Selecão horizontal",
        //     lineY: "Selecão vertical",
        //     keep: "Selecão  continua",
        //     clear: "Limpar",
        //   }
          
        // },
        // restore: {
        //   title: "Resetar",
        // }
        
      }
    },
    brush: {
      xAxisIndex: "all",
      brushLink: "all",
      outOfBrush: {
        colorAlpha: 0.1

      },
      brushType:"rect",

      brushStyle: {
        borderWidth: 1,
    color: 'rgba(255, 173, 177, 0.4)',
    borderColor: 'rgba(255, 173, 177, 1)',

   
      },
      // throttleType: "debounce",
      removeOnClick: true,
      inBrush: {
        color: "red",
        
        // symbol: "rect",
        // symbolSize: 10,
        // showSymbol: true,
      }

  },

    xAxis: {
      type: "time",
      min: startTime,
      max: endTime,
    },
    yAxis: [
      {
        name: unit,
        type: "value",
        alignTicks: true,
        scale: true,
        // min: 0,
        // max:10,
        axisTick: {
          show: true,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#979797",
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: theme ? "#fff" : "#000",
         
        },
      },
    ],
    dataZoom: [
      {
        type: "inside",

      },
    ],
    tooltip: {
      trigger: "axis",
      // showDelay: 0,
      hideDelay: 5, 
      transitionDuration: 2,
      confine: true,
      axisPointer: {
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 0,
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
        left: "25",
        right: "20",
        bottom: "20",
        top: "60",
        containLabel: true,
    },
    series: [
      {
        data: data,
        yAxisIndex: 0,
        showSymbol: false,
        symbolSize: 5,
        symbol: "circle",
        name: name,
        type: "line",
        
        color: theme ? COLORS_DARK[0] : COLORS[0],
        smooth: false,
        markArea: {
          data: [
            [
              {
                xAxis: startArea,
              },
              {
                xAxis: endArea,
              },
            ],
          ],
          silent: false,
          itemStyle: { 
            color: type === "alert" ? "rgba(255, 255, 160, 0.4)": "rgba(255, 173, 177, 0.4)"
          },
          animation: true,
          animationDuration: 1500,
          animationEasing:" linear",
          animationDelay: 500,
          animationDurationUpdate: 1000,
        },
        animation: true,
        animationDuration: 5000,
        animationEasing:" linear",
        animationDelay: 500,
        animationDurationUpdate: 1000,
      },
    ],
  };
};

export default PROVISORY;
