import "./AlertCard.css";
import { ReactComponent as Ciente } from "../../../Assets/Icons/ciente.svg";
import { ReactComponent as AlertIcon } from "../../../Assets/Icons/alert_yellow.svg";
import { ReactComponent as AlarmIcon } from "../../../Assets/Icons/alertaRed.svg";
import moment from "moment";
import { useEffect, useState } from "react";
import { Paper, useTheme } from "@mui/material";


const AlertCard = ({
  element,
  onClick,
  type,
  checked,
  company,
  digitalTwin,
  selected,
}) => {
  let message = "*";
 
  message = `${type === "alert" ? "Previsão de " : ""}${element.sensor.name} ${
    type === "alert" ? "sair" : "fora"
  } do intervalo desejável (${parseFloat(
    type === "alert"
      ? element.recomendation["eficiência atual"]
      : element.first_value
  ).toFixed(2)} ${element.sensor.unit.abbreviation})`;

  let color = "";
  if (type === "alert") {
    color = "#FFCB05";
  } else {
    color = "#C04040";
  }

  const [timestamp, setTimestamp] = useState(moment());

  const theme = useTheme();

  const startTime = `${new Date(
    element.first_alert_time
  ).toLocaleDateString()}, ${new Date(
    element.first_alert_time
  ).toLocaleTimeString()} `;

  const endTime = `${new Date(
    element.last_alert_time
  ).toLocaleDateString()}, ${new Date(
    element.last_alert_time
  ).toLocaleTimeString()} `;

  const diff = moment(element.last_alert_time).diff(
    moment(element.first_alert_time)
  );

  const diff2 = moment(timestamp).diff(moment(element.first_alert_time));

  const duration = moment.duration(diff);
  const duration2 = moment.duration(diff2);

  const durationString = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  const realDurationString = `${duration2.days()}d ${duration2.hours()}h ${duration2.minutes()}m ${duration2.seconds()}s`;

  let backgroundColor = "";
  if (selected) {
    backgroundColor = theme.palette.mode === "dark"? "#555":"#EDEDED";
  } else {
    backgroundColor = theme.palette.mode === "dark"? "#282828":"#FFF";
  }

  useEffect(() => {
    const interval = setInterval(() => setTimestamp(moment()), 1000);
    return () => clearInterval(interval);
  }, []);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      
        setStarted(true);
      
    }, Math.random() * 500);
  }, []);

  return (
    <Paper
      elevation={0}
      onClick={() => {
        if (!selected) {
          onClick();
        }
      }}
      sx={{
        opacity: started ? 1 : 0 ,transition: "opacity 0.5s ease-in-out",
        
        // height: "130px",
        display: "flex",
        cursor: "pointer",
        // width: "390px",
        fontFamily:"Roboto",
        fontWeight: 400,
        fontSize: "14px",
        
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: "10px",
          borderRadius: "3px 0 0 3px",
        }}
      >

      </div>

      <div
        style={{
          backgroundColor: backgroundColor,
          borderLeft: "none",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px 20px 10px 5px ",
            color: theme.palette.mode === "dark"? "white":"#000",
          }}
        >
          <p style={{ margin: 0, fontSize: "16px" }}>
            <b style={{ color: theme.palette.mode === "dark"? "white":"#000",}}>{`${company} `}</b> {" > "}
            <b style={{ color: theme.palette.mode === "dark"? "white":"#000", }}>{digitalTwin}</b>
          </p>
          <div>
            {!checked && <Ciente style={{ marginRight: "10px" }} />}
            {type === "alert" ? <AlertIcon /> : <AlarmIcon />}
          </div>
        </div>

        <div style={{ padding: "0 50px 5px 5px" ,color: theme.palette.mode === "dark"? "white":"#000",}}>
          <p style={{ margin: 0 ,color: theme.palette.mode === "dark"? "white":"#000", }}>
            <b>{type === "alert" ? "Alerta" : "Alarme"}:</b> {message}
          </p>

          <p style={{ margin: 0 ,color: theme.palette.mode === "dark"? "white":"#000", }}>
            <b>{element.finished ? "Normalizado em:" : "Início:"} </b>{" "}
            {element.finished ? endTime : startTime} <br />
            <b>Duração: </b>
            {element.finished ? durationString : realDurationString}
          </p>
        </div>
      </div>
    </Paper>
  );
};
export default AlertCard;
