import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewUserForm.css";

interface newUserInterface {
  name: string;
  email: string;
  permition_level: string;
}

const initialState: newUserInterface = {
  name: "",
  email: "",
  permition_level: "",
};

const NewUserForm = (props: any) => {
  const [newUser, setNewUser] = useState<newUserInterface>(initialState);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    // console.log({ name, value });
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    // console.log("testing store");
    // console.log(newUser);
    const response = await DbService.store_user(
      newUser.name,
      newUser.email,
      props.companyId,
      Date.now(),
      newUser.permition_level
    );
    setLoadingRequest(false);

    // console.log(response)

    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-div-input">
        <input
          id="name"
          name="name"
          type="text"
          onChange={onChange}
          placeholder="Digite o nome do funcionario"
        />
        <label htmlFor="name">Nome</label>
      </div>
      <div className="form-div-input">
        <input
          id="email"
          name="email"
          type="text"
          onChange={onChange}
          placeholder="Digite o email do funcionario"
        />
        <label htmlFor="email">E-mail</label>
      </div>

      <div className="form-div-select">
        <select
          id="permition_level"
          name="permition_level"
          onChange={onChange}
          required
        >
          <option value="" disabled selected hidden>
            Selecione o nivel de permissão do funcionario
          </option>
          <option value="admin">administrador</option>
          <option value="basic">básico</option>
        </select>
        <label htmlFor="permition_level">Nivel de permissão</label>
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.cancel}
          style={{
            backgroundColor: "#FFF",
            color: "#124251",
            marginRight: "10px",
          }}
          variant="contained"
        >
          Cancelar
        </Button>
        {loadingRequest ? (
          <Button
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            <CircularProgress style={{ color: "white" }} size={20} />
          </Button>
        ) : (
          <Button
            type="submit"
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            Salvar Usuario
          </Button>
        )}
      </div>
    </form>
  );
};
export default NewUserForm;
