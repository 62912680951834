import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//Importar arquivos de tradução
import statusEN from "./en/status_en_US.json";
import statusPT from "./pt/status_pt_BR.json";
import login from "./pt/login.json";
import singUp from './pt/singUp.json'
import alerta from './pt/alerta.json'
import passwordReset from './pt/passwordReset.json'
import monitoring from './pt/monitoring.json'
import alert_page from './pt/alert_page.json'
import historic_data from './pt/historic_data.json'
import recommendation_history from './pt/recommendation_history.json'

//Associar namespaces e seus arquivos correspondentes
const resources = {
  enUS: {
    status: statusEN
  },
  ptBR: {
    status: statusPT,
    login,
    singUp,
    alerta,
    passwordReset,
    monitoring,
    alert_page,
    historic_data,
    recommendation_history
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ptBR",
    fallbackLng: "ptBR",
    //Exportar namespaces
    ns:['status'],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });