import { Grid, Paper } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import Gain from "./Gain";

interface ChartProps {
  isDarkTheme: boolean;
  selectedSensor: any;
  historicalChartRef1: any;
  historicalChartRef2: any;
  alarmTimeChartRef1: any;
  alarmTimeChartRef2: any;
  alarmTimeChartRefTotal: any;
  frequencyRangeChartRef1: any;
  frequencyRangeChartRef2: any;
}

const Charts = (props: ChartProps) => {
  const isKpi = props.selectedSensor?.variable_type === "kpi";

  const toolbox ={
    top: 0,
    right: 0,
    show: true,
    feature: {
      saveAsImage: {
        title: "Baixar .png",
       
        pixelRatio: 4,
      },
      dataZoom: {
        title: {
          zoom: "Zoom",
          back: "Voltar",
        },
        yAxisIndex: "all",
        brushStyle: {
          borderWidth: 1,
          color: "rgba(255, 173, 17, 0.4)",
          borderColor: "rgba(255, 173, 17, 1)",
        },
      },
      magicType: {
        title: {
          line: "Linha",
          bar: "Barra",
        },
        type: ["line", "bar"],
      },
    },
  };
  return (
    <Grid
      container
      spacing={1}
      sx={{ width: "95%", height: "100%", padding: "10px 0" }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {props.selectedSensor?.name} em Alarme
          </span>

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.historicalChartRef1}
            style={{
              height: "24vh",
              margin: "0px",
            }}
            option={{
              backgroundColor: "transparent",
              toolbox: toolbox,
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          className="a"
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {props.selectedSensor?.name} Total
          </span>

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.historicalChartRef2}
            style={{
              height: "24vh",
              margin: "0px",
            }}
            option={{
              backgroundColor: "transparent",
              toolbox: toolbox,
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            Frequência em Alarme
          </span>

          <Gain
            chartRef={props.historicalChartRef1}
            isDarkTheme={props.isDarkTheme}
            isGain={false}
          />

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.frequencyRangeChartRef1}
            style={{
              height: "24vh",
              margin: "0px",
            }}
            option={{
              backgroundColor: "transparent",
              toolbox: toolbox,
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            Frequência Total
          </span>

          <Gain
            chartRef={props.historicalChartRef2}
            isDarkTheme={props.isDarkTheme}
            isGain={false}
          />

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.frequencyRangeChartRef2}
            style={{
              height: "24vh",
              margin: "0px",
            }}
            option={{
              backgroundColor: "transparent",
              toolbox: toolbox,
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "31vh",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Tempo em Alarme" : "Prescrições no Período"}
          </span>

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.alarmTimeChartRef1}
            style={{
              height: "26.5vh",
              margin: "0px",
            }}
            option={{
              grid: {},
              backgroundColor: "transparent",
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            height: "31vh",
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Ganho em Alarme" : null}
          </span>
          <Gain
            chartRef={props.historicalChartRef1}
            isDarkTheme={props.isDarkTheme}
            isAlarm
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            height: "31vh",
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Ganho Total" : null}
          </span>
          <Gain
            chartRef={props.historicalChartRef2}
            isDarkTheme={props.isDarkTheme}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Charts;