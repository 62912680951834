import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface Charts {
  data: {
    [companyId: string]: {
      [digitalTwinId: string]: { charts: any[]; layouts: any[] };
    };
  };
}

const initialState: Charts = {
  data: {},
};

// Define the async thunk
export const setChartsAsync =  createAsyncThunk(
  "charts/setChartsAsync",
  async (payload: any, { getState }) => {
    const state: any = getState();
    const companyId = state.company.data.id;
    const digitalTwinId = state.digitalTwin.data.id;

    return { companyId, digitalTwinId, payload };
  }
);

// Create the slice
export const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    resetCharts: (state) => {
      state.data = { ...initialState.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setChartsAsync.fulfilled, (state, action) => {
      const { companyId, digitalTwinId, payload } = action.payload;
      if (!state.data[companyId]) {
        state.data[companyId] = {};
      }
      state.data[companyId][digitalTwinId] = payload;
    });
  },
});

export const getCharts = (state) => {
  const companyId = state.company.data.id;
  const digitalTwinId = state.digitalTwin.data.id;

  return state.charts.data[companyId]?.[digitalTwinId];
};

export const { resetCharts } = chartsSlice.actions;
export default chartsSlice.reducer;
