import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import SideNav from "../Components/SideNav/SideNav";
import TopBar from "../Components/TopBar/TopBar";
import { getIsCompanyDefined } from "../store/slices/companyslice";
import { getIsDigitalTwinDefined } from "../store/slices/digitalTwinSlice";
import paths from "./paths";
import { Route } from "./useRoutes";
import { getIsAdmin, getIsLoggedIn } from "../store/slices/userSlice";
import { Box } from "@mui/material";


const ConditionalRoute = ({
  path,
  component,
  requiresAuth,
  requiresAdmin,
  requiresCompanyId,
  requiresDigitalTwinId,
  extraConditions,
  redirectRoutes,
}: Route) => {
  const isNotLoggedIn = !useSelector(getIsLoggedIn);
  const isNotAdmin = !useSelector(getIsAdmin);
  const isCompanyNotDefined = !useSelector(getIsCompanyDefined);
  const isDigitalTwinNotDefined = !useSelector(getIsDigitalTwinDefined);

  if (requiresAuth && isNotLoggedIn) {
    return <Navigate to={paths.LOGIN_SCREEN} />;
  }

  if (requiresAdmin && isNotAdmin) {
    return <Navigate to="-1" />;
  }

  if (requiresCompanyId && isCompanyNotDefined) {
    return <Navigate to={paths.CHOOSE_COMPANY} />;
  }

  if (requiresDigitalTwinId && isDigitalTwinNotDefined) {
    return <Navigate to={paths.PANEL_TWINS} />;
  }

  if (extraConditions && redirectRoutes) {
    for (let i = 0; i < extraConditions.length; i++) {
      if (extraConditions[i] === false) {
        return <Navigate to={redirectRoutes[i]} />;
      }
    }
  }

  const pathIsNotLogin = path !== paths.LOGIN_SCREEN && !path.includes(paths.RESET_PASSWORD) && !path.includes(paths.AUTH);

  return pathIsNotLogin ? <TopBar sx={{ }}>{component}</TopBar> : <Box sx={{ width: "100%" }}>{component}</Box>;
      
};

export default ConditionalRoute;
