import { createSlice } from "@reduxjs/toolkit";

interface DigitalTwin {
  data: {
    id: string;
    name: string;
    status: string;
    efficiencyInterval?: number[];
  };
}

const initialState: DigitalTwin = {
  data: {
    id: "",
    name: "",
    status: "",
    efficiencyInterval: undefined,
  },
};

export const digitalTwinSlice = createSlice({
  name: "digitalTwin",
  initialState,
  reducers: {
    setDigitalTwin: (state, action) => {
      const payload = action.payload;
      state.data = {
        id: payload.id,
        name: payload.name,
        status: payload.status,
        efficiencyInterval: payload.efficiency_interval,
      };
    },
    resetDigitalTwin: (state) => {
      state.data = { ...initialState.data };
    },
  },
});

export const getDigitalTwin = (state) => state.digitalTwin.data;
export const getDigitalTwinId = (state) => state.digitalTwin.data.id;
export const getIsDigitalTwinDefined = (state) =>
  state.digitalTwin.data.id !== "";
export const getDigitalTwinEffiencyInterval = (state) =>
  state.digitalTwin.data.efficiencyInterval;

export const { setDigitalTwin, resetDigitalTwin } = digitalTwinSlice.actions;
export default digitalTwinSlice.reducer;
