import { Badge } from "@mui/material";
import { ReactComponent as Alarm } from "../../Assets/Icons/alarm2.svg";
import { ReactComponent as Alert } from "../../Assets/Icons/alert2.svg";
import { ReactComponent as Check } from "../../Assets/Icons/check_white.svg";
import "./NotificationBadge.css";
//Definindo tipos que recebo nos parâmetros do componente

//Esse componente recebe o tipo e o número de notificações de cada badge
const NotificationBadge = (props: any) => {
  const badgeIcon = (type: string) => {
    switch (type) {
      case "greenBadge":
        return (
          <Check
            className="greenBadge"
            style={{ height: "25px", width: "25px" }}
          />
        );
      case "yellowBadge":
        return (
          <Alert
            className="yellowBadge"
            style={{ height: "25px", width: "25px" }}
          />
        );
      default:
        return (
          <Alarm
            className="redBadge"
            style={{ height: "25px", width: "25px" }}
          />
        );
    }
  };
  //Renderizo condicionalmente cada badge
  return (
    // <Link to="/painel" state={{name:''}} style={{ textDecoration: 'none' , padding: '5px'}}>
    <div className={`badge ${props.type}`} style={{ margin: "5px" }}>
      <Badge
        color={props.type === "greenBadge" ? "success" : "error"}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        showZero

        // badgeContent={notificationNumber}
      >
        {badgeIcon(props.type)}
      </Badge>

      <span>{props.notificationNumber}</span>
    </div>
    // </Link>
  );
};

export default NotificationBadge;
