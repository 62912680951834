import AuthService from "../Services/AuthService";
import paths from "../router/paths";
import { setCompany } from "../store/slices/companyslice";
import { setUser } from "../store/slices/userSlice";

const makeLogin = async (email: string, password: string, dispatch) => {
  //Chamando serviço que submete os dados para o servidor fazer login
  const loginResponse = await AuthService.login(email, password);

  // Preparing user data for the store
  const userData = {
    token: loginResponse.data.token,
    id: loginResponse.data.user.id,
    name: loginResponse.data.user.name,
    email: loginResponse.data.user.email,
    permissionLevel: loginResponse.data.user.permition_level,
  };

  // Dispatching action to set user data in the store
  dispatch(setUser(userData));

  const companies = loginResponse.data.user.companies;

  if (companies.length > 0) {
    const company = companies[0];
    dispatch(setCompany({ id: company.id, name: company.name }));
  }

  if (loginResponse.data.user.permition_level === "in_forma") {
    return paths.CHOOSE_COMPANY;
  }
  //Redirecionando usuário:
  return paths.PANEL_TWINS;
};

export default makeLogin;
