import { useState } from "react";
// import logo from "../../Assets/Images/logo_asa.png";
import DbService from "../../../Services/LocalDataService";
import { Button, CircularProgress } from "@mui/material";
// import "./NewDigitalTwinForm.css";

interface newDigitalTwinInterface {
  name: string;
  input: string;
  output: string;
  parent_id: number;
}

const NewDigitalTwinForm = (props: any) => {
  // const [assets, setAssets] = useState<string[]>([""]);

  const [loadingRequest, setLoadingRequest] = useState(false);

  const initialState: newDigitalTwinInterface = {
    name: "",
    input: "",
    output: "",
    parent_id: -1,
  };

  const [newDigitalTwin, setNewDigitalTwin] =
    useState<newDigitalTwinInterface>(initialState);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    // console.log({ name, value });
    if (name === "parent_id" && value === "") {
      setNewDigitalTwin({ ...newDigitalTwin, [name]: -1 });
    } else {
      setNewDigitalTwin({ ...newDigitalTwin, [name]: value });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    // console.log("testing store");
    // console.log(newDigitalTwin);
    let parent_id_to_submit: number | null;
    newDigitalTwin.parent_id === -1
      ? (parent_id_to_submit = null)
      : (parent_id_to_submit = newDigitalTwin.parent_id);
    // console.log(parent_id_to_submit);
    const response = await DbService.store_dt(
      newDigitalTwin.name,
      newDigitalTwin.input,
      newDigitalTwin.output,
      props.companyId,
      Date.now(),
      parent_id_to_submit
    ); //.then(() => (navigate("/groups")));
    setLoadingRequest(false);
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            placeholder="Digite o nome do Gêmeo Digital"
          />
          <label htmlFor="name">Nome</label>
        </div>

        <div className="form-div-input">
          <input
            id="input"
            name="input"
            type="text"
            onChange={onChange}
            placeholder="Digite a Utilidade de entrada"
          />
          <label htmlFor="input">Utilidade de entrada</label>
        </div>
        <div className="form-div-input">
          <input
            id="output"
            name="output"
            type="text"
            onChange={onChange}
            placeholder="Digite a Utilidade de saída"
          />
          <label htmlFor="output">Utilidade de Saída</label>
        </div>

        <div className="form-div-select">
          <select
            id="parent_id"
            name="parent_id"
            onChange={onChange}
            // value={newDigitalTwin.parent_id}
            required
          >
            <option value="" disabled selected hidden>
              Selecione o...
            </option>
            <option value="">Nenhum</option>
            {props.dts &&
              props.dts.map((dt: any) => {
                return <option value={dt.id}>{dt.name}</option>;
              })}
          </select>
          <label htmlFor="zSensor">Pai</label>
        </div>

        {/* <ul>
          {assets.map((_, index) => {
            return (
              <li>
                <div className="label-float2">
                  <input
                    id={`asset[${index}]`}
                    name={`asset[${index}]`}
                    type={`asset[${index}]`}
                    onChange={onChange}
                  />
                  <label>Ativo {index + 1}</label>
                </div>
              </li>
            );
          })}
          <button onClick={addAsset}>Adcionar Atívo</button>
        </ul> */}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={props.cancel}
            style={{
              backgroundColor: "#FFF",
              color: "#124251",
              marginRight: "10px",
            }}
            variant="contained"
          >
            Cancelar
          </Button>
          {loadingRequest ? (
            <Button
              style={{
                backgroundColor: "#124251",
              }}
              variant="contained"
            >
              <CircularProgress style={{ color: "white" }} size={20} />
            </Button>
          ) : (
            <Button
              type="submit"
              style={{
                backgroundColor: "#124251",
              }}
              variant="contained"
            >
              Salvar Gêmeo Digital
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
export default NewDigitalTwinForm;
