// import { ConstructionOutlined } from "@mui/icons-material";
// import { useState } from "react";
// import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";
import "./DeleteConfirmationDialog.css";

const DeleteConfirmationDialog = (props: any) => {
  return (
    <div className="DeleteConfirmationDialog">
      <div className="dialogBox">
        <div className="text">
          <p>{props.text}</p>
        </div>
        <div className="buttons">
          <button name="cancel" onClick={(event) => props.handleAction(event)}>
            cancel
          </button>
          <button name="confirm" onClick={(event) => props.handleAction(event)}>
            confirm
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmationDialog;
