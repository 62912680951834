import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";
import "./GraphCard.css";

const GraphCard = (props: any) => {
  return (
    <div className="graphCard" >
      <div>
        <p>{props.graph.name}</p>
      </div>
      <div className="icon">
        <Icon onClick={props.onClick}/>
      </div>
    </div>
  );
};
export default GraphCard;
