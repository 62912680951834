import { useSelector } from "react-redux";

import { getIsDarkTheme } from "../../../store/slices/themeSlice";
import { useTheme } from "@emotion/react";

interface ReferenceValueProps {
  width: any;
  value: number;
  fontSize?: string;
  sensorName?: string;
}

const ReferenceValue = ({
  width,
  value,
  
  sensorName,
}: ReferenceValueProps) => {
  const theme: any = useTheme();

  if (value > 0) {
    return (
      
           <div style={{
             display: "inline-block",
             fontFamily: "Roboto",
             color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
             fontSize: "15px",
             whiteSpace: "nowrap",
             overflow: "hidden",
             maxWidth: width,
             textOverflow: "ellipsis",
             
            //  animation: `marquee 5s linear infinite`,
           }}>

          
              {sensorName ? (
                <b>{sensorName} recomendado </b>
              ) : (
                <b>Valor recomendado: </b>
              )}
              {" < "}
              {value.toFixed(2)}
              </div>
            
       
      
    );
  }
  return null;
};

export default ReferenceValue;
