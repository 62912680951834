import { Box, Button, CircularProgress, Grid, List, ListItem, Paper } from "@mui/material";
import axios from "axios";
import moment, { Moment, now } from "moment";
import { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import { END_DATE, START_DATE } from "react-dates/constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import prescriptionsByTwinId from "../../Utils/prescriptionsByTwinId";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-widgets/styles.css";

import AlertCard from "../../Components/Cards/AlertCard/AlertCard";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import { getCompanyId } from "../../store/slices/companyslice";
import {
  getDigitalTwinId,
  getIsDigitalTwinDefined,
} from "../../store/slices/digitalTwinSlice";
import { getIsDarkTheme } from "../../store/slices/themeSlice";
import AssociatedGraphsBoard from "./AssociatedGraphsBoard";
import RecomendationDetailsBoard from "./RecomendationDetailsBoard";
import { getUserId, getUserName } from "../../store/slices/userSlice";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./DateRangePickerStyles.css";

const RecommendationHistoryView = () => {
  const companyId = useSelector(getCompanyId);
  const isDigitalTwinDefined = useSelector(getIsDigitalTwinDefined);
  const digitalTwinId = useSelector(getDigitalTwinId);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const userId = useSelector(getUserId);
  const userName = useSelector(getUserName);

  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const [di2winSensorData, setDi2winSensorData] = useState<any>(null);

  const [startDate, setStartDate] = useState<Moment>(
    moment().subtract(7, "days")
  );
  const [endDate, setEndDate] = useState<Moment>(moment(now()));
  const [focusedInput, setFocusedInput] = useState();
  const [days, setDays] = useState(7);
  const location = useLocation();
  const passedState = location.state;
  const [passedAlertId, setPassedAlertId] = useState(
    passedState ? passedState.alertId : null
  );

  const ackAlarm = async (alarmId) => {
    if (userId) {
      AlertsAlarmsService.ackAlarm(userId, alarmId).then((response) => {
        const i = data.findIndex(
          (e: any) => e.id === alarmId && e.type === "alarm"
        );
        const newData: any = [...data];
        newData[i].user = { name: userName };
        newData[i].user_id = userId;
        newData[i].acknowledged = true;
        newData[i].acknowledged_time = moment().toISOString();
        setData(newData);
        const newSelected: any = { ...selected };
        newSelected.registeredUser = userName;
        newSelected.registeredTime = moment().toISOString();
        setSelected(newSelected);
      });
    }
  };

  const ackAlert = async (alertId) => {
    if (userId) {
      AlertsAlarmsService.ackAlert(userId, alertId).then((response) => {
        const i = data.findIndex(
          (e: any) => e.id === alertId && e.type === "alert"
        );
        const newData: any = [...data];
        newData[i].user = { name: userName };
        newData[i].user_id = userId;
        newData[i].acknowledged = true;
        newData[i].acknowledged_time = moment().toISOString();
        setData(newData);
        const newSelected: any = { ...selected };
        newSelected.registeredUser = userName;
        newSelected.registeredTime = moment().toISOString();
        setSelected(newSelected);
      });
    }
  };

  const fetchAlarmsData = useCallback(async () => {
    if (settings && isDigitalTwinDefined) {
      const dataFetched: any = [];
      for (const twin of settings.digitalTwins) {
        // console.log(twin)
        const rawAlarms = await AlertsAlarmsService.getAlarmsRange(
          twin.id,
          moment().subtract(days + 1, "days"),
          moment()
        );

        const formatedAlarms = selected
          ? rawAlarms.map((alarm) =>
              alarm.id !== selected.id
                ? {
                    ...alarm,
                    selected: false,
                    companyName: settings.name,
                    dtName: twin.name,
                    type: "alarm",
                    associatedSensors: twin.sensors,
                  }
                : {
                    ...alarm,
                    selected: true,
                    companyName: settings.name,
                    dtName: twin.name,
                    type: "alarm",
                    associatedSensors: twin.sensors,
                  }
            )
          : rawAlarms.map((alarm) => {
              return {
                ...alarm,
                companyName: settings.name,
                dtName: twin.name,
                type: "alarm",
                associatedSensors: twin.sensors,
                selected: false,
              };
            });
        dataFetched.push(...formatedAlarms);
      }
      return dataFetched;
    }
  }, [settings, isDigitalTwinDefined, days, selected]);

  

  const aggregate = useCallback(async () => {
    const aux1 = (await fetchAlarmsData()) || [];
    if (aux1.length === 0) {
      setLoading(false);
      return;
    }

    //Provável principal motivo da lentidão no carregamento dos alarmes
    const ordenated = aux1.sort((a, b) => {
      if (moment(a.first_alert_time).isBefore(moment(b.first_alert_time)))
        return 1;
      if (moment(a.first_alert_time).isAfter(moment(b.first_alert_time)))
        return -1;
      return 0;
    });
    setData(ordenated);
    setLoading(false);

    //settando alarme que foi clicado na tela de monitoramento
    if (passedAlertId) {
      const passedAlertIndex = ordenated.findIndex(
        (element) => element.id === passedAlertId
      );
      const passedAlert = ordenated[passedAlertIndex];
      ordenated[passedAlertIndex].selected = true;

      setSelected({
        selected: passedAlert,
        predictions: passedAlert.predictions
          ? passedAlert.predictions
          : undefined,
        associatedSensors: passedAlert.associatedSensors,
        address: passedAlert.address,
        recomendation: passedAlert.recomendation,
        i: passedAlertIndex,
        id: passedAlert.id,
        type: passedAlert.type,
        message: passedAlert.message,
        startTime: passedAlert.first_alert_time,
        endTime: passedAlert.last_alert_time,
        firstValue: passedAlert.first_value,
        lastValue: passedAlert.last_value,
        finished: passedAlert.finished,
        registeredUser: passedAlert.user?.name,
        registeredTime: passedAlert.acknowledged_time,
        kpi: [
          {
            name: passedAlert.sensor.name,
            deviceId: passedAlert.sensor.device_id,
            id: passedAlert.sensor.number,
            unit: passedAlert.sensor.unit.abbreviation,
          },
        ],
      });
      setPassedAlertId(null);
    }
  }, [fetchAlarmsData, passedAlertId]);

  const updateDataOnSelect = async (i) => {
    const oldI = data.findIndex((e: any) => e.selected === true);
    const newData: any = [...data];
    newData[i].selected = true;
    if (oldI !== -1) {
      newData[oldI].selected = false;
    }
    setData(newData);
  };

  useEffect(() => {
    if (isDigitalTwinDefined) {
      const promise1 = axios.get(`/companies/hist/${companyId}`);

      //Busca metadados da IA necessarios pela
      //para renderizar as recomendacaoes
      const promise2 = axios.get(
        `/newApi/getSensorsInfo?digitalTwinId=${digitalTwinId}`
      );

      //
      Promise.all([promise1, promise2]).then((responses) => {
        setDi2winSensorData(responses[1].data);
        const aux = {
          name: responses[0].data.name,
          digitalTwins: responses[0].data.digitalTwins.map((digitalTwin) => {
            return {
              id: digitalTwin.id,
              name: digitalTwin.name,
              sensors: digitalTwin.sensors
                .map((sensor, index) => {
                  if (sensor.alert_detail) {
                    return {
                      indexAux: index,
                      name: sensor.name,
                      status: false,
                      id: sensor.number,
                      deviceId: sensor.device_id,
                      unit: sensor.unit.abbreviation,
                      precedence: sensor.precedence,
                    };
                  } else return null;
                })
                .filter((element) => {
                  return element !== null;
                }),
            };
          }),
        };
        setSettings(aux);
      });
    }
    return () => {};
  }, [companyId, digitalTwinId, isDigitalTwinDefined]);

  useEffect(() => {
    setDays(moment(now()).diff(moment(startDate), "days"));
  }, [startDate]);

  useEffect(() => {
    if (settings !== null) {
      aggregate();
    }
    const interval = setInterval(() => {
      if (settings !== null) {
        aggregate();
      }
    }, 180000);
    return () => {
      clearInterval(interval);
    };
  }, [aggregate, settings]);

  useEffect(() => {
    data.length === 0 && setLoading(false);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setEndDate(moment(now()));
  }, []);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  return !settings ? (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)",gap:'10px' }}><CircularProgress color={"inherit"} size={"25px"} /> {`Carregando ... ${'Histórico de recomendações'}`}</Box>

  ) : (
    <Grid container spacing={1} sx={{ height: "100%" ,width:'calc(100% - 40px)' ,padding:'10px'}}>
    <Grid item xs={3}>
      <Paper
        elevation={0}
        sx={{
          opacity: started ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          
          
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          
          
        }}
      >
        <Box
          sx={{
        
            fontFamily: "Roboto",
            fontWeight: 400,
            
            fontSize: "18px",
            width: "100%",

          }}
        >
          Histórico de Recomendações
        </Box>

        <Box sx={{ width: "100%" ,display:'flex',flexDirection:'row',justifyContent:'space-between', height:'100%',alignItems:'center',padding:'10px 0',overflow:'auto'}}>
            <DateRangePicker
            
              noBorder
              minimumNights={0}
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId={START_DATE} // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId={END_DATE} // PropTypes.string.isRequired,
              onDatesChange={({ startDate }) => {
                setStartDate(startDate);
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={(day) => day.isAfter()}
              displayFormat={() => "DD/MM/YYYY"}
              startDatePlaceholderText="Data Inicial"
              endDatePlaceholderText="Data Final"
              
            />

            <Button onClick={() => aggregate()} variant="contained">
              Buscar
            </Button>
          
          </Box>

        <List
          sx={{
          height: 'calc(100vh - 260px)',
            overflow: "auto",
           
          }}
        >
          
       

       

          {loading ?
            
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)",gap:'10px' }}><CircularProgress color={"inherit"} size={"25px"} /> {`Carregando...`}</Box>
              
            :

            data.length > 0 &&
            data
              
              .map((element: any, i: number) => {
                return (
                  <ListItem style={{ width: "100%" }} key={i}>
                    <AlertCard
                      element={element}
                      checked={element.acknowledged}
                      type={element.type}
                      company={element.companyName}
                      digitalTwin={element.dtName}
                      selected={element.selected}
                      onClick={() => {
                        updateDataOnSelect(i);
                        setSelected({
                          selected: element,
                          predictions: element.predictions
                            ? element.predictions
                            : undefined,
                          associatedSensors: element.associatedSensors,
                          address: element.address,
                          recomendation: element.recomendation,
                          i: i,
                          id: element.id,
                          type: element.type,
                          message: element.message,
                          startTime: element.first_alert_time,
                          endTime: element.last_alert_time,
                          firstValue: element.first_value,
                          lastValue: element.last_value,
                          finished: element.finished,
                          registeredUser: element.user?.name,
                          registeredTime: element.acknowledged_time,
                          kpi: [
                            {
                              name: element.sensor.name,
                              deviceId: element.sensor.device_id,
                              id: element.sensor.number,
                              unit: element.sensor.unit.abbreviation,
                            },
                          ],
                        });
                      }}
                    />
                  </ListItem>
                );
              })}
        </List>


      </Paper>
    </Grid>

    <Grid item xs={9}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {selected ? (
          
          <Grid container spacing={1}>

          <Grid item xs={12}>
          <RecomendationDetailsBoard
          selected={selected}
          id={selected.id}
          kpi={selected.kpi[0]}
          message={selected.message}
          recomendation={selected.recomendation}
          type={selected.predictions !== undefined ? "alerta" : "alarme"}
          firstValue={selected.firstValue}
          lastValue={selected.lastValue}
          startTime={selected.startTime}
          endTime={selected.endTime}
          finished={selected.finished}
          registeredUser={selected.registeredUser}
          registeredTime={selected.registeredTime}
          socketAddresses={di2winSensorData}
          isDarkTheme={isDarkTheme}
          ack={selected.type === "alert" ? ackAlert : ackAlarm}
          PrescriptionSensors={prescriptionsByTwinId}
        />
          </Grid>
          <Grid item xs={6}>
          <Paper
            elevation={0}
            sx={{
              opacity: started ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              
              display: "flex-column",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: "10px",
              
              
            }}
          >
            <AssociatedGraphsBoard
              selected={selected}
              kpi={false}
              type={selected.type}
              startTime={selected.startTime}
              endTime={selected.endTime}
              registeredSensorData={selected.associatedSensors.filter(
                (e) => e.id !== selected.kpi[0].id
              )}
              finished={selected.finished}
            />
          </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper
            elevation={0}
            sx={{
              opacity: started ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              
              display: "flex-column",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: "10px",

            }}
          >
            <AssociatedGraphsBoard
              selected={selected}
              kpi={true}
              type={selected.type}
              startTime={selected.startTime}
              endTime={selected.endTime}
              registeredSensorData={selected.kpi}
              finished={selected.finished}
            />
          </Paper>
          </Grid>
          
          </Grid>
          
            
        ) : (
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              opacity: started ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              
              width: "calc(100vw - 600px)",
              height: "calc(100vh - 150px)",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "18px",
              padding: "10px",
 
            }}
          >
            {data.length !== 0 &&
              "Selecione uma recomendação ao lado para visualização"}
          </Paper>
        )}
        
       
        
      </Box>

    </Grid>

    
    </Grid>
  );
};

export default RecommendationHistoryView;
