import { Box, CircularProgress } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import {  useSelector } from "react-redux";
import { SizeMe } from "react-sizeme";
import CardBarra from "../../Components/dinamicos/CardBarra";
import CardGrafico from "../../Components/dinamicos/CardGrafico";
import CardPizza from "../../Components/dinamicos/CardPizza";
import CardVelocimetro from "../../Components/dinamicos/CardVelocimetro";
import { getCharts,getLayouts } from "../../store/slices/monitoringSlice";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";

const MonitoringView = () => {

 
  const storedCharts = useSelector(getCharts);
  const layouts = useSelector(getLayouts);
  
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const digitalTwin = useSelector(getDigitalTwin);


  return (
  <>
  <ResponsiveGridLayout
              style={{
                overflowY: "auto",
                width: "calc(100% - 70px)",
              }}
              draggableHandle=".draggableHandle"
              className="layout"
              breakpoints={{
                xl: 1900,
                lg: 1200,
                md: 996,
                sm: 768,
                xs: 480,
                xxs: 0,
              }}
              cols={{ xl: 12, lg: 12, md: 4, sm: 4, xs: 2, xxs: 2 }}
              rowHeight={260}
              layouts={{ xl: layouts }}
            >

              {storedCharts.map((chart: any, i: number) => {
                if (chart.default_visualization === "speedometer") {
                  return (
                    <div key={i}>
                      <SizeMe monitorHeight>
                        {({ size }) => (
                          <div
                            style={{
                              height: "100%",
                            }}
                          >
                            <CardVelocimetro
                              elemento={chart}
                              w={size.width}
                              h={size.height}
                            />
                          </div>
                        )}
                      </SizeMe>
                    </div>
                  );
                } else if (chart.default_visualization === "pie") {
                  return (
                    <div key={i}>
                      <SizeMe monitorHeight>
                        {({ size }) => (
                          <div
                            style={{
                              height: "100%",
                            }}
                          >
                            <CardPizza
                              elemento={chart}
                              w={size.width}
                              h={size.height}
                            />
                          </div>
                        )}
                      </SizeMe>
                    </div>
                  );
                } else if (chart.default_visualization === "bar") {
                  return (
                    <div key={i}>
                      <SizeMe monitorHeight>
                        {({ size }) => (
                          <div
                            style={{
                              height: "100%",
                            }}
                          >
                            <CardBarra
                              elemento={chart}
                              w={size.width}
                              h={size.height}
                            />
                          </div>
                        )}
                      </SizeMe>
                    </div>
                  );
                } else {
                  return (
                    <div key={i}>
                      <SizeMe monitorHeight>
                        {({ size }) => (
                          <div
                            style={{
                              height: "100%",
                            }}
                          >
                            <CardGrafico
                              chart={chart}
                              width={size.width || undefined}
                              height={size.height || undefined}
                            />
                          </div>
                        )}
                      </SizeMe>
                    </div>
                  );
                }
              })}
             
             
   
            </ResponsiveGridLayout>

            {storedCharts.length === 0 && 
               
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)",gap:'10px' }}><CircularProgress color={"inherit"} size={"25px"} /> {`Carregando ... ${digitalTwin.name}`}</Box>
                 
              
             }
  </>
              
        
         

  );
};

export default MonitoringView;
