const paths = {
  HOME: "/",
  FAQ: "/faq",
  LOGIN_SCREEN: "/login",
  SIGNUP: "/signup",
  PANEL_TWINS: "/painel",
  PANEL_MONITORING: "/monitoramento",
  ALERT: "/alert",
  HISTORICO_DADOS: "/historico-dados",
  HISTORICO_RECOMENDACOES: "/historico-recomendacoes",
  RESET_PASSWORD: "/resetPassword",
  GASTEC_MONITORING: "/monitoramento/gastec",
  GROUPS_PAGE: "/groups",
  GROUP_PAGE: "/groups/:id",
  DIGITAL_TWIN_PAGE: "/digitaltwin/:id",
  ANALYTICS_HOME_PAGE: "/analytics",
  ANALYTICS_COMPANY_PAGE: "/analytics/:id",
  CHOOSE_COMPANY: "/inFormaPanel",
  AUTH: "/auth/singup/activation-code/:token",
};

export default paths;
