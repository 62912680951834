import React from 'react';
import { IconButton } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { useThemeContext } from './ThemeContext.jsx';

const ThemeToggleButton = () => {
    const { isDarkTheme, toggleTheme } = useThemeContext();

  

  return (
    <IconButton onClick={toggleTheme} title={isDarkTheme ? "light" : "dark"}>
      {isDarkTheme ? (
        <LightMode style={{color: "white" }} />
      ) : (
        <DarkMode style={{  color: "black"  }} />
      )}
    </IconButton>
  );
};

export default ThemeToggleButton;