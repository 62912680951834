const unitDictionary: { [key: string]: string } = {
  sensor_umidade_po: "%",
  "27": "%",
  temperatura_ar_apos_GASTEC: "ºC",
  temperatura_ar_apos_torre_secagem: "ºC",
  temperatura_ciclone: "ºC",
  pressao_bomba_alta: "kPa",
  pressao_bomba_baixa: "kPa",
  pressao_torre_secagem: "kPa",
  "4": "bar",
  "34": "",
  consumo_gas_GASTEC: "Nm³",
  producao_GASTEC: "kg",
  "924": "kg",
  eficiencia_GASTEC: "Nm³/kg",
  cegn: "kcal/kg",
  "Temperatura-Backwall-Centro": "ºC",
  "Temperatura-pórtico-6-passante": "ºC",
  "Temperatura-portico2-soleira": "ºC",
  "Temperatura-portico3-soleira": "ºC",
  "Temperatura-portico4-soleira": "ºC",
  "Temperatura-portico5-Esq-soleira": "ºC",
  "Temperatura-portico5-soleira": "ºC",
  "Temperatura-portico7-soleira": "ºC",
  "Temperatura-refino1-soleira": "ºC",
  "Temp-Soleira-Refino-2": "ºC",
  "Temperatura-refino3-Center-soleira": "ºC",
  "KPI-CONSUMO-POR-EFICIENCIA": "Nm³/kg",
  VL_TEMPERAT_3: "ºC",
  VL_TEMPERAT_4: "ºC",
  VL_TEMPERAT_6: "ºC",
  VL_TEMPERAT_7: "ºC",
  VL_TEMPERAT_9: "ºC",
  VL_TEMPERAT_10: "ºC",
  VL_TEMPERAT_11: "ºC",
  VL_TEMPERAT_12: "ºC",
  VL_TEMPERAT_13: "ºC",
  VL_TEMPERAT_14: "ºC",
  VL_TEMPERAT_15: "ºC",
  VL_TEMPERAT_16: "ºC",
  VL_TEMPERAT_17: "ºC",
  VL_TEMPERAT_19: "ºC",
  VL_TEMPERAT_23: "ºC",
  VL_TEMPERAT_24: "ºC",
  VL_TEMPERAT_25: "ºC",
};

export default unitDictionary;
