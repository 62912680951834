import ObjectsToCsv from "objects-to-csv";

async function downloadCsvHelper(data, analytics=false) {
    const dataHist = data.slice(0, 4);
    
    const csvHist = dataHist
      .map((obj, index) => {
        const csvObj = obj.rawData.map((raw) => ({
          Grafico: obj.name,
          "Sensor ID": obj.sensor,
          "Periodo de Tempo": `${obj.startDate.format("YYYY-MM-DD")} - ${obj.endDate.format("YYYY-MM-DD")}`,
          Timestamp: raw.timestamp,
          Valor: raw.value,
          Unidade: obj.unit,
        }));
  
        if (index > 0 && index % 2 === 0) {
          csvObj.unshift({
            Grafico: "",
            "Sensor ID": "",
            "Periodo de Tempo": "",
            Timestamp: "",
            Valor: "",
            Unidade: "",
          });
        }
      
        return csvObj;
      })
      .flat();
    
    const csvHistString = await new ObjectsToCsv(csvHist).toString();

    // Criar Blob
    const blobHist = new Blob([csvHistString], { type: "text/csv" });

    // Criar URL do Blob
    const urlHist = window.URL.createObjectURL(blobHist);

    // Criar link para download
    const linkHist = document.createElement("a");
    linkHist.href = urlHist;

    const formattedDates = `${dataHist[0].startDate.format("DD-MM-YYYY")}_${dataHist[0].endDate.format("DD-MM-YYYY")}`;

    linkHist.setAttribute(
    "download",
    `Análise dos dados historicos no Período ${formattedDates}.csv`
    );

    // Simular click para iniciar o download
    linkHist.click();

    // Limpar URL do Blob
    window.URL.revokeObjectURL(urlHist);

    if (analytics) {
        const dataFreq = data.slice(4, 8);
        const dataAlarm = data.slice(8, 9);

        const csvFreq = dataFreq
        .map((obj, index) => {
        const csvObj = obj.rawData.map((raw) => ({
            Grafico: obj.name,
            "Sensor ID": obj.sensor,
            Valor: raw[1],
            Frequencia: raw[0],
            Unidade: obj.unit,
        }));

        if (index > 0 && index % 2 === 0) {
            csvObj.unshift({
            Grafico: "",
            "Sensor ID": "",
            Valor: "",
            Frequencia: "",
            Unidade: "",
            });
        }

        return csvObj;
        })
        .flat();

        const csvAlarm = dataAlarm
        .map((obj, index) => {
        const csvObj = obj.rawData.map((raw) => ({
            Grafico: obj.name,
            "Digital Twin ID": obj.digitalTwinId,
            "Sensor ID": raw.sensor_id,
            "First Alert Time": raw.first_alert_time,
            "Last Alert Time": raw.last_alert_time,
        }));

        if (index > 0 && index % 2 === 0) {
            csvObj.unshift({
            Grafico: "",
            "Digital Twin ID":"",
            "Sensor ID": "",
            "First Alert Time": "",
            "Last Alert Time": "",
            });
        }

        return csvObj;
        })
        .flat();

        const csvFreqString = await new ObjectsToCsv(csvFreq).toString();
        const csvAlarmString = await new ObjectsToCsv(csvAlarm).toString();
    
        // Criar Blob
        const blobFreq = new Blob([csvFreqString], { type: "text/csv" });
        const blobAlarm = new Blob([csvAlarmString], { type: "text/csv" });
    
        // Criar URL do Blob
        const urlFreq = window.URL.createObjectURL(blobFreq);
        const urlAlarm = window.URL.createObjectURL(blobAlarm);

        // Criar link para download
        const linkFreq = document.createElement("a");
        linkFreq.href = urlFreq;
        const linkAlarm = document.createElement("a");
        linkAlarm.href = urlAlarm;
    
        linkFreq.setAttribute(
        "download",
        `Análise dos dados de frequencia no Período ${formattedDates}.csv`
        );
        linkAlarm.setAttribute(
        "download",
        `Análise do tempo em alarme no Período ${formattedDates}.csv`
        );
    
        // Simular click para iniciar o download
        linkFreq.click();
        linkAlarm.click();
    
        // Limpar URL do Blob
        window.URL.revokeObjectURL(urlFreq);
        window.URL.revokeObjectURL(urlAlarm);
    }
  }

export default downloadCsvHelper;