import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Services/AuthService";
import LoginHelper from "../../Utils/LoginHelper";
import useField from "../../Utils/useField";
import "./SignUpView.css";
import { useDispatch } from "react-redux";

const SignUpView = () => {
  const dispatch = useDispatch();
  const name = useField("text");
  const email = useField("text");
  const password = useField("password");
  const { t } = useTranslation(["singUp"]);
  const buttonText = t("singUp:createButton").toString();
  const navigate = useNavigate();
  const singUpButton = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    //TODO Validar email e, possivelmente, senha e nome
    const payload = {
      name: name.value,
      email: email.value,
      password: password.value,
      company_id: 1,
    };
    //Chamando serviço que submete os dados para o servidor fazer singup
    const singUpResponse = await AuthService.singup(payload);
    if (singUpResponse.status === 200) {
      //alert(t('singUp:createAccountAlert'))
      navigate(await LoginHelper(email.value, password.value, dispatch));
    }
  };
  return (
    <div className="singUpFormContainer">
      <form onSubmit={singUpButton}>
        <div>
          <TextField {...name} label={t("singUp:nameField")} />
        </div>
        <div>
          <TextField {...email} label={t("singUp:emailField")} />
        </div>
        <div>
          <TextField {...password} label={t("singUp:passwordField")} />
        </div>
        {/* <Button variant="contained" color="primary" type="submit">
          {buttonText}
        </Button> */}
        <button
          type="submit"
          style={{
            border: "0.50px solid #EBEBEB",
            backgroundColor: "#124251",
            color: "#FFF",
            padding: "10px",
            borderRadius: "6px",
            flexGrow: "1",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              {buttonText}
            </p>
          </div>
        </button>
      </form>
    </div>
  );
};
export default SignUpView;
