import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import { sensorName } from "../../Utils/formatString";
import prescriptionsByTwinId from "../../Utils/prescriptionsByTwinId";
import unitDictionary from "../../Utils/unitDictionary";

import { useSelector } from "react-redux";
import { getIsAdmin, getUserId } from "../../store/slices/userSlice";
import DataService from "../../Services/DataService";
import { getIsDarkTheme } from "../../store/slices/themeSlice";

const Modal = (props: any) => {
  const userId = useSelector(getUserId);
  const isAdmin = useSelector(getIsAdmin);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const arrayOfPrescriptions = prescriptionsByTwinId[props.gemeo.id];
  const [scenarioVariables, setScenarioVariables] = useState<Array<any>>([]);
  const [scenarioDataList, setScenarioDataList] = useState<Array<any>>([]);
  const [timestamp, setTimestamp] = useState<any>(moment());
  const [openModal, setOpenModal] = useState(false);
  const [openModalJustify, setOpenModalJustify] = useState(false);
  const [justification, setJustification] = useState("");

  useEffect(() => {
    let isComponentMounted = true; // Flag to track mounting status

    const fetchData = async () => {
      if (!isComponentMounted) return; // Prevents state update if component is unmounted

      let fetchedDataList: any[] = [];

      if (props.alerta.first_alert_time) {
        const sensors = await DataService.getSensorsWithAlarmDetails(
          props.gemeo.id
        );

        setScenarioVariables(sensors);

        for (let variable of sensors) {
          NewDi2winDataService.getLastNInfoInRange(
            variable.number,
            variable.device_id,
            moment(props.alerta.first_alert_time),
            moment(props.alerta.first_alert_time).add(30, "minute"),
            "1"
          ).then((scenarioData) => {
            let lastData = scenarioData && scenarioData.pop();

            if (lastData && lastData.sensorId === 34) {
              lastData.value = lastData.value ? "Em uso" : "Não está em uso";
            }

            lastData = {
              ...lastData,
              name: variable.name,
              unit:
                variable.type.name === "booleano"
                  ? ""
                  : variable.unit.abbreviation,
            };
            fetchedDataList.push(lastData);
            setScenarioDataList(fetchedDataList);
          });
        }
      }
    };

    fetchData();

    // Cleanup function to update mount status
    return () => {
      isComponentMounted = false;
    };
  }, [props.alerta.first_alert_time, props.gemeo]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const timeCurrent = props.alerta.finished
    ? `${moment
        .duration(
          moment(props.alerta.last_alert_time).diff(
            moment(props.alerta.first_alert_time)
          )
        )
        .days()}d ${moment
        .duration(
          moment(props.alerta.last_alert_time).diff(
            moment(props.alerta.first_alert_time)
          )
        )
        .hours()}h ${moment
        .duration(
          moment(props.alerta.last_alert_time).diff(
            moment(props.alerta.first_alert_time)
          )
        )
        .minutes()}m ${moment
        .duration(
          moment(props.alerta.last_alert_time).diff(
            moment(props.alerta.first_alert_time)
          )
        )
        .seconds()}s`
    : `${moment
        .duration(moment(timestamp).diff(moment(props.alerta.first_alert_time)))
        .days()}d ${moment
        .duration(moment(timestamp).diff(moment(props.alerta.first_alert_time)))
        .hours()}h ${moment
        .duration(moment(timestamp).diff(moment(props.alerta.first_alert_time)))
        .minutes()}m ${moment
        .duration(moment(timestamp).diff(moment(props.alerta.first_alert_time)))
        .seconds()}s`;

  const handleClose = () => {
    if (isAdmin) {
      // deve fechar o modal
      setOpenModal(false);
    }
    setOpenModal(false);
  };

  const handleCiente = async (alarmId) => {
    if (userId) {
      try {
        await AlertsAlarmsService.ackAlarm(userId, alarmId);
        setOpenModal(false);
      } catch (error) {
        console.log(error);
        try {
          await AlertsAlarmsService.ackAlert(userId, alarmId);
          setOpenModal(false);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleCienteJustificar = async (alarmId, justification) => {
    if (userId) {
      try {
        await AlertsAlarmsService.justifyRecommendation(
          userId,
          alarmId,
          justification
        );
        setOpenModal(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseJustify = () => {
    setOpenModalJustify(false);
  };

  useEffect(() => {
    if (props.alerta.acknowledged && props.alerta.finished) {
      setOpenModal(false);
    }

    if (
      !props.alerta.acknowledged &&
      props.alerta.finished &&
      scenarioDataList.length > 1
    ) {
      setOpenModal(true);
    }

    if (
      !props.alerta.acknowledged &&
      !props.alerta.finished &&
      scenarioDataList.length > 1
    ) {
      setOpenModal(true);
    }
    if (!props.alerta) {
      setOpenModal(false);
    }
  }, [props.alerta, scenarioDataList.length]);

  const getTransformedValue = (scenarioData) => {
    if (scenarioData.sensorId === 17) {
      return (parseFloat(scenarioData.value) / 0.37).toFixed(2);
    }

    const floatValue = parseFloat(scenarioData.value);

    if (isNaN(floatValue)) {
      return scenarioData.value;
    }

    return floatValue.toFixed(2);
  };

  const getValue = (scenarioData) => {
    if (scenarioData.sensorId === 17) {
      return (parseFloat(scenarioData.value) / 0.37).toFixed(2);
    }

    const floatValue = parseFloat(scenarioData.value);

    if (isNaN(floatValue)) {
      return scenarioData.value;
    }

    return floatValue.toFixed(2);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseJustify}
        open={openModalJustify}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          fontWeight: 400,
          color: isDarkTheme ? "#fff" : "#000",
          fontSize: "16px",
          fontFamily: "Roboto",
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1a8f9d",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "1vh",
              justifyContent: "space-around",
              alignItems: "center",
              color: isDarkTheme ? "#fff" : "#000",
            }}
          >
            Recomendação recusada
          </div>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isDarkTheme ? "#fff" : "#000",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              multiline
              rows={4}
              autoFocus
              margin="dense"
              id="justification"
              label="Justificativa"
              type="text"
              fullWidth
              placeholder="Insira o motivo de não seguir a recomendação"
              onChange={(e) => setJustification(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            color: isDarkTheme ? "#fff" : "#000",
          }}
        >
          <Button
            onClick={() => {
              setOpenModalJustify(false);
            }}
          >
            Fechar
          </Button>
          <Button
            onClick={() => {
              handleCienteJustificar(props.alerta.id, justification);
              setOpenModalJustify(false);
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={props.alerta.finished && handleClose}
        open={openModal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          fontWeight: 400,
          color: isDarkTheme ? "#fff" : "#000",
          fontSize: "16px",
          fontFamily: "Roboto",
        }}
      >
        <DialogTitle
          sx={{
            background: props.alerta.predictions
              ? "rgba(255, 203, 5, 0.38)"
              : props.alerta.finished
              ? "rgba(131, 171, 81, 0.38)"
              : "#C0404061",
            padding: "0",
          }}
        >
          <div
            style={{
              backgroundColor: props.alerta.predictions
                ? "rgba(255, 203, 5)"
                : props.alerta.finished
                ? "#83AB51"
                : "#C04040",
              padding: "3vh 0 3vh 0",
              width: "1vw",
              float: "left",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "flex",
              margin: "3vh",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {props.alerta !== undefined && (
              <div style={{ color: isDarkTheme ? "#fff" : "#000" }}>
                {props.alerta.predictions
                  ? "ALERTA"
                  : props.alerta.finished
                  ? "CORREÇÃO"
                  : "ALARME"}
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            fontWeight: 400,
            color: isDarkTheme ? "#fff" : "#000",
            fontSize: "16px",
            fontFamily: "Roboto",
            backgroundColor: isDarkTheme ? "#282828" : "#fff",
          }}
        >
          <>
            {!props.alerta.predictions && props.alerta && props.gemeo && (
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  padding: "1vh",
                  fontWeight: 400,
                  color: isDarkTheme ? "#fff" : "#000",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                }}
              >
                <b>Unidade:</b> <span>Recife</span>
                <br />
                <b>Setor:</b> <span>{props.gemeo.name}</span>
                <br />
                <b>Descrição:</b>{" "}
                <span>
                  Eficiência do consumo de Gás Natural{" "}
                  {props.alerta.finished ? "dentro" : "fora"} do intervalo
                  aceitável
                </span>
                <br />
                <span>
                  (
                  {parseFloat(
                    props.alerta.finished
                      ? props.alerta.last_value < 0
                        ? 0
                        : props.alerta.last_value
                      : props.alerta.first_value
                  ).toFixed(2)}{" "}
                  Nm³/ton)
                </span>
                <br />
                <b>Início:</b>
                <span>
                  {` ${new Date(
                    props.alerta.first_alert_time
                  ).toLocaleDateString()}  ${new Date(
                    props.alerta.first_alert_time
                  ).toLocaleTimeString()}  `}
                </span>
                <br />
                {props.alerta.finished && (
                  <>
                    <b>Normalizado em:</b>{" "}
                    <span>{`${moment
                      .duration(
                        moment(props.alerta.last_alert_time).diff(
                          moment(props.alerta.first_alert_time)
                        )
                      )
                      .days()}d ${moment
                      .duration(
                        moment(props.alerta.last_alert_time).diff(
                          moment(props.alerta.first_alert_time)
                        )
                      )
                      .hours()}h ${moment
                      .duration(
                        moment(props.alerta.last_alert_time).diff(
                          moment(props.alerta.first_alert_time)
                        )
                      )
                      .minutes()}m ${moment
                      .duration(
                        moment(props.alerta.last_alert_time).diff(
                          moment(props.alerta.first_alert_time)
                        )
                      )
                      .seconds()}s`}</span>
                  </>
                )}
                {!props.alerta.finished && (
                  <>
                    <b>Duração:</b> <span>{timeCurrent}</span>
                  </>
                )}
              </DialogContentText>
            )}

            {props.alerta.predictions &&
              props.alerta !== undefined &&
              props.gemeo !== undefined && (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{
                    padding: "1vh",
                    fontWeight: 400,
                    color: isDarkTheme ? "#fff" : "#000",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                  }}
                >
                  <b>Unidade:</b> <span>Recife</span>
                  <br />
                  <b>Setor:</b> <span>{props.gemeo.name}</span>
                  <br />
                  <b>Descrição:</b>{" "}
                  <span>
                    Eficiência do consumo de Gás Natural sairá do intervalo
                    aceitável
                  </span>
                  <br />
                  <br />
                  <b>Início:</b>
                  <span>
                    {` ${new Date(
                      props.alerta.first_alert_time
                    ).toLocaleDateString()}  ${new Date(
                      props.alerta.first_alert_time
                    ).toLocaleTimeString()}  `}
                  </span>
                  <br />
                  {props.alerta.finished && (
                    <>
                      <b>Normalizado em:</b>{" "}
                      <span>{`${new Date(
                        new Date(props.alerta.last_alert_time).getTime() -
                          new Date(props.alerta.first_alert_time).getTime()
                      ).getMinutes()}m ${new Date(
                        new Date(props.alerta.last_alert_time).getTime() -
                          new Date(props.alerta.first_alert_time).getTime()
                      ).getSeconds()}s`}</span>
                    </>
                  )}
                  {!props.alerta.finished && (
                    <>
                      <b>Duração:</b> <span>{timeCurrent}</span>
                    </>
                  )}
                </DialogContentText>
              )}

            {props.alerta !== undefined && (
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  padding: "1vh",
                  fontWeight: 400,
                  color: isDarkTheme ? "#fff" : "#000",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                }}
              ></DialogContentText>
            )}
            {props.alerta.predictions && (
              <>
                <b>Predicão:</b>
                <ul className="listaPrescricao"></ul>

                {props.alerta !== undefined &&
                  Object.entries(props.alerta.predictions).map((e) => {
                    if (arrayOfPrescriptions.includes(e[0])) {
                      const tmp = e[1] as any;

                      return (
                        <div
                          key={e[0]}
                          style={{
                            marginLeft: "3vw",
                            fontWeight: 400,
                            color: isDarkTheme ? "#fff" : "#000",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                          }}
                        >
                          <span>{sensorName(String(e[0]))}</span>
                          <div
                            style={{
                              marginLeft: "1vw",
                              fontWeight: 400,
                              color: isDarkTheme ? "#fff" : "#000",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                            }}
                          >
                            <span>{`Inicial: ${tmp["Valor atual"]} ${
                              unitDictionary[e[0]]
                            }`}</span>
                            <br></br>
                            <span>{`Recomendada: ${tmp["Valor recomendado"]} ${
                              unitDictionary[e[0]]
                            }`}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </>
            )}

            {!props.alerta.predictions &&
              props.alerta &&
              !props.alerta.finished && (
                <>
                  <Typography
                    style={{
                      fontWeight: 400,
                      color: isDarkTheme ? "#fff" : "#000",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                    }}
                    component="div"
                  >
                    Prescrição:
                  </Typography>
                  <Box
                    sx={{
                      pl: 3,
                      mt: 1,
                      fontWeight: 400,
                      color: isDarkTheme ? "#fff" : "#000",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                    }}
                  >
                    {props.alerta.message &&
                      Object.entries(JSON.parse(props.alerta.message)).map(
                        (e) => {
                          if (arrayOfPrescriptions.includes(e[0])) {
                            const tmp = e[1] as any;

                            return (
                              <Box
                                key={e[0]}
                                sx={{
                                  mb: 2,
                                  fontWeight: 400,
                                  color: isDarkTheme ? "#fff" : "#000",
                                  fontSize: "16px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                <Typography variant="subtitle1" gutterBottom>
                                  {sensorName(String(e[0]))}
                                </Typography>
                                <Box
                                  sx={{
                                    pl: 2,
                                    fontWeight: 400,
                                    color: isDarkTheme ? "#fff" : "#000",
                                    fontSize: "16px",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {props.alerta.alerts_number === 1 && (
                                    <Typography variant="body1" gutterBottom>
                                      {`Inicial: ${parseFloat(
                                        props.alerta.first_value
                                      ).toFixed(2)} ${
                                        unitDictionary[e[0]] !== undefined
                                          ? unitDictionary[e[0]]
                                          : "%"
                                      }`}
                                    </Typography>
                                  )}

                                  {props.alerta.alerts_number >= 2 && (
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                      }}
                                    >
                                      {`Atual: ${tmp["Valor atual"].toFixed(
                                        2
                                      )} ${
                                        unitDictionary[e[0]] !== undefined
                                          ? unitDictionary[e[0]]
                                          : "%"
                                      }`}
                                    </Typography>
                                  )}
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{
                                      color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                  >
                                    {`Recomendada: ${tmp[
                                      "Valor recomendado"
                                    ].toFixed(2)} ${
                                      unitDictionary[e[0]] !== undefined
                                        ? unitDictionary[e[0]]
                                        : "%"
                                    }`}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                  </Box>
                  <Box
                    sx={{
                      mt: 3,
                      fontWeight: 400,
                      color: isDarkTheme ? "#fff" : "#000",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 400,
                        color: isDarkTheme ? "#fff" : "#000",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                      }}
                      component="div"
                    >
                      Cenário:
                    </Typography>
                    <Box
                      sx={{
                        pl: 3,
                        mt: 1,
                        fontWeight: 400,
                        color: isDarkTheme ? "#fff" : "#000",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                      }}
                    >
                      {scenarioDataList.map((scenarioData, index) => (
                        <Box
                          key={scenarioData.sensorId}
                          sx={{
                            mb: 2,
                            fontWeight: 400,
                            color: isDarkTheme ? "#fff" : "#000",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                          ></Typography>
                          <Box
                            sx={{
                              pl: 2,
                              fontWeight: 400,
                              color: isDarkTheme ? "#fff" : "#000",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                            }}
                          >
                            <Typography
                              variant="body1"
                              gutterBottom
                              sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                            >
                              {`${scenarioData.name}: ${getTransformedValue(
                                scenarioData
                              )} ${
                                scenarioData.unit !== undefined
                                  ? scenarioData.unit
                                  : "%"
                              }`}
                            </Typography>
                          </Box>
                        </Box>
                      ))}

                      {scenarioDataList.length < scenarioVariables.length && (
                        <CircularProgress />
                      )}
                    </Box>
                  </Box>
                </>
              )}
          </>
        </DialogContent>

        <DialogActions
          sx={{
            color: isDarkTheme ? "#fff" : "#000",
            backgroundColor: isDarkTheme ? "#282828" : "#fff",
          }}
        >
          {isAdmin &&
            (props.alerta.finished ? (
              <>
                <Button onClick={handleClose}>Fechar</Button>
              </>
            ) : (
              <>
                <Button
                  sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                  onClick={handleClose}
                >
                  Fechar
                </Button>
                <Button
                  sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                  onClick={() => {
                    handleCiente(props.alerta.id);
                  }}
                  disabled
                >
                  Ciente
                </Button>
              </>
            ))}

          {!isAdmin &&
            (props.alerta.finished ? (
              <>
                <Button
                  sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                  onClick={handleClose}
                >
                  Fechar
                </Button>
                <Button
                  sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                  onClick={() => {
                    handleCiente(props.alerta.id);
                  }}
                >
                  Ciente
                </Button>
              </>
            ) : (
              <>
                {!props.alerta.acknowledged && (
                  <>
                    <Button
                      sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                      onClick={() => {
                        setOpenModalJustify(true);
                      }}
                    >
                      Ciente-Justificar
                    </Button>
                    <Button
                      sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                      onClick={() => {
                        handleCiente(props.alerta.id);
                      }}
                    >
                      Ciente-Seguir
                    </Button>
                  </>
                )}

                {props.alerta.acknowledged && (
                  <>
                    <Button
                      sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                      onClick={handleClose}
                    >
                      Fechar
                    </Button>
                    <Button
                      sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                      onClick={() => {
                        handleCiente(props.alerta.id);
                      }}
                      disabled
                    >
                      Ciente
                    </Button>
                  </>
                )}
              </>
            ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
