import { useState } from "react"

const useField = (type: string) => {
    const [value, setValue] = useState('')

    const onChange = (e:any) => {
        setValue(e.target.value)
    }
    
    return{
        type, value, onChange
    }
}

export default useField;