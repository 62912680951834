import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GraphCard from "../../Components/Cards/GraphCard/GraphCard";
import SensorCard from "../../Components/Cards/SensorCard/SensorCard";
import NewGraphForm from "../../Components/Forms/ChartForms/NewChartForm";
import NewSensorForm from "../../Components/Forms/NewSensorForm/NewSensorForm";

import DbService from "../../Services/LocalDataService";

// import logo from "../../Assets/Images/logo_asa.png";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";
import { ReactComponent as Icon2 } from "../../Assets/Icons/edit.svg";

import "./DigitalTwinView.css";
import GraphDetailsCard from "../../Components/Cards/GraphDetailsCard/GraphDetailsCard";
import SensorDetailsCard from "../../Components/Cards/SensorDetailsCard/SensorDetailsCard";
import UploadFileModal from "../../Components/Modals/UploadFileModal";
import { Paper } from "@mui/material";

import { Button } from "@mui/material";

interface Idigitaltwin {
  id: number;
  name: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  sensors: Array<Isensor>;
  graphs: Array<Igraph>;
  company: any;
}

interface Isensor {
  id: string;
  name: string;
  number: number;
  graphs: Array<Igraph>;
}

interface Igraph {
  id: number;
  name: string;
}

const initialState: Idigitaltwin = {
  id: 0,
  name: "",
  inclusion_date: "",
  createdAt: "",
  updatedAt: "",
  sensors: [],
  graphs: [],
  company: null,
};

const DigitalTwinView = (props: any) => {
  const { id } = useParams();

  const [sensors, setSensors] = useState<Array<Isensor>>([]);
  const [graphs, setGraphs] = useState<Array<Igraph>>([]);

  const [digitaltwin, setDigitaltwin] = useState<Idigitaltwin>(initialState);

  // const [selectedSensorId, setSelectedSensorId] = useState<number>(0);

  const [addingSensor, setAddingSensor] = useState<boolean>(false);
  const [addingGraph, setAddingGraph] = useState<boolean>(false);

  const [selectedSensor, setSelectedSensor] = useState<number | null>(null);
  const [selectedGraph, setSelectedGraph] = useState<number | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  // const togle = (assetId: number) => {
  //   // console.log(assetId);
  //   setSelectedSensorId(assetId);

  //   DbService.get_graphs(assetId.toString())
  //     .then((data) => setGraphs(data))
  //     .then(() => // console.log(graphs));

  //   // DbService.get_digitalTwins(assetId.toString())
  //   //   .then((data) => setDigitalTwins(data))
  //   //   .then(() => // console.log(digitalTwins));
  //   // console.log(selectedSensorId);
  // };

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "sensorsSearch") {
      if (digitaltwin.sensors) {
        const filteredSensors = digitaltwin.sensors.filter((sensor) => {
          if (sensor.name.toLowerCase().includes(value.toLowerCase())) {
            return sensor;
          } else {
            return undefined;
          }
        });
        setSensors(filteredSensors);
      }
    } else {
      if (digitaltwin.graphs) {
        const filteredGraphs = digitaltwin.graphs.filter((graph) => {
          if (graph.name.toLowerCase().includes(value.toLowerCase())) {
            return graph;
          } else {
            return undefined;
          }
        });
        setGraphs(filteredGraphs);
      }
    }
  };

  // const fetchSensors = () => {};

  useEffect(() => {
    if (id) {
      DbService.get_digitalTwin(id).then((data) => {
        setDigitaltwin(data);
        setSensors(data.sensors);
        setGraphs(data.graphs);
      });
    }
  }, [id]);

  const handleNewGraph = () => {
    if (id) {
      DbService.get_digitalTwin(id).then((data) => {
        setDigitaltwin(data);
        setSensors(data.sensors);
        setGraphs(data.graphs);
        setAddingGraph(false);
        setSelectedGraph(null);
      });
    }
  };

  const handleNewSensor = () => {
    if (id) {
      DbService.get_digitalTwin(id).then((data) => {
        setDigitaltwin(data);
        setSensors(data.sensors);
        setGraphs(data.graphs);
        setAddingSensor(false);
        setSelectedSensor(null);
      });
    }
  };

  return (
    <>
      <div className="mainViewDt">
        <Paper
          className="collumn"
          style={{
            backgroundColor: props.isDarkTheme ? "#323238" : "white",
            color: !props.isDarkTheme ? "#323238" : "white",
            height: "calc(100vh - 110px)",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="1">
            <p className="sectionTittle">Sensores</p>
            <div className="groupDiv">
              {digitaltwin.company && (
                <>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/logos/" +
                      digitaltwin.company.group.name +
                      ".png"
                    }
                    alt="Logo"
                  />
                  <p>
                    {digitaltwin.company.group.name} {">"}{" "}
                    {digitaltwin.company.name} {">"} {digitaltwin.name}
                  </p>
                </>
              )}

              <Icon2 className="editIcon" />
            </div>
            {addingSensor ? (
              <div>
                <NewSensorForm
                  handleResponse={handleNewSensor}
                  digitalTwinId={id}
                  cancel={() => {
                    setAddingSensor(false);
                  }}
                />
              </div>
            ) : selectedSensor ? (
              <SensorDetailsCard
                handleResponse={handleNewSensor}
                sensor={sensors.find(
                  (sensor) => parseInt(sensor.id) === selectedSensor
                )}
                cancel={() => {
                  setSelectedSensor(null);
                }}
              />
            ) : (
              <div>
                <div className="searchDiv">
                  <input
                    type="text"
                    name="sensorsSearch"
                    onChange={searchInputHandler}
                  />
                  <Icon />
                </div>
                <div
                  style={{
                    height: "calc(100vh - 390px)",
                    // border: "0.1vh solid #16161627",
                    overflowY: "auto",
                    borderRadius: "0.5rem",
                    margin: "10px",
                  }}
                >
                  {sensors.map((sensor: any) => {
                    return (
                      <SensorCard
                        key={sensor.id}
                        sensor={sensor}
                        onClick={() => {
                          setSelectedSensor(sensor.id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div style={{ alignSelf: "end" }}>
            {!addingSensor && (
              <>
                <Button
                  onClick={() => {
                    setAddingSensor(!addingSensor);
                  }}
                  style={{
                    backgroundColor: "#124251",
                    color: "#FFF",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  variant="contained"
                >
                  Adicionar Sensor
                </Button>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  style={{
                    backgroundColor: "#124251",
                    color: "#FFF",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  variant="contained"
                >
                  teste
                </Button>
                {/* <button
                  className="bottomButton"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  teste
                </button>
                <button
                  className="bottomButton"
                  onClick={() => {
                    setAddingSensor(!addingSensor);
                  }}
                >
                  Adicionar Sensor
                </button> */}
              </>
            )}
          </div>
        </Paper>

        <Paper
          className="GraphsContainer"
          style={{
            backgroundColor: props.isDarkTheme ? "#323238" : "white",
            color: !props.isDarkTheme ? "#323238" : "white",
            height: "calc(100vh - 110px)",
            margin: "10px 10px 0 0px",
            // overflowY: "auto",
            width: "calc(100vw - 720px)",
          }}
        >
          <div
            className="collumn"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p className="sectionTittle">Gráficos</p>

              {digitaltwin.id === 0 ? (
                <h1>waiting</h1>
              ) : (
                <div>
                  <div className="searchDiv">
                    <input
                      type="text"
                      name="graphSearch"
                      onChange={searchInputHandler}
                    />
                    <Icon />
                  </div>
                  {graphs.map((graph: any) => {
                    return (
                      <GraphCard
                        key={graph.id}
                        graph={graph}
                        onClick={() => {
                          setSelectedGraph(graph.id);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div style={{ alignSelf: "end" }}>
              {addingGraph ? (
                // <button
                //   className="bottomButton"
                //   onClick={() => {
                //     setAddingGraph(!addingGraph);
                //   }}
                // >
                //   Cancelar
                // </button>
                <Button
                  onClick={() => {
                    setAddingGraph(!addingGraph);
                  }}
                  style={{
                    backgroundColor: "#124251",
                    color: "#FFF",
                    alignSelf: "flex-end",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  variant="contained"
                >
                  Cancelar
                </Button>
              ) : selectedGraph ? (
                // <button
                //   className="bottomButton"
                //   onClick={() => {
                //     setSelectedGraph(null);
                //   }}
                // >
                //   voltar
                // </button>
                <Button
                  onClick={() => {
                    setSelectedGraph(null);
                  }}
                  style={{
                    backgroundColor: "#124251",
                    color: "#FFF",
                    alignSelf: "flex-end",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  variant="contained"
                >
                  voltar
                </Button>
              ) : (
                // <button
                //   className="bottomButton"
                //   onClick={() => {
                //     setAddingGraph(!addingGraph);
                //   }}
                // >
                //   Adicionar Grafico
                // </button>
                <Button
                  onClick={() => {
                    setAddingGraph(!addingGraph);
                  }}
                  style={{
                    backgroundColor: "#124251",
                    color: "#FFF",
                    alignSelf: "flex-end",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  variant="contained"
                >
                  Adicionar Grafico
                </Button>
              )}
            </div>
          </div>

          <div
            className="?"
            style={{ overflowY: "auto", height: "calc(100vh - 130px)" }}
          >
            {/* <p className="sectionTittle"></p> */}
            {addingGraph ? (
              <div
              //  style={{overflowY: "auto", maxHeight: "95vh"}}
              >
                <NewGraphForm
                  sensors={digitaltwin.sensors}
                  handleResponse={handleNewGraph}
                  digitalTwinId={id}
                  cancel={() => {
                    setAddingGraph(false);
                  }}
                />
              </div>
            ) : (
              selectedGraph !== null && (
                <div>
                  <GraphDetailsCard
                    handleResponse={handleNewGraph}
                    graph={graphs.find((graph) => graph.id === selectedGraph)}
                    sensors={digitaltwin.sensors}
                  />
                </div>
              )
            )}
          </div>
        </Paper>
      </div>
      {showModal && (
        <UploadFileModal
          digital_twind_id={id}
          cancel={() => {
            handleNewSensor();
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
export default DigitalTwinView;
