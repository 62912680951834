import { Box, CardMedia, ListItem, Menu, MenuItem, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DataService from "../../../Services/DataService";
import NotificationBadge from "../../NotificationBadge/NotificationBadge";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "../../../store/slices/companyslice";
import { getIsDarkTheme } from "../../../store/slices/themeSlice";
import { NavigateBeforeRounded } from "@mui/icons-material";
import { setCharts } from "../../../store/slices/monitoringSlice";
import {  setDigitalTwinsList } from "../../../store/slices/digitalTwinsListSlice";

interface GroupCardWithCompaniesProps {
  group: any;
}

const GroupCardWithCompanies = (props: GroupCardWithCompaniesProps) => {
  const dispatch = useDispatch();

  const [activeMenu, setActiveMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [qtdGemeosOk, setQtdGemeosOk] = useState<any>(0);
  const [qtdGemeosAlertado, setQtdGemeosAlertado] = useState<any>(0);
  const [qtdGemeosAlarmado, setQtdGemeosAlarmado] = useState<any>(0);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(!activeMenu);
  };

  const handleClose = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    let isMounted = true;
    let normal = 0;
    let alerta = 0;
    let alarme = 0;

    props.group.companies.forEach((company) => {
      DataService.getCompanyTwinsStatus(company.id)
        .then((res) => {
          if (isMounted) {
            normal = res.normal;
            alerta = res.alert;
            alarme = res.alarm;
          }
        })
        .then(() => {
          if (isMounted) {
            setQtdGemeosOk(normal);
            setQtdGemeosAlertado(alerta);
            setQtdGemeosAlarmado(alarme);
          }
        });
    });

    const interval = setInterval(() => {
      props.group.companies.forEach((company) => {
        DataService.getCompanyTwinsStatus(company.id)
          .then((res) => {
            if (isMounted) {
              normal = res.normal;
              alerta = res.alert;
              alarme = res.alarm;
            }
          })
          .then(() => {
            if (isMounted) {
              setQtdGemeosOk(normal);
              setQtdGemeosAlertado(alerta);
              setQtdGemeosAlarmado(alarme);
            }
          });
      });
    }, 180000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [props.group.companies]);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      
        setStarted(true);
      
    }, Math.random() * 500);
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Paper
        onClick={handleClick}
        elevation={0}
        sx={{
          
          cursor: "pointer",
          height: "273px",
          width: "273px",
          display: "flex",
          flexDirection: "column",
       
          opacity: started ? 1 : 0 ,transition: "opacity 0.5s ease-in-out",
          
        }}
      >
       
          
            <CardMedia
            sx={{padding: '10px'}}
              component="img"
              image={
                process.env.PUBLIC_URL + "/logos/" + props.group.name + ".png"
              }
              alt={props.group.name}
            />
         
        

        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "60px",
            justifyContent: "space-around",
            padding: "0px",
            alignItems: "center",
          }}
        >
          <NotificationBadge
            type="greenBadge"
            notificationNumber={qtdGemeosOk}
          />
          <NotificationBadge
            type="yellowBadge"
            notificationNumber={qtdGemeosAlertado}
          />
          <NotificationBadge
            type="redBadge"
            notificationNumber={qtdGemeosAlarmado}
          />
        </Box>
      </Paper>

      <Menu
        elevation={1}
       
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={activeMenu && props.group.companies.length !== 0}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        
        
       
      >
        <MenuItem sx={{padding: '0px' }}> 
        
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            }}
          >
            <Typography
              variant="h6"
              >Selecione a empresa</Typography>
          </Box>

          </MenuItem>
        
        
          {props.group.companies.map((companhia) => {
            return (
              
              <MenuItem
              onClick={() => {
                handleClose();
                navigate('/painel')
                dispatch(
                  setCompany({ id: companhia.id, name: companhia.name })
                );
                dispatch(setCharts([]));
                
                DataService.getTwinsByCompany(companhia.id).then(async(res) => {
                  dispatch(setDigitalTwinsList({ companyId: companhia.id, payload: res }));
                 
                }
                )
              }}
              key={companhia.id}
               >
                <ListItem disablePadding sx={{ linkStyle: "none", textDecoration: "none" }} >{companhia.name}</ListItem>
              
              </MenuItem>
              
            );
          })}
      </Menu>
    </>
  );
};
export default GroupCardWithCompanies;
