import LocalDataService from "../../../Services/LocalDataService";
import { useState } from "react";
import DeleteConfirmationDialog from "../../DeleteConfirmatioDialog/DeleteConfirmationDialog";
import EditForm from "../../Forms/NewCompanyForm/EditCompanyForm";

const CompanyDetailsCard = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleDelete = async () => {
    setModal(true);
  };

  const handleAction = async (event: any) => {
    const { name } = event.target;

    if (name === "confirm") {
      await LocalDataService.deleteCompany(props.company.id);
      props.handleResponse();
    } else {
      setModal(false);
    }
  };
  return (
    <div className="GraphDetailsCard">
      <EditForm
        company={props.company}
        onClickDelete={handleDelete}
        handleResponse={props.handleResponse}
      />
      {modal && (
        <DeleteConfirmationDialog
          text="Certeza que quer deletar?"
          handleAction={handleAction}
        />
      )}
    </div>
  );
};
export default CompanyDetailsCard;
