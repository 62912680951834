import axios from "axios";
import { useEffect, useState } from "react";
import { ReactComponent as Ok } from "../../Assets/Icons/check_white.svg";
import "./FeedbackModal.css";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserName } from "../../store/slices/userSlice";

const FeedbackModal = (props: any) => {
  const [message, setMessage] = useState<any>();
  const [disabeButtons, setDisabeButtons] = useState(false);
  const [resultState, setResultState] = useState<any>(null);
  const userName = useSelector(getUserName);

  const sendFeedback = async () => {
    if (message === "") {
      alert("A mensagem está vazia");
    } else {
      setDisabeButtons(true);
      const result = await axios.post("/feedback", {
        user: userName,
        feedbackMessage: message,
      });
      if (result.status === 200) {
        setDisabeButtons(false);

        setResultState(200);
      } else {
        setDisabeButtons(false);

        setResultState(500);
      }
    }
  };

  useEffect(() => {
    message !== undefined ? setDisabeButtons(false) : setDisabeButtons(true);
  }, [message]);

  return (
    <div className="FeedbackModal">
      <div className="mainModal">
        <div className="head">
          <b>
            <div
              style={{
                color: "white",
                fontFamily: "Roboto, Courier, monospace",
                fontWeight: "600",
                fontSize: "20px",
                padding: "10px",
              }}
            >
              Feedback
            </div>
          </b>
        </div>
        {resultState === null ? (
          <div className="body">
            <div style={{ fontSize: "medium", paddingBottom: "10px" ,color: "#000"}}>
              Como tem sido sua experiência com a plataforma?
            </div>

            <textarea
              rows={7}
              cols={5}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
            <div
              className="buttonsModal"
              style={{
                justifyContent: disabeButtons ? "center" : "space-between",
              }}
            >
              <button
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                style={{
                  border: "0.50px solid #124251",
                  backgroundColor: "white",
                  color: "#124251",
                  marginTop: "10px",
                  padding: "10px",
                  borderRadius: "6px",
                  flexGrow: "1",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      color: "#124251",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      wordWrap: "break-word",
                    }}
                  >
                    Cancelar
                  </p>
                </div>
              </button>
              {!disabeButtons && (
                <button
                  id={!disabeButtons ? "sendButton" : "cancelButton"}
                  disabled={disabeButtons}
                  onClick={sendFeedback}
                  style={{
                    border: "0.50px solid #EBEBEB",
                    backgroundColor: "#124251",
                    color: "#FFF",
                    marginTop: "10px",
                    padding: "10px",
                    borderRadius: "6px",
                    flexGrow: "1",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px",
                        color: "#FFF",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        wordWrap: "break-word",
                      }}
                    >
                      Enviar
                    </p>
                  </div>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="body">
            {resultState === 500 ? (
              <div
                style={{ display: "flex", height: "7vh", margin: "0 0 5vh 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#C04040",
                    width: "10px",
                    borderRadius: "6px 0  0 6px",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F0D0D0",
                    flexGrow: "1",
                    borderRadius: "0 6px 6px 0 ",
                  }}
                >
                  <Ok style={{ margin: "0 10px" }} />
                  <div>
                    <h3 style={{ margin: "0" }}>Erro interno</h3>
                    <p style={{ margin: "0" }}>Tente novamente mais tarde</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ display: "flex", height: "7vh", margin: "0 0 10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#118751",
                    width: "10px",
                    borderRadius: "6px 0  0 6px",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#DEE9E4",
                    flexGrow: "1",
                    borderRadius: "0 6px 6px 0 ",
                  }}
                >
                  <Ok style={{ margin: "0 10px" }} />
                  <div>
                    <h3 style={{ margin: "0" }}>Email enviado com sucesso</h3>
                  </div>
                </div>
              </div>
            )}

            <div className="buttonsModal" style={{ justifyContent: "center" }}>
              <Button
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  height: "40px",
                  width: "180px",
                  position: "relative",
                  minWidth: "100px",
                  marginTop: "10px",
                  backgroundColor: "#124251",
                  color: "#f4f7fe",
                  padding: "10px 30px",
                }}
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                disabled={disabeButtons}
              >
                Fechar
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FeedbackModal;
