import React, { createContext, useContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#fff',
      },
      secondary: {
        main: '#0c5e5a',
      },
      background: {
        default: '#f5f5f5',
        secondary:'#0c5e5a',
      },
      text:{
        main: '#fff',
        contrastText: '#000',
      },
      
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#303030',
      },
      background: {
        default: '#303030',
        secondary:'#303030',
      },
      text: {
        main: '#fff',
        contrastText: '#000',
      },
    },
    
  });

  

  const theme = useMemo(() => (isDarkTheme ? darkTheme : lightTheme), [isDarkTheme, lightTheme, darkTheme]);

  const toggleTheme = () => {
    setIsDarkTheme((prev) => !prev);
  };

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);