import { Button, Card, CardMedia } from "@mui/material";
import { useState } from "react";
import "./GroupCard.css";

import {useSelector } from "react-redux";
import { getIsDarkTheme } from "../../../store/slices/themeSlice";

const GroupCard = (props: any) => {
  const isDarkTheme = useSelector(getIsDarkTheme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Button
      id="basic-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      sx={{ display: "flex" }}
    >
      <Card
        sx={{
          cursor: "pointer",
          height: "273px",
          width: "273px",
          display: "flex-column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          backgroundColor: isDarkTheme ? "#323238" : "white",
          color: !isDarkTheme ? "#323238" : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "273px",
            height: "213px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <div style={{ width: "200px", margin: "auto" }}>
            <CardMedia
              component="img"
              image={
                process.env.PUBLIC_URL + "/logos/" + props.group.name + ".png"
              }
              alt={props.group.name}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: "60px",
            justifyContent: "space-around",
            padding: "0px",
            alignItems: "center",
          }}
        >
          {props.group.name}
        </div>
      </Card>
    </Button>
  );
};
export default GroupCard;