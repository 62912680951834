import CardAlertas from "./CardAlertas";

const WrapWithCardAlertas = ({ component }) => {
  return (
    <>
      <CardAlertas />
      {component}
    </>
  );
};

export default WrapWithCardAlertas;
