import { useCallback, useEffect, useState } from "react";
import "./ActivationView.css";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../router/paths";
import AuthService from "../../Services/AuthService";
import loginBackground from "../../Assets/Images/loginBackground2.png";
import { Dialog } from "@mui/material";


const ActivationView = () => {

  const [open, setOpen] = useState(true);
  const [error , setError] = useState<string>("");
  const [response , setResponse] = useState<string>("");

  const navigate = useNavigate();
  const token = useParams().token;

  const userActivated = useCallback(() => {
    setResponse("Conta ativada");
    setTimeout(() => {
      setResponse("");
      setOpen(false);
      navigate(paths.LOGIN_SCREEN);
      
    }, 3000);
  }, [navigate]);

  const userNotActivated = useCallback(() => {
    setError("Conta não ativada");
    setTimeout(() => {
      setError("");
      setOpen(false);
      navigate(paths.LOGIN_SCREEN);
      
    }, 3000);
  }, [navigate]);

  const activation = useCallback(async ( ) => {

    try {
      await AuthService.activationCode(token)
      
      userActivated();
      
    } catch (error) {
      userNotActivated()
    }
    
  }, [token,userActivated,userNotActivated]);
  
  useEffect(() => {
    activation();
  }, [activation]);
 
 
  return (
    <div className="ActivationContainer" style={{
      backgroundImage: `url(${loginBackground}) `,
      backgroundSize: "cover",
    }}>
      <Dialog open={open}>
        <div style={{ textAlign: "center",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         margin: "20px",
         }}>
            <p style={{fontSize:"2rem"}}>Ativação de conta...</p>
            {error !== "" && <p style={{color:"red"}}>{error}</p>}
            {response !== "" && <p style={{color:"green"}}>{response}</p>}
        </div>
      </Dialog>
    </div>
  );
};
export default ActivationView;
