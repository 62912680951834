interface Sensor {
  name: string;
  number: number;
  deviceId: string;
  data: Array<any>;
  unit: string;
}

interface AnalyticsLineChartProps {
  yAxis1: Array<Sensor>,
  theme?: boolean

}

const analyticsLineChart = ( props : AnalyticsLineChartProps) => {
  
  const COLORS_DARK = [ "#F0E442","#7852E0", "#D55E00", "#009E73", "#fff"];
  const COLORS = [
    "rgb(18, 66, 81)",
    "#98CBFF",
    "#548BBB",
    "#009E73",
    "#000000",
    "#F0E442",
    "#0072B2",
    "#D55E00",
    "#CC79A7",
  ];

  const legends: Array<string> = props.yAxis1.map((sensor) => sensor.name);
  const series = props.yAxis1.map((sensor, index) => ({
    data: sensor.data,
    name: sensor.name,
    type: "line",
    showSymbol: false,
    symbolSize: 5,
    symbol: "circle",
    color: props.theme ? COLORS_DARK[index % COLORS_DARK.length] : COLORS[index % COLORS.length],
    smooth: false,
  }));

  return {
    xAxis: {
      type: "time",
    },
    yAxis: {
      name: props.yAxis1[0]?.unit,
      type: "value",
      alignTicks: true,
      scale: true,
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#979797",
        },
      },
      axisLabel: {
        formatter: "{value}",
       
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: legends,
      orient: "horizontal",
      bottom: 10,
      selectedMode: true,
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      left: 20,
      right: 20,
      top: 30,
      bottom: 28,
      containLabel: true,
    },
    series: series,
  };
};

export default analyticsLineChart;
