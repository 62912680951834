
import styled from "styled-components";

const DraggableHandle = styled.div`
cursor:grab;

width: calc(100% - 160px);
height: 50px;

z-index: 100;

  `





export {
   
  DraggableHandle
}
