import React, { useState, useEffect } from "react";

interface PositionProps {
  positionString: string;
  onChange: (positionString: string) => void;
}

const PositionInput: React.FC<PositionProps> = ({
  positionString,
  onChange,
}) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    minW: 0,
    minH: 0,
  });

  useEffect(() => {
    try {
      const posObj = JSON.parse(positionString);
      setPosition(posObj);
    } catch (error) {
      console.error("Error parsing position string:", error);
    }
  }, [positionString]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedPosition = {
      ...position,
      [name]: Number(value),
    };
    setPosition(updatedPosition);
    onChange(JSON.stringify(updatedPosition));
  };

  return (
    <div className="position-input-group">
      <h4>Configurações de Posição</h4>
      {[
        { field: "x", label: "Posição X (Horizontal)" },
        { field: "y", label: "Posição Y (Vertical)" },
        { field: "w", label: "Largura" },
        { field: "h", label: "Altura" },
        { field: "minW", label: "Largura Mínima" },
        { field: "minH", label: "Altura Mínima" },
      ].map(({ field, label }) => (
        <div key={field} className="form-div-input">
          <input
            id={field}
            name={field}
            type="number"
            value={position[field] || ""}
            onChange={handleInputChange}
            placeholder={label}
          />
          <label htmlFor={field}>{label}</label>
        </div>
      ))}
    </div>
  );
};

export default PositionInput;
