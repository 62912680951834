import { useState, useEffect } from "react";

interface GainInterface {
  chartRef: any;
  isDarkTheme: any;
  isAlarm?: boolean;
  isGain?: boolean;
}

const Gain = ({ chartRef, isDarkTheme, isAlarm, isGain=true }: GainInterface) => {
  const [gain, setGain] = useState("0");
  const [aMean, setAMean] = useState("...");
  const [rMean, setRMean] = useState("...");

  useEffect(() => {
    const calculateGain = () => {
      const chartInstance = chartRef.current?.getEchartsInstance();
      const option = chartInstance?.getOption();

      if (!option || !option.series || option.series.length < 2) {
        return "0";
      }

      const actualValuesFrequency = option.series[0].data.map((data, idx) =>
        parseFloat(data[1])
      );
      const recommendedValuesFrequency = option.series[1].data.map(
        (data, idx) => parseFloat(data[1])
      );

      // Calculate the means
      const actualMean =
        actualValuesFrequency.reduce((a, b) => a + b, 0) /
        actualValuesFrequency.length;
        setAMean(actualMean.toFixed(2));
      const recommendedMean =
        recommendedValuesFrequency.reduce((a, b) => a + b, 0) /
        recommendedValuesFrequency.length;
        setRMean(recommendedMean.toFixed(2));

      // Calculate the percentual difference between means
      const percentualDifference =
        Math.abs(actualMean - recommendedMean) / actualMean;

      if (isNaN(percentualDifference) || !isFinite(percentualDifference)) {
        return "Dados Insuficientes";
      }

      return (percentualDifference * 100).toFixed(2) + "%";
    };

    const updateGain = () => {
      const newGain = calculateGain();
      setGain(newGain);
    };

    const chartInstance = chartRef.current?.getEchartsInstance();
    chartInstance?.on("finished", updateGain);

    return () => {
      chartInstance?.off("finished", updateGain);
    };
  }, [chartRef, isAlarm]);

  return (
    <div
      style={{
        backgroundColor: isDarkTheme ? "#323238" : "#fdfdff",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "90%",
      }}
    >
      {isGain ? (
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: gain === "Dados Insuficientes" ? "2vw" : "5vw",
            color: isDarkTheme ? "#fff" : "#124251",
          }}
        >
          {gain}
        </span>
      ) : (
        <div>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "600",
              color: isDarkTheme ? "#fff" : "#3cb2ef",
              marginRight: "20px",
            }}
          >
            X̅ = {aMean}
          </span>

          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "600",
              color: isDarkTheme ? "#fff" : "#ff0000",
            }}
          >
            X̅ = {rMean}
          </span>
        </div>
      )}
    </div>
  );
};

export default Gain;
