import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import paths from "../../router/paths";
import axios from "axios";
import GroupCard from "../../Components/Cards/GroupCard/GroupCard";
import { useSelector } from "react-redux";
import { getIsAdmin } from "../../store/slices/userSlice";
import { getIsDarkTheme } from "../../store/slices/themeSlice";

const AnalyticsView = () => {
  const [groups, setGroups] = useState<any>([]);
  const isAdmin = useSelector(getIsAdmin);
  const  isDarkTheme = useSelector(getIsDarkTheme);
  useEffect(() => {
    if (isAdmin) {
      axios.get("/groupsWithCompanies").then((response) => {
        setGroups(response.data);
      });
    } else {
      axios.get("/groupsWithCompanies").then((response) => {
        setGroups(response.data.filter((group) => group.name === "Asa"));
      });
    }

  }, [isAdmin]);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor:isDarkTheme ? "#161616" : "#f4f7fe",
        height: "calc(100vh - 72px)",
        width: "calc(100vw - 70px)",
        padding: "20px",
        gap: "10px",
      }}
    >
      {groups.length > 0 &&
        groups.map((group) => {
          return (
            
              <Link
                key={group.id}
                to={`${paths.ANALYTICS_HOME_PAGE}/${group.companies[0]?.id}`}
                state={group}
                style={{
                  textDecoration: "none",
                }}
              >
                <GroupCard key={group.id} group={group} onClick={() => {}} />
              </Link>
            
          );
        })}
    </div>
  );
};
export default AnalyticsView;
