import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { DateRangePicker } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import '../../Views/RecommendationHistoryView/DateRangePickerStyles.css';
import Di2winDataService from "../../Services/NewDi2winDataService";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../../Views/RecommendationHistoryView/DateRangePickerStyles.css";
import downloadCsvHelper from "../../Utils/downloadCsvHelper";

interface ParametersProps {
  focusedInput: any;
  setFocusedInput: any;

  startDate1: any;
  setStartDate1: any;

  endDate1: any;
  setEndDate1: any;

  setStartTime: any;
  startTime: any;
  setEndTime: any;
  endTime: any;
  digitalTwin: any;
  setDigitalTwin: any;
  selectedSensor: any;
  setSelectedSensor: any;
  handleSubmit: any;
  isDarkTheme: any;
  formatInputDate: any;
  data: any;
}

const Parameters = (props: ParametersProps) => {
  const [dataToDownload, setDataToDownload] = useState<any>([]);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);

  useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsReadyToDownload(true);
    } else {
      setIsReadyToDownload(false);
    }
  }, [dataToDownload]);

  const handleDownloadCSV = () => {
    const localStartDate = props.startDate1.clone().startOf("day");
    const localEndDate = props.endDate1.clone().endOf("day");
    const range = { start: localStartDate, end: localEndDate };

    let auxDates = props.formatInputDate(props.startDate1, props.endDate1, props.startTime, props.endTime);

    const fetchedFrequencyDataPromises = [
      Di2winDataService
      .fetchAnalyticsData("1000", 6, [range]).then((res) => ({
        name: "Frquencia em Alarme Lida",
        sensor: 6,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 5, [range]).then((res) => ({
        name: "Frquencia em Alarme Recomendada",
        sensor: 5,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 8, [range]).then((res) => ({
        name: "Frquencia Total Lida",
        sensor: 8,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 7, [range]).then((res) => ({
        name: "Frquencia Total Lida Recomendada",
        sensor: 7,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),
    ];

    const fetchedHistoricalDataPromise =[
      Di2winDataService
      .getRawInfo(6, "1000", localStartDate, localEndDate)
      .then((res) => ({
        name: "CEGN em Alarme Lido",
        sensor: 6,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .getRawInfo(5, "1000", localStartDate, localEndDate)
      .then((res) => ({
        name: "CEGN em Alarme Recomendado",
        sensor: 5,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .getRawInfo(8, "1000", localStartDate, localEndDate)
      .then((res) => ({
        name: "CEGN Total Lido",
        sensor: 8,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),
      
      Di2winDataService
      .getRawInfo(7, "1000", localStartDate, localEndDate)
      .then((res) => ({
        name: "CEGN Total Recomendado",
        sensor: 7,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })) 
    ];

    const fetchedAlarmDataPromise = [
      Di2winDataService
      .getAlarmData(props.data.digitalTwins[props.digitalTwin].id, auxDates[0], auxDates[1])
      .then((res) =>({
          name: "Tempo em Alarme",
          digitalTwinId: props.data.digitalTwins[props.digitalTwin].id,
          startDate: localStartDate,
          endDate: localEndDate,
          rawData: res,
          unit: props.selectedSensor.unit.abbreviation,
      }))
    ];

    const allPromises =  [...fetchedHistoricalDataPromise, ...fetchedFrequencyDataPromises, ...fetchedAlarmDataPromise];
    
    Promise.all(allPromises).then((fetchedData) => {
      setDataToDownload(fetchedData);
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  const isOutsideRange = (day) => {
    return day.isAfter();
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
          justifyContent: "stretch",
          display: "flex-column",
          height: "90vh",
          margin: "10px",
          position: "relative",
        }}
      >
        <div
          style={{
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "larger",
            marginBottom: "5%",
            padding: "10px",
            color: props.isDarkTheme ? "white" : "#124251",
          }}
        >
          Parâmetros:
        </div>

        {props.data ? (
          <form action="" onSubmit={props.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sx={{ marginBottom: "3vh", height: "5.5vh" }}>
                <div
                  className="dateRangeWrapper"
                  style={{
                    backgroundColor: props.isDarkTheme ? "#323238" : "white",
                    color: !props.isDarkTheme ? "#323238" : "white",
                    justifyContent: "space-around",
                    display: "flex",
                  }}
                >
                  <div
                    className={props.isDarkTheme ? "dark-theme" : "light-theme"}
                  >
                    <DateRangePicker
                      minimumNights={0}
                      noBorder={true}
                      startDate={props.startDate1}
                      startDateId={START_DATE}
                      endDate={props.endDate1}
                      endDateId={END_DATE}
                      onDatesChange={({ startDate, endDate }) => {
                        props.setStartDate1(startDate);
                        props.setEndDate1(endDate);
                      }}
                      focusedInput={props.focusedInput}
                      onFocusChange={(focusedInput) =>
                        props.setFocusedInput(focusedInput)
                      }
                      isOutsideRange={(day) => isOutsideRange(day)}
                      displayFormat={() => "DD/MM/YYYY"}
                      startDatePlaceholderText="Data Inicial"
                      endDatePlaceholderText="Data Final"
                      id="ySensors1"
                      name="ySensors1"
                    />
                  </div>
                  <label
                    htmlFor="ySensors1"
                    style={{
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                    }}
                  >
                    Período
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: "3vh", height: "5.5vh" }}>
                <div
                  className="form-div-range"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: "5.5vh",
                  }}
                >
                  <input
                    id="time"
                    name="time"
                    type="time"
                    onChange={(e) => {
                      props.setStartTime(e.target.value);
                    }}
                    style={{
                      padding: "10px",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                    }}
                  />
                  <p
                    className="timeArrow "
                    style={{
                      padding: "10px",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                    }}
                  >
                    →
                  </p>
                  <input
                    id="time"
                    name="time"
                    type="time"
                    onChange={(e) => {
                      props.setEndTime(e.target.value);
                    }}
                    style={{
                      padding: "10px",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                    }}
                  />
                  <label
                    htmlFor="time"
                    style={{
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                    }}
                  >
                    Horário
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: "3vh", height: "5.5vh" }}>
                <div
                  className={props.isDarkTheme ? "dark-theme" : "light-theme"}
                >
                  <TextField
                    id="unidadeFabril"
                    select
                    label="Unidade Fabril"
                    size="small"
                    sx={{
                      width: "100%",
                      color: !props.isDarkTheme ? "#323238" : "white",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                    }}
                  >
                    <MenuItem
                      value={"afogados"}
                      sx={{
                        width: "100%",
                        color: !props.isDarkTheme ? "#323238" : "white",
                        backgroundColor: props.isDarkTheme
                          ? "#323238"
                          : "white",
                      }}
                    >
                      Afogados
                    </MenuItem>
                  </TextField>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: "3vh", height: "5.5vh" }}>
                <TextField
                  id="digitalTwin"
                  select
                  label="Gêmeo Digital"
                  size="small"
                  value={props.digitalTwin}
                  onChange={(e) => {
                    props.setDigitalTwin(parseInt(e.target.value));
                    props.setSelectedSensor(
                      props.data.digitalTwins[parseInt(e.target.value)]
                        .sensors[0]
                    );
                  }}
                  sx={{
                    width: "100%",
                    color: !props.isDarkTheme ? "#323238" : "white",
                    backgroundColor: props.isDarkTheme ? "#323238" : "white",
                  }}
                >
                  {props.data.digitalTwins && props.data.digitalTwins.map((dt: any, index: number) => (
                    <MenuItem
                      value={index}
                      sx={{
                        width: "100%",
                        color: !props.isDarkTheme ? "#323238" : "white",
                        backgroundColor: props.isDarkTheme
                          ? "#323238"
                          : "white",
                      }}
                    >
                      {dt.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: "3vh", height: "5.5vh" }}>
                {props.digitalTwin !== null && (
                  <div>
                    {props.data.digitalTwins[props.digitalTwin].sensors.length >
                    0 ? (
                      <>
                        <Autocomplete
                          id="sensorsBox"
                          options={props.data.digitalTwins[
                            props.digitalTwin
                          ].sensors.filter(
                            (v: any) => v.variable_type === "kpi"
                          )}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(event: any, newValue: any) => {
                            props.setSelectedSensor(newValue);
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sensor"
                              sx={{
                                width: "100%",
                                color: !props.isDarkTheme ? "#323238" : "white",
                                backgroundColor: props.isDarkTheme
                                  ? "#323238"
                                  : "white",
                              }}
                            />
                          )}
                          renderOption={(params, option) => (
                            <MenuItem
                              {...params}
                              sx={{
                                width: "100%",
                                color: !props.isDarkTheme ? "#323238" : "white",
                                backgroundColor: props.isDarkTheme
                                  ? "#323238"
                                  : "white",
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          )}
                        />
                      </>
                    ) : (
                      <p>nenhum sensor cadastrado</p>
                    )}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "15vh", height: "5.5vh" }}>
                {isReadyToDownload && (
                  <button
                    style={{
                      width: "40%",
                      height: "80%",
                      backgroundColor: "#124251",
                      color: "white",
                    }}
                    onClick={() => {
                      downloadCsvHelper(dataToDownload, true);
                    }}
                  >
                    Baixar CSV
                  </button>
                )}

                <button
                  className="submmitButton"
                  style={{
                    width: "40%",
                    height: "80%",
                    backgroundColor: "#124251",
                  }}
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                >
                  Aplicar
                </button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          >
            <CircularProgress
              sx={{
                color: "#124251",
              }}
              size={30}
            />
            <p
              style={{
                margin: "10px",
                fontSize: "20px",
              }}
            >
              Carregando...
            </p>
          </div>
        )}
      </Paper>
    </>
  );
};

export default Parameters;