const GASTECMonitoringPie = (
    name1: string,
    value1: number,
    name2: string,
    value2: number,
    total: number,
    theme: boolean
  ) => {
    const p = {};
    
  
    p[name1] = 100 * ((value1 / total) < 0 ? 0 : value1 / total);
    p[name2] = 100 * ((value2 / total) > 1 ? 1 : value2 / total);
  
    return {
      tooltip: {
        trigger: "item",
        formatter: function (params: any) {
          return `${params.marker} ${params.name} ${params.percent} %`;
        },
        
        
      },
      title:{
        text: "",
        subtext: "(Total do dia)",
        left: '5px',
        
        top: "35px",
        textStyle: {
          
          fontSize: 12,
          fontWeight: 300,
          fontFamily: "Roboto",

        } 
      },
      legend: {
        orient: 'horizontal',
        top: "bottom",
        left:'center',
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '12px',
        // color: theme ? '#FFFFFF' : '#000000',
        
      
        
        formatter: function (params: any, test: any) {
          return `${params} ${p[params].toFixed(2)} %`;
        },
      },
      series: [
        {
          type: "pie",
        
          radius: "60%",
          center: ["50%", "50%"],
          color:["#DEE9E4","#e0a0a0"],
          data: [
            { value: value1, name: name1 },
            { value: value2, name: name2 },
          ],
          itemStyle: {
            
            border: '1px solid black',
            
            // color: 'red',
            
          },
          
          emphasis: {
            itemStyle: {
              // shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              // color: theme ? '#FFFFFF' : '#000000',
            }
          },
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
          
        },
      ],
    };
  };
  
  export default GASTECMonitoringPie;