import { useState } from "react";
// import logo from "../../Assets/Images/logo_asa.png";
import DbService from "../../../Services/LocalDataService";
// import "./NewDigitalTwinForm.css";

// interface newDigitalTwinInterface {
//   name: string;
//   input: string;
//   output: string;
//   parent_id: number;
// }

const NewDigitalTwinForm = (props: any) => {
  const [newDigitalTwin, setNewDigitalTwin] = useState(props.dt);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    // console.log({ name, value });
    if (name === "parent_id" && value === "") {
      setNewDigitalTwin({ ...newDigitalTwin, [name]: -1 });
    } else {
      setNewDigitalTwin({ ...newDigitalTwin, [name]: value });
    }
  };

  // const x = (
  //   event:
  //     | React.ChangeEvent<HTMLInputElement>
  //     | React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const { name, value } = event.target;
  //   if ((name === "xSensor" || name === "zSensor") && value !=="time") {
  //     setNewGraph({ ...newGraph, [name]: parseInt(value) });
  //   } else {
  //     setNewGraph({ ...newGraph, [name]: value });
  //   }
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log("testing store");
    // console.log(newDigitalTwin);
    let parent_id_to_submit: number | null;
    newDigitalTwin.parent_id === -1
      ? (parent_id_to_submit = null)
      : (parent_id_to_submit = newDigitalTwin.parent_id);
    // console.log(parent_id_to_submit);
    const response = await DbService.edit_dt(
      newDigitalTwin.id,
      newDigitalTwin.name,
      newDigitalTwin.input_utility,
      newDigitalTwin.output_utility,
      parent_id_to_submit
    ); //.then(() => (navigate("/groups")));
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            value={newDigitalTwin.name}
            placeholder="Digite o nome do Gêmeo Digital"
          />
          <label htmlFor="name">Nome</label>
        </div>

        <div className="form-div-input">
          <input
            id="input_utility"
            name="input_utility"
            type="text"
            onChange={onChange}
            value={newDigitalTwin.input_utility}
            placeholder="Digite a Utilidade de entrada"
          />
          <label htmlFor="input_utility">Utilidade de entrada</label>
        </div>
        <div className="form-div-input">
          <input
            id="output_utility"
            name="output_utility"
            type="text"
            onChange={onChange}
            value={newDigitalTwin.output_utility}
            placeholder="Digite a Utilidade de saída"
          />
          <label htmlFor="output_utility">Utilidade de Saída</label>
        </div>

        <div className="form-div-select">
          <select
            id="parent_id"
            name="parent_id"
            onChange={onChange}
            // value={newDigitalTwin.parent_id}
            required
          >
            <option value="" disabled selected hidden>
              Selecione o...
            </option>
            <option value="">Nenhum</option>
            {props.dts &&
              props.dts.map((dt: any) => {
                return <option value={dt.id}>{dt.name}</option>;
              })}
          </select>
          <label htmlFor="zSensor">Pai</label>
        </div>

        {/* <ul>
          {assets.map((_, index) => {
            return (
              <li>
                <div className="label-float2">
                  <input
                    id={`asset[${index}]`}
                    name={`asset[${index}]`}
                    type={`asset[${index}]`}
                    onChange={onChange}
                  />
                  <label>Ativo {index + 1}</label>
                </div>
              </li>
            );
          })}
          <button onClick={addAsset}>Adcionar Atívo</button>
        </ul> */}
        <div className="buttons">
          <button
            className="delete"
            onClick={(event) => {
              event.preventDefault();
              props.onClickDelete();
            }}
          >
            deletar
          </button>
          <button className="submmit">Salvar Gemeo..</button>
        </div>
      </form>
    </div>
  );
};
export default NewDigitalTwinForm;
