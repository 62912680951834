
import "./UserCard.css";

import { Button } from "@mui/material";

const UserCard = (props: any) => {
 
  let style = {};
  if (props.user.is_active === false) {
    style = { backgroundColor: "#babab8", borderColor: "#80807e" };
  }
  return (
    <div className="userCard" style={style}>
      <div>
        <p>
          {props.user.name}
          {props.user.is_active === false && <span> (Inativo)</span>}
        </p>

        <p>{props.user.email}</p>
      </div>
      <Button onClick={props.onClick}>Editar</Button>
      
    </div>
  );
};
export default UserCard;
