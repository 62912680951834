import {
  orange,
  lime,
  green,
  teal,
  blue,
  indigo,
  purple,
  pink,
  red,
} from "@material-ui/core/colors";

export const getRandomColor = () => {
  const possibleColors = [
    orange,
    lime,
    green,
    teal,
    blue,
    indigo,
    purple,
    pink,
    red,
  ];
  const color =
    possibleColors[generateRandom(0, possibleColors.length - 1, false)];
  const hue = generateRandom(100, 900, true);
  return color[hue];
};

function generateRandom(min: number, max: number, hue: boolean) {
  const difference = max - min;
  let rand = Math.random();
  rand = Math.ceil(rand * difference);
  const res = rand + min;
  return hue === true ? Math.ceil(res / 100) * 100 : res;
}

export const createInitials = (name: string | null) => {
  if (name !== null) {
    let arrayOfNames = name.split(/(\s+)/);
    const stringOfInitials = arrayOfNames[0]
      .charAt(0)
      .concat(arrayOfNames[arrayOfNames.length - 1].charAt(0));
    return stringOfInitials;
  }
};
