import axios from "axios";

const AlertsAlarmsService = {
  getAlarmsHours: async (id: any, hours) => {
    const response = await axios.get(`/alerts/hours/${id}?hours=${hours}`);
    return response.data;
  },

  getAlertsHours: async (id: any, hours) => {
    const response = await axios.get(`/newAlerts/hours/${id}?hours=${hours}`);
    return response.data;
  },

  getAlertsRange: async (digitalTwindId: any, start: any, end: any) => {
    const axiosResponse = await axios.get(
      `/newAlerts?digital_twin_id=${digitalTwindId}&gt=${start.toISOString()}&lt=${end.toISOString()}`
    );

    // console.log(
    //   `/newAlerts?digital_twin_id=${digitalTwindId}&gt=${start.toISOString()}&lt=${end.toISOString()}`
    // )
    //Esse reverse() é pra retornar os dados do mais recente pro mais antigo. O padrão é o contrário.
    if(axiosResponse) {
      return axiosResponse.data.reverse();

    }
    else {
      return null
    }
  },

  getAlarmsRange: async (digitalTwindId: any, start: any, end: any) => {
    const axiosResponse = await axios.get(
      `/alerts?digital_twin_id=${digitalTwindId}&gt=${start.toISOString()}&lt=${end.toISOString()}`
    );
    //Esse reverse() é pra retornar os dados do mais recente pro mais antigo. O padrão é o contrário.
    return axiosResponse.data.reverse();
  },

  ackAlert: async (userId: string, alertId: string) => {
    const body = {
      // user = recebe um numero inteiro
      userId: userId,
      acknowledged: true,
      acknowledged_time: new Date().toISOString(),
    };

    await axios.post(`/newAlerts/ack/${alertId}`, body);
    return true;
  },

  ackAlarm: async (userId: string, alarmId: string) => {
    const body = {
      // user = recebe um numero inteiro
      userId: userId,
      acknowledged: true,
      acknowledged_time: new Date().toISOString(),
    };

    await axios.post(`/alerts/ack/${alarmId}`, body);
    return true;
  },

  justifyRecommendation: async (userId: string, alarmId: string, justification: string) => {
    const body = {
      // user = recebe um numero inteiro
      justification: justification,
      userId: userId,
      acknowledged: true,
      acknowledged_time: new Date().toISOString(),
    };

    await axios.post(`/alerts/justify/${alarmId}`, body);
    return true;
  },
};

export default AlertsAlarmsService;
