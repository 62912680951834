import { useState } from "react";
// import logo from "../../Assets/Images/logo_asa.png";
import DbService from "../../../Services/LocalDataService";
import "./NewCompanyForm.css";
import { Button, CircularProgress } from "@mui/material";

interface NewCompanyInterface {
  name: string;
  CNPJ: string;
  zipcode: string;
  address: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
}

const initialState: NewCompanyInterface = {
  name: "",
  CNPJ: "",
  zipcode: "",
  address: "",
  number: "",
  neighborhood: "",
  city: "",
  state: "",
  country: "",
};

const NewCompanyForm = (props: any) => {
  const [newCompany, setNewCompany] =
    useState<NewCompanyInterface>(initialState);

  const [loadingRequest, setLoadingRequest] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewCompany({ ...newCompany, [name]: value });
    // console.log(newCompany);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    // console.log("testing store");
    await DbService.store_country(
      newCompany.country,
      "America",
      Date.now()
    ).then((resp: any) => {
      DbService.store_state(newCompany.state, Date.now(), resp.data.id).then(
        (resp: any) => {
          DbService.store_city(newCompany.city, Date.now(), resp.data.id).then(
            (resp: any) => {
              DbService.store_company(
                newCompany.name,
                props.groupId,
                resp.data.id,
                newCompany.CNPJ,
                newCompany.neighborhood,
                newCompany.number,
                "",
                newCompany.neighborhood,
                newCompany.zipcode,
                Date.now()
              ).then((response) => {
                setLoadingRequest(false);
                if (response.error) {
                  alert(response.error);
                } else {
                  props.handleResponse();
                }
              });
            }
          );
        }
      );
    });
    //// console.log(country.data);
  };

  return (
    <div className="mainU">
      <form onSubmit={handleSubmit}>
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            placeholder="Digite o nome da empresa"
          />
          <label htmlFor="name">Empresa</label>
        </div>
        <div className="form-div-input">
          <input
            id="CNPJ"
            name="CNPJ"
            type="text"
            onChange={onChange}
            placeholder="Digite o CNPJ da empresa"
          />
          <label htmlFor="CNPJ">CNPJ</label>
        </div>
        <div className="form-div-input">
          <input
            id="zipcode"
            name="zipcode"
            type="text"
            onChange={onChange}
            placeholder="Digite o CEP da empresa"
          />
          <label htmlFor="zipcode">CEP</label>
        </div>
        <div className="form-div-input">
          <input
            id="address"
            name="address"
            type="text"
            onChange={onChange}
            placeholder="Digite o endereço da empresa"
          />
          <label htmlFor="address">Logradouro</label>
        </div>
        <div className="form-div-input">
          <input
            id="number"
            name="number"
            type="text"
            onChange={onChange}
            placeholder="Digite o número do endereço"
          />
          <label htmlFor="number">Número</label>
        </div>
        <div className="form-div-input">
          <input
            id="neighborhood"
            name="neighborhood"
            type="text"
            onChange={onChange}
            placeholder="Digite o bairro.."
          />
          <label htmlFor="neighborhood">Bairro</label>
        </div>
        <div className="form-div-input">
          <input
            id="city"
            name="city"
            type="text"
            onChange={onChange}
            placeholder="Digite a Cidade.."
          />
          <label htmlFor="city">Cidade</label>
        </div>
        <div className="form-div-input">
          <input
            id="state"
            name="state"
            type="text"
            onChange={onChange}
            placeholder="Digite o Estado.."
          />
          <label htmlFor="state">Estado</label>
        </div>
        <div className="form-div-input">
          <input
            id="country"
            name="country"
            type="text"
            onChange={onChange}
            placeholder="Digite o País.."
          />
          <label htmlFor="country">País</label>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={props.cancel}
            style={{
              backgroundColor: "#FFF",
              color: "#124251",
              marginRight: "10px",
            }}
            variant="contained"
          >
            Cancelar
          </Button>
          {loadingRequest ? (
            <Button
              style={{
                backgroundColor: "#124251",
              }}
              variant="contained"
            >
              <CircularProgress style={{ color: "white" }} size={20} />
            </Button>
          ) : (
            <Button
              type="submit"
              style={{
                backgroundColor: "#124251",
              }}
              variant="contained"
            >
              Salvar Empresa
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
export default NewCompanyForm;
