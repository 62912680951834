import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";
import "./CompanyCard.css";

const CompanyCard = (props: any) => {
  return (
    <div
      className={`CompanyCard ${props.selected ? "selected" : ""}`}
      onClick={props.onClick}
    >
      <div>
        <p>{props.company.name}</p>
        <p>{props.company.cnpj}</p>
      </div>
      <div className="icon">
        <Icon
          onClick={(event) => {
            event.stopPropagation();
            props.onClickIcon();
          }}
        />
      </div>
    </div>
  );
};
export default CompanyCard;
