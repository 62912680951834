const DataFormatService = {
  floatInput: (input: any, n: number): string | void => {
    let dataToreturn = input;
    if (typeof dataToreturn === "string") {
      if (isNaN(parseFloat(dataToreturn))) {
        return dataToreturn as string;
      }
      dataToreturn = parseFloat(dataToreturn).toFixed(n);
      return dataToreturn as string;
    }
    if (typeof dataToreturn === "boolean") {
      if (dataToreturn) {
        return "Ligado" as string;
      } else {
        return "Desligado" as string;
      }
    } else if (typeof dataToreturn === "number") {
      dataToreturn = dataToreturn.toFixed(n);
      return dataToreturn as string;
    }
  },
};

export default DataFormatService;
