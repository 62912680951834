
interface sensors {
  name: number;
  number: number;
  deviceId: string;
  data: Array<any>;
  unit: string;
  max: number;
  min: number;
}

interface configurableGraphOneYAxisProps {
  yAxis1: Array<sensors>;
  gemeo: any;
  markArea?: Array<any>;
  offArea?: Array<any>;
  tooltip?: Array<any>;
  theme?: Boolean;
}

const configurableGraphOneYAxis = ({
  yAxis1,
  gemeo,
  markArea,
  offArea,
  tooltip,
  theme
}: configurableGraphOneYAxisProps) => {

  

  const COLORS_DARK = [ "#F0E442","#7852E0", "#D55E00", "#009E73", "#fff"];
  
  
  const COLORS =  [
    "rgb(18, 66, 81)",
    "#98CBFF",
    "#548BBB",
    "#009E73",
    "#000000",
    "#F0E442",
    "#0072B2",
    "#D55E00",
    "#CC79A7",
  ] 

  let markAreaX: any = [];
  if (offArea) {
    for (const iterator of offArea) {
      markAreaX = [...markAreaX, ...iterator];
    }
  }

  const findClosestDataPoint = (serie, timestamp) => {
    if (serie.length === 0) {
      return "Sem dados";
    }
    const d1 = new Date(timestamp).getTime();
    const aux = serie.findIndex((e) => new Date(e[0]).getTime() > d1);

    if (aux === undefined || aux === 0 || aux === -1) {
      return "Sem dados";
    } else {
      return serie[aux - 1][1];
    }
  };

  let tooltipData: any = {
    name: yAxis1[0] !== undefined && yAxis1[0].name,
    data: [],
  };
  let tooltipFormat: any = {
    trigger: "axis",
    axisPointer: {
      label: {
        backgroundColor: "#6a7985",
      },
    },
  };

  if (tooltip && tooltip.length > 0) {
    const test = yAxis1[0].data;

    const test2 = test.map((element) => {
      const x: any = [];
      for (const tool of tooltip) {
        const aux = findClosestDataPoint(tool.data, element[0]);
        const name = tool.name;
        x.push({
          name: name,
          value: aux,
        });
      }
      return {
        timestamp: element[0],
        value: element[1],
        associated: x,
      };
    });

    tooltipData.data = test2;
    tooltipFormat = {
      trigger: "axis",
      axisPointer: {
        label: {
          backgroundColor: "#6a7985",
        },
      },
      formatter: (x) => {
        if (tooltipData.data.length > 0) {
          let aux = "";
          tooltipData.data[x[0].dataIndex]?.associated.forEach((e) => {
            aux = aux.concat(
              `<div style="display:flex;justify-content: space-between;"><p style="display: inline-block;margin: 0;padding-right: 10px;">${e.name.concat(
                ":"
              )}</p><b>${e.value}</b></div>`
            );
          });

          return `${new Date(
            tooltipData.data[x[0].dataIndex]?.timestamp
          ).toLocaleString(
            "pt-BR"
          )}<br><div style="display:flex;justify-content: space-between;"><p style="display: inline-block;margin: 0;padding-right: 10px;">${
            tooltipData.name
          }:</p>   <b>${tooltipData.data[x[0].dataIndex]?.value}</b></div>
          ${aux}`;
        }
        return `teste`;
      },
    };
  }

  const getLegend = (legends, markedArea, markedAreaX, gemeo) => {
    if (markedArea || markedAreaX) {
      let data: any = legends;
      if (markedAreaX?.length > 0) {
        data.push({ name: `${gemeo} Desligado`, icon: "rect" });
      }
      if (markedArea?.length > 0) {
        data.push({ name: `${gemeo} Alarmado`, icon: "rect" });
      }
      return {
        data: data,
        orient: "horizontal",
        bottom: 10,
        selectedMode: true,
        itemWidth: 10,
        itemHeight: 10,
      };
    }
    return {
      data: legends,
      orient: "horizontal",
      bottom: 10,
      selectedMode: true,
      itemWidth: 10,
      itemHeight: 10,
    };
  };

  const getSeries = (series, markedArea, markedAreaX, gemeo) => {
    let returnedSeries = series;
    if (markedAreaX?.length > 0) {
      returnedSeries.push({
        name: `${gemeo} Desligado`,
        type: "line",
        color: "#cccccc",
        
        
      });
    }
    if (markedArea?.length > 0) {
      returnedSeries.push({
        name: `${gemeo} Alarmado`,
        type: "line",
        color: "#e0a0a0",
      });
    }
    return returnedSeries;
  };

  const legends: Array<string> = [];

  const series = yAxis1
    .map((element, index) => {
      const name = element.name.toString();
      legends.push(name);
      
      return {
       
        data: element.data,
        yAxisIndex: 0,
        name: name,
        type: "line",
        showSymbol: false,
        symbolSize: 5,
        symbol: "circle",
        // color: theme ? COLORS_DARK[index] : COLORS[index],
        smooth: false,
        markArea: {
          data: markArea ? markArea.concat(markAreaX) : markAreaX,
          silent: false,
          animation: true,
          animationDurationUpdate: 1000,

        },
        animation: true,
        animationDuration: 5000,
        animationEasing:" linear",
        animationDelay: 500,
        animationDurationUpdate: 1000,
          
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return {
    xAxis: {
      type: "time",
      max: new Date(),
    },
    yAxis: [
      {
        name: yAxis1[0]?.unit,
        type: "value",
        alignTicks: true,
        scale: true,
        axisTick: {
          show: true,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#979797",
          },
        },
        axisLabel: {
          formatter: "{value}",

          
        },
        max: yAxis1[0]?.max,
        min: yAxis1[0]?.min,
      },
    ],
    dataZoom: [
      {
        type: "inside",
        filterMode: "none",
        minValueSpan: 60 * 1000 * 8,
      },
    ],
    tooltip: tooltipFormat,
    legend: getLegend(legends, markArea, offArea, gemeo),
    grid: {
      left: 20,
      right: 20,
      top: 80,
      bottom: 30,
      containLabel: true,
      
    },
    series: getSeries(series, markArea, offArea, gemeo),
    

  };
};

export default configurableGraphOneYAxis;
