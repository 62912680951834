import { useState } from "react";
import DbService from "../../../Services/LocalDataService";

const NewGroupForm = (props: any) => {
  const [name, setName] = useState<string>("");
  // const [array, setArray] = useState<any>(null);
  // const [img, setImg] = useState<any>(null);

  // const [newGroup, setNewGroup] = useState<any>({});

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    setName(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // // console.log(array);
    // const blob = new Blob([array]);
    // // console.log(blob);

    // const response = await DbService.store_group(name, blob);

    const response = await DbService.store_group(name);
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  //   let file: any;
  //   const changeFile = (event: any) => {
  //     event.preventDefault();

  //     file = event.target.files[0];
  //     // console.log(file.name);
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);
  //     fileReader.onload = () => {
  //       // console.log(fileReader.result);
  //       setArray(fileReader.result);
  //     };
  //   };

  //   const renderImage = () => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(array);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;

  //     };
  //   };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            value={name}
            placeholder="Digite o nome do Grupo"
          />
          <label htmlFor="name">Nome</label>
        </div>

        {/* <input
          type="file"
          name="file"
          onChange={changeFile}
          accept=".png,.jpeg"
        /> */}

        <button className="submmitButton">Salvar Grupo empresarial</button>
      </form>
      {/* {array && (
        <div>
          <img id="ItemPreview" src={img} /> 
          <p>image loaded</p>
        </div>
      )} */}
    </div>
  );
};
export default NewGroupForm;
