import { useEffect, useState } from "react";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";
import { ReactComponent as Icon2 } from "../../Assets/Icons/edit.svg";
import "./GroupView.css";

import DbService from "../../Services/LocalDataService";

import { useParams } from "react-router-dom";
import CompanyCard from "../../Components/Cards/CompanyCard/CompanyCard";
import UserCard from "../../Components/Cards/UserCard/UserCard";
import DigitalTwinCard from "../../Components/Cards/DigitalTwinCard/DigitalTwinCard";
import NewDigitalTwinForm from "../../Components/Forms/NewDigitalTwinForm/NewDigitalTwinForm";
import NewUserForm from "../../Components/Forms/NewUserForm/NewUserForm";
import NewCompanyForm from "../../Components/Forms/NewCompanyForm/NewCompanyForm";
// import logo from "../../Assets/Images/logo_asa.png";

import UserDetailsCard from "../../Components/Cards/UserDetailsCard/UserDetailsCard";
import DigitalTwinDetailsCard from "../../Components/Cards/DigitalTwinDetailsCard/DigitalTwinDetailsCard";
import CompanyDetailsCard from "../../Components/Cards/CompanyDetailsCard/CompanyDetailsCard";
import {
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import AuthService from "../../Services/AuthService";

interface IGroup {
  id: number;
  name: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  companies: Array<ICompany>;
  digitalTwins: Array<any>;
}

interface ICompany {
  id: number;
  name: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  users: Array<IUsers>;
  digitalTwins: Array<IDigitalTwin>;
}

interface IUsers {
  id: number;
  name: string;
  email: string;
  password: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  email_confirmation_code: string;

}

interface IDigitalTwin {
  id: number;
  name: string;
  assets: any;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  parent_id: number;
}

const GroupView = (props: any) => {
  const { id } = useParams();

  const [addingCompany, setAddingCompany] = useState<boolean>(false);
  const [addingDigitalTwin, setAddingDigitalTwin] = useState<boolean>(false);
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [users, setUsers] = useState<IUsers[]>([]);
  const [usersList, setUsersList] = useState<IUsers[]>([]);
  const [digitalTwins, setDigitalTwins] = useState<IDigitalTwin[]>([]);
  const [digitalTwinsList, setDigitalTwinsList] = useState<IDigitalTwin[]>([]);
  // const [parentDts, setParentsDts] = useState<IDigitalTwin[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const [selected, setSelected] = useState<number | null>(null);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedDt, setSelectedDt] = useState<number | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);

  useEffect(() => {
   
    if (id) {
      DbService.get_group(id).then((data) => {
        setGroup(data);
        setCompanies(data.companies);
      });
    }
  }, [id]);

  const togle = (company_id: number) => {
    setSelected(company_id);
    if (group !== null) {
    }

    DbService.get_users_from_company(company_id.toString()).then((data) => {
      setUsers(data);
      setUsersList(data);
    });
    DbService.get_digitalTwins(company_id.toString()).then((data) => {
      
      setDigitalTwins(data);
      const parents = data.filter((dt) => dt.parent_id === null);
     
      setDigitalTwinsList(parents);
    });
    
  };

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "companySearch") {
      if (group) {
        const newComp = group.companies.filter((companie) => {
          if (companie.name.toLowerCase().includes(value.toLocaleLowerCase())) {
            return companie;
          } else {
            return undefined;
          }
        });
        setCompanies(newComp);
      }
    } else if (name === "userSearch") {
      const newUsers = users.filter((user) => {
        if (user.name.toLowerCase().includes(value.toLocaleLowerCase())) {
          return user;
        } else {
          return undefined;
        }
      });
      setUsersList(newUsers);
    } else if (name === "digitalTwinSearch") {
      const newDts = digitalTwins.filter((dt) => {
        if (dt.name.toLowerCase().includes(value.toLocaleLowerCase())) {
          return dt;
        } else {
          return undefined;
        }
      });
      setDigitalTwinsList(newDts);
    }
  };


  const handleNewCompany = () => {
    if (id) {
      DbService.get_group(id).then((data) => {
        setGroup(data);
        setCompanies(data.companies);
      });
    }
    setAddingCompany(false);
    setSelectedCompany(null);
  };

  const handleNewUser = async () => {
    if (id && selected) {
      await DbService.get_users_from_company(selected.toString()).then(
        (data) => {
          setUsers(data);
          setUsersList(data);
        }
      );
    }
    setAddingUser(false);
    setSelectedUser(null);
  };

  const handleNewDt = () => {
    if (id && selected) {
      DbService.get_digitalTwins(selected.toString()).then((data) => {
        setDigitalTwins(data);
        const parents = data.filter((dt) => dt.parent_id === null);
        // setParentsDts(parents);
        setDigitalTwinsList(parents);
      });
    }
    setAddingDigitalTwin(false);
    setSelectedDt(null);
  };
  // setSelectedUser(1);

  const resetSelecteds = () => {
    setAddingDigitalTwin(false);
    setAddingUser(false);
    setSelectedUser(null);
    setSelectedDt(null);
    setSelectedCompany(null);
  };

  const activateUser = ( ) => {
    
    if (selectedUser) {
      const user = users.filter((user) => user.id === selectedUser)[0];
     
      AuthService.activationCode(user?.email_confirmation_code)
      setSelectedUser(null);
      
    }
  }

  return (
    <  >
      <Paper
      
      >
        <Typography className="sectionTittle">Organização</Typography>
        <div className="groupDiv">
          {group && (
            <img
              src={process.env.PUBLIC_URL + "/logos/" + group.name + ".png"}
              alt="Logo"
            />
          )}

          <Typography variant="h5" sx={{ margin: "10px" }}>
            {group?.name}
          </Typography>

          <Icon2 className="editIcon" />
        </div>

        <div>
          <Typography className="sectionTittle">Empresas</Typography>

          {addingCompany ? (
            <div>
              <NewCompanyForm
                groupId={group?.id}
                handleResponse={handleNewCompany}
                cancel={() => {
                  setAddingCompany(false);
                }}
              />
            </div>
          ) : selectedCompany ? (
            <CompanyDetailsCard
              handleResponse={handleNewCompany}
              company={companies.find(
                (company) => company.id === selectedCompany
              )}
            />
          ) : (
            <div>
              <OutlinedInput
                sx={{ width: "97%", margin: "10px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <Icon />
                  </InputAdornment>
                }
                type="text"
                name="companySearch"
                onChange={searchInputHandler}
              />

              {companies === null ? (
                <span>Not found</span>
              ) : (
                <div
                  className="testeScroll"
                  style={{ height: "calc(100vh - 450px)" }}
                >
                  {companies.map((company) => {
                    return (
                      <CompanyCard
                        selected={company.id === selected ? true : false}
                        
                        key={company.id}
                        company={company}
                        onClick={() => {
                          handleNewCompany();
                          resetSelecteds();
                          togle(company.id);
                        }}
                        onClickIcon={() => {
                          setSelected(null);
                          setSelectedCompany(company.id);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {!addingCompany && (
            // <button
            //   className="bottomButton"
            //   onClick={() => {
            //     setAddingCompany(!addingCompany);
            //     setSelected(null);
            //   }}
            // >
            //   Adicionar Empresa
            // </button>
            <Button
              onClick={() => {
                setSelected(null);
                setAddingCompany(!addingCompany);
              }}
              style={{
                backgroundColor: "#124251",
                color: "#FFF",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Adicionar Empresa
            </Button>
          )}
        </div>
      </Paper>

      <Paper
       
      >
        <div>
          <Typography className="sectionTittle">Usuarios</Typography>
          {!selected ? (
            <span></span>
          ) : addingUser ? (
            <div>
              <NewUserForm
                companyId={selected}
                handleResponse={handleNewUser}
                cancel={() => {
                  setAddingUser(false);
                }}
              />
            </div>
          ) : selectedUser ? (
            <UserDetailsCard
              handleResponse={handleNewUser}
              user={usersList.find((user) => user.id === selectedUser)}
              cancel={() => {
                setAddingUser(false);
              }}
              activateUser={() => {
                activateUser();
              }}
            />
          ) : (
            <div className="list">
              <OutlinedInput
                sx={{ width: "97%", margin: "10px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <Icon />
                  </InputAdornment>
                }
                type="text"
                name="userSearch"
                onChange={searchInputHandler}
              />

              {usersList.map((user) => {
                return (
                  <UserCard
                    user={user}
                    onClick={() => {
                      setSelectedUser(user.id);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {selected && !addingUser && (
            // <button
            //   className="bottomButton"
            //   onClick={() => {
            //     setAddingUser(!addingUser);
            //   }}
            // >
            //   Adicionar usuario
            // </button>
            <Button
              onClick={() => {
                setAddingUser(!addingUser);
              }}
              style={{
                backgroundColor: "#124251",
                color: "#FFF",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Adicionar usuario
            </Button>
          )}
        </div>
      </Paper>

      <Paper
       
      >
        <div>
          <div>
            <p className="sectionTittle">Gêmeos Digitais</p>
          </div>
          <div className="">
            {!selected ? (
              <span></span>
            ) : addingDigitalTwin ? (
              <div>
                <NewDigitalTwinForm
                  companyId={selected}
                  dts={digitalTwins}
                  handleResponse={handleNewDt}
                  cancel={() => {
                    setAddingDigitalTwin(false);
                  }}
                />
              </div>
            ) : selectedDt ? (
              <DigitalTwinDetailsCard
                handleResponse={handleNewDt}
                dts={digitalTwinsList.filter((dt) => dt.id !== selectedDt)}
                dt={digitalTwins.find((dt) => dt.id === selectedDt)}
              />
            ) : (
              <div className="list">
                <div className="searchDiv">
                  <input
                    type="text"
                    name="digitalTwinSearch"
                    id=""
                    onChange={searchInputHandler}
                  />
                  <Icon />
                </div>
                <div>
                  {digitalTwinsList.map((dt) => {
                    return (
                      <DigitalTwinCard
                        dt={dt}
                        onClick={(id) => {
                          setSelectedDt(id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {/* {!addingDigitalTwin ? (
                selected === 0 ? (
                  <p>waiting to select</p>
                ) : (
                  <div className="list">
                    <div className="searchDiv">
                      <input
                        type="text"
                        name="digitalTwinSearch"
                        id=""
                        onChange={searchInputHandler}
                      />
                      <Icon />
                    </div>
                    <div>
                      {digitalTwinsList.map((dt) => {
                        return (
                          <DigitalTwinCard
                            dt={dt}
                            onClick={() => {
                              setSelectedDt(dt.id);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <NewDigitalTwinForm
                    companyId={selected}
                    dts={digitalTwins}
                    handleResponse={handleNewDt}
                  />
                </div>
              )}*/}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {selected && !addingDigitalTwin && (
            // <button
            //   className="bottomButton"
            //   onClick={() => {
            //     setAddingDigitalTwin(!addingDigitalTwin);
            //   }}
            // >
            //   Adicionar gemeo..
            // </button>
            <Button
              onClick={() => {
                setAddingDigitalTwin(!addingDigitalTwin);
              }}
              style={{
                backgroundColor: "#124251",
                color: "#FFF",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Adicionar Gêmeo digital
            </Button>
          )}
        </div>
      </Paper>
    </>
  );
};
export default GroupView;
