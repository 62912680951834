// tirar isso do hardcode ****************************************************************************

const prescriptionsByTwinId = {
  "1": ["eficiencia_GASTEC"],
  "3": [
    // "Qtd-Energia-Portico1-(variável-de-processo)",
    // "Qtd-Energia-Portico2-(variável-de-processo)",
    // "Qtd-Energia-Portico3-(variável-de-processo)",
    // "Qtd-Energia-Portico4-(variável-de-processo)",
    // "Qtd-Energia-Portico5-(variável-de-processo)",
    // "Qtd-Energia-Portico6-(variável-de-processo)",
    // "Qtd-Energia-Portico7-(variável-de-processo)",
    "cegn"
  ],
  "5": [
    // "KPI-CONSUMO-POR-EFICIENCIA",
    // "VL_TEMPERAT_3",
    // "VL_TEMPERAT_4",
    // "VL_TEMPERAT_6",
    // "VL_TEMPERAT_7",
    // "VL_TEMPERAT_9",
    // "VL_TEMPERAT_10",
    // "VL_TEMPERAT_11",
    // "VL_TEMPERAT_12",
    // "VL_TEMPERAT_13",
    // "VL_TEMPERAT_14",
    // "VL_TEMPERAT_15",
    // "VL_TEMPERAT_16",
    // "VL_TEMPERAT_17",
    // "VL_TEMPERAT_19",
    // "VL_TEMPERAT_23",
    // "VL_TEMPERAT_24",
    // "VL_TEMPERAT_25",
    // 'KPI-CONSUMO-POR-EFICIENCIA', 
'RG04_1_VL_POS_VALV',
'RG06_1_VL_POS_VALV',
'RG07_1_VL_POS_VALV',
'RG09_1_VL_POS_VALV',
'RG10_1_VL_POS_VALV',
'RG11_1_VL_POS_VALV',
'RG12_1_VL_POS_VALV',
'RG14_1_VL_POS_VALV' ,
'RG15_1_VL_POS_VALV',
'RG16_1_VL_POS_VALV' ,
'RG17_1_VL_POS_VALV',
'RG19_1_VL_POS_VALV' ,
'RG23_1_VL_POS_VALV',
// 'Mock-ROCA-CEGN',
  ]
};

export default prescriptionsByTwinId;

