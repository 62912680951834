import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { Box, Paper } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { hideLoading } from "../../Utils/hideLoading";
import moment from "moment";
import GASTECMonitoringPie from "../../GraphOptions/GASTECMonitoringPie";
import axios from "axios";
import { useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";

interface CardPizzaProps {
  elemento: any;
  w: number | null;
  h: number | null;

}

const CardPizza = (props: CardPizzaProps) => {
  
  const theme = useTheme();

  const eChartsRef = useRef(null as any);
  let sensorsData: any = useMemo<any>(() => [], []);

  const fetchData = useCallback(async () => {
    sensorsData.current.forEach(async (element) => {
      const data = await axios.get(`/testpie?offset=0&sensorId=${element.id}`);

      const times = data.data.map((element) => {
        let start = element.first_alert_time;
        let end = element.last_alert_time;
        if (
          moment(element.first_alert_time).isBefore(
            moment().startOf("day").subtract(6, "hours")
          )
        ) {
          start = moment().startOf("day").toJSON();
        }
        if (element.finished === false) {
          end = moment().toJSON();
        }
        return [{ yAxis: start }, { yAxis: end }];
      });

      let timeInAlert = 0;

      times.forEach((element) => {
        if (element !== undefined) {
          const t1 = moment(element[0].yAxis);
          const t2 = moment(element[1].yAxis);
          const time = t2.diff(t1);
          timeInAlert = timeInAlert + time;
        }
      });

      const t1 = moment().startOf("day");
      const t2 = moment();
      const totalTime = t2.diff(t1);

      const timeOk = totalTime - timeInAlert;

      hideLoading([eChartsRef]);

      eChartsRef.current
        ?.getEchartsInstance()
        .setOption(
          GASTECMonitoringPie(
            "Sem Alarme",
            timeOk,
            "Em alarme",
            timeInAlert,
            totalTime,
            theme.palette.mode === "dark" ? true : false
          )
        );
    });
  }, [theme.palette.mode, sensorsData]);

  useEffect(() => {
    const data = props.elemento.pie_sensors.map((elemment) => {
      return {
        name: elemment.name,
        id: elemment.id,
        number: elemment.number,
        deviceId: elemment.device_id,
        historicData: [],
        newData: [],
        unit: elemment.unit.abbreviation,
      };
    });
    sensorsData.current = sensorsData.concat(data);
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 180000);

    return () => clearInterval(interval);
  }, [fetchData, props.elemento.pie_sensors, sensorsData]);

  const [started, setStarted] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      
        setStarted(true);
      
    }, Math.random() * 500);
  }, []);
  return (
    <Paper
    elevation={0}
    sx={{
      width: props.w?.toString(),
      height: '100%',
      display: "flex",
      flexDirection: "column",
      
      padding: "10px",
    }}
    >
      <Box
        className="draggableHandle"
        title={props.elemento.name}
        sx={{
          cursor: "grab",
          zIndex: 2,
          flexGrow: 1 ,  
         textOverflow: "ellipsis", 
         overflow: "hidden", 
         whiteSpace: "nowrap",
        }}
        
      >
        {props.elemento.name}
      </Box>

     
        
          <ReactEcharts
            ref={eChartsRef}
            style={{
              height:'100%',
              width: props.w?.toString(),
              marginTop:'-30px'
            }}
            
            option={{
              backgroundColor: "transparent",
              legend: {
                textStyle: {
                  color: theme.palette.mode === "dark" ? '#FFFFFF' : '#000000',
                },
              },
              title:{
                
                color: theme.palette.mode === "dark" ? '#FFFFFF' : '#000000',
                
              },
            
            }}
          />
         
       
      
    </Paper>
  );
};

export default CardPizza;
