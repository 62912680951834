import { Box, CircularProgress, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Power } from "../../../Assets/Icons/power.svg";
import DataService from "../../../Services/DataService";
import NewDi2winDataService from "../../../Services/NewDi2winDataService";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as Ok } from "../../../Assets/Icons/Ok.svg";
import { ReactComponent as Alarm } from "../../../Assets/Icons/Alarm.svg";
import { ReactComponent as Alert } from "../../../Assets/Icons/Alert.svg";

import "./TwinCard.css";

import { setDigitalTwin } from "../../../store/slices/digitalTwinSlice";

import paths from "../../../router/paths";
import ReferenceValue from "../../Barometer/ReferenceValue";
import { setCharts } from "../../../store/slices/monitoringSlice";


interface TwinCardProps {
  digitalTwin: any;
}

const TwinCard = (props: TwinCardProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [redBoard, setRedBoard] = useState(
    theme.palette.mode === "dark" ? "0px solid #C04040" : "1px solid #D9D9D9"
  );
  const [data, setData] = useState<any>();
  const [value, setValue] = useState<any>(0);
  const [range, setRange] = useState<any>(
    props.digitalTwin.efficiency_interval
  );

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  const [sensorTwinStatus, setSensorTwinStatus] = useState<any>();
  const [isDigitalTwinOn, setIsDigitalTwinOn] = useState(false);
  const [twinStatus, setTwinStatus] = useState("");

  useEffect(() => {
    DataService.getGraphsByTwin(props.digitalTwin.id).then((res: any) => {
      const twinStatus = res.data.find(
        (element) => element.speedometer_main !== null
      )?.x_axis_sensor_id_bar;
      if (twinStatus !== null || twinStatus !== undefined) {
        setSensorTwinStatus(twinStatus);
      }
      setData(res.data.find((element) => element.speedometer_main !== null));
    });
  }, [props.digitalTwin.id]);

  useEffect(() => {
    const fetchData = () => {
      DataService.getDigitalTwinEfficiencyInterval(props.digitalTwin.id).then(
        (res) => {
          setRange(res.data);
        }
      );
    };
    fetchData();
    // Set a timeout to delay the first fetch by 5 minutes
    const interval = setInterval(() => fetchData(), 180000);

    return () => clearInterval(interval);
  }, [props.digitalTwin.id]);

  useEffect(() => {
    DataService.getTwinNameById(props.digitalTwin.id).then((res: any) => {
      const twin = res.data;
      twin && twin.status === "unknown"
        ? setTwinStatus("")
        : setTwinStatus(twin.status);
    });

    const interval = setInterval(() => {
      DataService.getTwinNameById(props.digitalTwin.id).then((res: any) => {
        const twin = res.data;
        twin && twin.status === "unknown"
          ? setTwinStatus("")
          : setTwinStatus(twin.status);
      });
    }, 180000);

    return () => clearInterval(interval);
  }, [props.digitalTwin.id]);

  useEffect(() => {
    twinStatus === "normal"
      ? setRedBoard("4px solid #83AB51")
      : twinStatus === "alert"
      ? setRedBoard("4px solid #FFCB05")
      : twinStatus === "alarm"
      ? setRedBoard("4px solid #C04040")
      : twinStatus === "" &&
        setRedBoard(theme.palette.mode === "dark" ? "0px solid #C04040" : "1px solid #D9D9D9");
  }, [twinStatus, theme.palette.mode]);

  useEffect(() => {
    DataService.getsensor(sensorTwinStatus).then((res) => {
      DataService.getTwins().then((res1) => {
        const response = res1
          .filter((twin) => twin.id === props.digitalTwin.id)
          .at(-1);

        response &&
          NewDi2winDataService.getLastNInfo(
            res.number,
            res.device_id,
            1,
            "hour",
            "1"
          ).then((res) => {
            setIsDigitalTwinOn(res.at(-1).value);
          });
      });
    });

    data &&
      NewDi2winDataService.getLastNInfo(
        data.speedometer_main.number,
        data.speedometer_main.device_id,
        1,
        "hour",
        "1"
      ).then((res: any) => {
        setValue(res.at(-1).value.toFixed(2));
      });

    const interval = setInterval(() => {
      DataService.getsensor(sensorTwinStatus).then((res) => {
        DataService.getTwins().then((res1) => {
          const response = res1
            .filter((twin) => twin.id === props.digitalTwin.id)
            .at(-1);

          response &&
            NewDi2winDataService.getLastNInfo(
              res.number,
              res.device_id,
              1,
              "hour",
              "1"
            ).then((res) => {
              setIsDigitalTwinOn(res.at(-1).value);
            });
        });
      });

      data &&
        NewDi2winDataService.getLastNInfo(
          data.speedometer_main.number,
          data.speedometer_main.device_id,
          1,
          "hour",
          "1"
        ).then((res: any) => {
          setValue(res.at(-1).value.toFixed(2));
        });
    }, 180000);

    return () => {
      clearInterval(interval);
    };
  }, [data, sensorTwinStatus, props.digitalTwin.id]);

  const getTwinStatus = () => {
    const baseStyle = {
      color: "white",
      height: "36px",
      width: "90x",
      display: "flex",
      gap: "10px",
      padding: "10px",
      borderRadius: "4px 4px 0 4px ",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return isDigitalTwinOn ? (
      <span
        style={{
          ...baseStyle,
          backgroundColor: "#83AB51",
        }}
      >
        <Power style={{ marginBottom: "5px" }} />
        &nbsp;<span> ON</span>
      </span>
    ) : (
      <span
        style={{
          ...baseStyle,
          backgroundColor: "#C04040",
        }}
      >
        <Power style={{ marginBottom: "5px" }} />
        &nbsp;<span> OFF</span>
      </span>
    );
  };

  const option = {
    backgroundColor: "transparent",
    

    grid: {
     
      top: 0,
      left: 50,
      right: 50,
      bottom: 0,
      containLabel: true,
    },
    
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)) *
            1.5,
        splitNumber: {
          show: false,
        },
        animationDuration: 5000,
        axisLine: {
          lineStyle: {
            width: -6,

            color: [
              [2 / 3, "#118751"],
              [1, "#c04040"],
            ],
          },
        },
        pointer: {
          show: false,
          length: "10%",
          width: value === 0 ? 0 : 5,
          itemStyle: {},
        },
        axisTick: {
          length: -5,
          show: false,
          lineStyle: {
            color: "#000",
            width: 10,
          },
        },
        splitLine: {
          length: 10,
          show: false,
          offsetCenter: [0, "-60%"],
          lineStyle: {
            color: "#000",
            width: 1,
          },
          color: "#000",
        },
        title: {
          offsetCenter: [0, "40%"],
          fontSize: "20px",
          fontFamily: "Roboto",
          fontWeight: 500,
        },
        detail: {
          fontSize: "30px",
          fontWeight: 500,

          fontFamily: "Roboto",

          offsetCenter: [0, "0%"],
          valueAnimation: true,
          formatter: function (value) {
            if (value > 99) {
              return `${value.toFixed(0)}`;
            }
            if (value < 1 && value > 0) {
              return `${parseFloat(value.toFixed(3)).toLocaleString("pt-BR", {
                minimumFractionDigits: 3,
              })}`;
            }
            if (!value) {
              return `${"0"}`;
            }

            return `${parseFloat(value.toFixed(2)).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })}`;
          },
          color: theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
        },
        data: [
          {
            value: value,
            name: data?.speedometer_main.unit.abbreviation,
            interval: range,
          },
        ],
      },
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 60,
        min: 0,
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)),

        itemStyle: {
          color: "#118751",
        },
        progress: {
          show: true,
          width: value === 0 ? 0 : 30,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: -2,
            color: [[1, "transparent"]],
            shadowColor: "white",
            shadowBlur: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: value,
          },
        ],
      },
      {
        type: "gauge",
        startAngle: 60,
        endAngle: 0,
        min:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)),
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)) *
            1.5,
        itemStyle: {
          color: "#c04040",
        },
        progress: {
          show: true,
          width: value === 0 ? 0 : 30,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: true,

          lineStyle: {
            width: -2,
            color: [[1, "transparent"]],
            shadowColor: "white",
            shadowBlur: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: value,
          },
        ],
      },
    ],
  };
  const getBarometer = () => {
    
      return (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", width:'100%', padding: "10px", flexDirection: "row"  }}> 
          {data && <span >{data.speedometer_main.name}</span>}

          {isDigitalTwinOn && twinStatus === "normal" && <Ok />}
            {isDigitalTwinOn && twinStatus === "alert" && <Alert />}
            {isDigitalTwinOn && twinStatus === "alarm" && <Alarm />}
          </Box>

          <ReactEcharts
            option={option}
            style={{ width: "230px",marginBottom:'-60px',marginTop:'-50px' }}
          
          />
          
        </Box>
      );
    

    
  };

  const renderDesiredValueOrProgress = () => {
    if (!range) {
      return <Box sx={{ width: "100%",
        alignItems: "center",
        
        height: "25px",
      }} >
        
        </Box>;
    }

    const isValueGreaterThanZero = range[1] > 0;
    const isStatusValid = ["alarm", "alert", "normal"].includes(twinStatus);
    const shouldShowProgress = !isValueGreaterThanZero && isStatusValid;

    if (shouldShowProgress) {
      return (
        <CircularProgress
          sx={{
            color: "#124251",
            position: "relative",
            bottom: "5px",
          }}
          size={20}
        />
      );
    }

    return (
      <ReferenceValue
        width={"100%"}
        value={range[1]}
        sensorName={data?.y_axis_1[0]?.name}
        fontSize="14px"
        
      ></ReferenceValue>
    );
  };

  

  return (
    <Paper
      elevation={0}
      onClick={() => {
        dispatch(setDigitalTwin(props.digitalTwin));
        dispatch(setCharts([]));
        DataService.getGraphsByTwin(props.digitalTwin.id).then((res) => {
          dispatch(setCharts(res.data));
        })
        navigate(paths.PANEL_MONITORING)
      }}
      key={props.digitalTwin.id}
      sx={{
        width: "300px",
        height: "300px",
        cursor: "pointer",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        opacity: started ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        
        border: redBoard,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          
          
          margin: "0",
          fontFamily: "Roboto",
          fontWeight: "bold",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "18px",
            cursor: "pointer",
            color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
          }}
        >
          {props.digitalTwin.name}
        </Typography>
        <Box
          style={{
            padding: "0px",
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "18px",
            cursor: "pointer",
          }}
        >
          {getTwinStatus()}
        </Box>
      </Box>

      
      <Box
            sx={{
              
              
              display: "flex",
              flexDirection: "column",
              
              
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {getBarometer()}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Roboto",
                fontWeight: 400,
                cursor: "pointer",
                fontSize: "14px",
                color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
              }}
            >
              {renderDesiredValueOrProgress()}
            </Box>
      </Box>
       
    </Paper>
  );
};
export default TwinCard;
