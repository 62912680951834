import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCompany } from "../../store/slices/companyslice";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";
import { useLocation } from "react-router-dom";
import "./TopBar.css";
import paths from "../../router/paths";

const SectionTitle = () => {
  const location = useLocation();
  const company = useSelector(getCompany);
  const digitalTwin = useSelector(getDigitalTwin);
  const [title, setTitle] = useState<any>();

  useEffect(() => {
    const getTitle = (pathname) => {
      switch (pathname) {
        case paths.FAQ:
          return "Faq > Util 4.0";
        case paths.PANEL_TWINS:
          return `Planta Industrial > ${company?.name ?? ""}`;
        case paths.PANEL_MONITORING:
          return `Monitoramento > ${company?.name ?? ""} > ${digitalTwin.name}`;
        case paths.HISTORICO_DADOS:
          return `Histórico de Dados > ${company?.name ?? ""}`;
        case paths.HISTORICO_RECOMENDACOES:
          return "Histórico de Recomendações";
        case paths.GROUPS_PAGE:
          return "Configurações";
        case paths.CHOOSE_COMPANY:
          return "Painel In Forma";
        case paths.ANALYTICS_HOME_PAGE:
          return "Análise";
        default:
          return "U~4.0";
      }
    };

    setTitle(getTitle(location.pathname));
  }, [company, digitalTwin, location.pathname]);

  return <span id="topBarSectionTitle">{title}</span>;
};

export default SectionTitle;
