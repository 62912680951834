export const formatString = (str: string) => {
  str = str.replace(/_/g, " ");
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0) + arr[i].slice(1);
  }
  str = arr.join(" ");

  return str;
};

export const sensorName = (str: string) => {
  if (str === "temperatura_ar_apos_GASTEC")
    return "Temperatura de entrada da torre";

  if (str === "pressao_bomba_baixa") return "Pressão da bomba de baixa";

  if (str === "pressao_bomba_alta") return "Pressão da bomba de alta";

  if (str === "sensor_umidade_po" || str === "27") return "Umidade do Pó";
  

  if (str === "pressao_torre_secagem" || str === "4")
    return "Pressão negativa da torre";

  if (str === "34") return "Status piscina de slurry";

  if (str === "temperatura_ar_apos_torre_secagem")
    return "Temperatura do ar após a torre de secagem";

  if (str === "ref_velocidade_bomba_alta") return "Velocidade da bomba de alta";

  if (str === "ref_velocidade_filtro_manga_externo")
    return "Velocidade no filtro de manga externo";

  if (str === "ref_abertura_valvula_gas_GASTEC")
    return "Abertura da valvula de gas no GASTEC";

  if (str === "vazao_barrilha_hidratada") return "Vazão barrilha hidratada";

  if (str === "vazao_gas_GASTEC") return "Vazão do gas no GASTEC";

  if (str === "vazao_po_soprado") return "Vazão do pó soprado";

  if (str === "consumo_gas_GASTEC") return "Consumo de gás no GASTEC";

  if (str === "producao_GASTEC" || str === "924") return "Produção no GASTEC";

  if (str === "eficiencia_GASTEC") return "Eficiência GASTEC";

  //roca
  if (str === "KPI-CONSUMO-POR-EFICIENCIA") return "Eficiência";
  if (str === "VL_TEMPERAT_3")
    return "Regulador de leitura 1031 Pré-Aquecimento do Forno";
  if (str === "VL_TEMPERAT_4") return "Regulador de leitura 1041 ";
  if (str === "VL_TEMPERAT_5") return "Regulador de leitura 1042";
  if (str === "VL_TEMPERAT_6") return "Regulador de leitura 1051";
  if (str === "VL_TEMPERAT_7") return "Regulador de leitura 1061";
  if (str === "VL_TEMPERAT_8") return "Regulador de leitura 1062";
  if (str === "VL_TEMPERAT_9") return "Regulador de leitura 1071";
  if (str === "VL_TEMPERAT_10") return "Regulador de leitura 1081";
  if (str === "VL_TEMPERAT_11") return "Regulador de leitura 1082";
  if (str === "VL_TEMPERAT_12") return "Regulador de leitura 1091";
  if (str === "VL_TEMPERAT_13") return "Regulador de leitura 1092";
  if (str === "VL_TEMPERAT_14") return "Regulador de leitura 1101";
  if (str === "VL_TEMPERAT_15") return "Regulador de leitura 1102";
  if (str === "VL_TEMPERAT_16") return "Regulador de leitura 1111";
  if (str === "VL_TEMPERAT_17") return "Regulador de leitura 1112";
  if (str === "VL_TEMPERAT_18") return "Regulador de leitura 1122";
  if (str === "VL_TEMPERAT_19") return "Regulador de leitura 1132";
  if (str === "VL_TEMPERAT_20") return "Regulador de leitura 1142";
  if (str === "VL_TEMPERAT_21") return "Regulador de leitura 1152";
  if (str === "VL_TEMPERAT_22") return "Regulador de leitura 1162";
  if (str === "VL_TEMPERAT_23") return "Regulador de leitura 1014";
  if (str === "VL_TEMPERAT_24") return "Regulador de leitura 1024";
  if (str === "VL_TEMPERAT_25") return "Regulador de leitura 1034";

 
  if (str === "Qtd Energia Portico1 (variável de processo)") return "Pórtico 1";
  if (str === "Qtd Energia Portico2 (variável de processo)") return "Pórtico 2";
  if (str === "Qtd Energia Portico3 (variável de processo)") return "Pórtico 3";
  if (str === "Qtd Energia Portico4 (variável de processo)") return "Pórtico 4";
  if (str === "Qtd Energia Portico5 (variável de processo)") return "Pórtico 5";
  if (str === "Qtd Energia Portico6 (variável de processo)") return "Pórtico 6";
  if (str === "Qtd Energia Portico7 (variável de processo)") return "Pórtico 7";
  // if (str === "cegn") return "Setpoint CEGN";

  if (str === "SP CEGN") return "SP CEGN";


  // default
  if (str === undefined) return "";
  if (str) return str;
};
