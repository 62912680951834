import {  TextField } from "@mui/material";
import { useState } from "react";
import { ReactComponent as Ok } from "../../Assets/Icons/check_white.svg";
import { ReactComponent as Alarm } from "../../Assets/Icons/Alarm.svg";
import AuthService from "../../Services/AuthService";
import validateEmail from "../../Utils/emailValidator";
import "./FeedbackModal.css";



const FeedbackModal = (props: any) => {
  const [email, setEmail] = useState("");
  const [disabeButtons] = useState(false);
  const [resultState, setResultState] = useState<any>(null);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const passwordRecovery = async () => {
 
    if (!validateEmail(email)) {
      
        setError({
          title: "Erro!",
          message: "O e-mail informado não é válido.",
        })
      //Apagando msg de erro após 5 segundos
      setTimeout(() => {
        setError({
          title: "",
          message: "",
        })
      }, 5000);
    } else {
      
      let result;
      try {
        result = await AuthService.forgotPassword(email);
        
        if (result?.status === 200) {
          setResultState(200);
          setError({
            title: "Sucesso!",
            message: "Um e-mail foi enviado para redefinir sua senha.",
          })
          setTimeout(() => {
            setError({
              title: "",
              message: "",
            })
          }, 5000);
          return
        }
        if (result?.status === 304) {
          setResultState(304);
          setError({
            title: "Erro!",
            message: "O e-mail informado não existe.",
          })
          setTimeout(() => {
            setError({
              title: "",
              message: "",
            })
          }, 5000);
          return
        }

        if (result?.status === 400) {
          setResultState(400);
          setError({
            title: "Erro!",
            message: "O e-mail informado não existe.",
          })
          setTimeout(() => {
            setError({
              title: "",
              message: "",
            })
          }, 5000);
          return
        }   else {
         
          setResultState(500);
          setError({
            title: "Erro!",
            message: "Ocorreu um erro ao tentar redefinir sua senha.",
          })
          setTimeout(() => {
            setError({
              title: "",
              message: "",
            })
          }, 5000);
          return
        }
      } catch (err) {}
      ;
      setEmail("");
      setError({
        title: "",
        message: "",
      })
    }
  };

  return (
    <div className="FeedbackModal">
      <div className="mainModal">
        <div className="head">
          <div
            style={{
              color: "white",
              fontFamily: "Roboto, Courier, monospace",
              fontWeight: "600",
              fontSize: "20px",
              padding: "10px",

            }}
          >
            Redefinição de Senha
          </div>
        </div>
        {resultState === null ? (
          <div className="body" style={{ textAlign: "center" , height: "100%"}}>
            <div  style={{ fontSize: "medium" , paddingBottom: "10px" }}>Para redefinir sua senha, informe o seu e-mail abaixo:</div>

            {error.title !== "" ? (
              <div
              style={{ display: "flex", height: "7vh", margin: "0 0 0vh 0" }}
            >
              <div
                style={{
                  backgroundColor: "#C04040",
                  width: "10px",
                  borderRadius: "6px 0  0 6px",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F0D0D0",
                  flexGrow: "1",
                  borderRadius: "0 6px 6px 0 ",
                }}
              >
                <Alarm style={{ margin: "0 10px" }} />
                <div className="flex">
                <p ><span style={{ color: "#333" , fontSize: "medium", fontWeight: "600",}}>{error.title}</span> {error.message}</p>
                  
                </div>
              </div>
            </div>
            ):<TextField fullWidth id="outlined-basic" label="Email" variant="outlined"  onChange={(e) => {
              setEmail(e.target.value);
            }}/>}
            

            <div style={{ fontSize: "medium" , paddingTop: "10px"}}>Você receberá um email com instruções para redefinição.</div>

            <div className="buttonsModal">
              {/* <Button
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                disabled={disabeButtons}
              >
                Cancelar
              </Button> */}
              <button
               id="cancelButton"
               name="cancel"
               onClick={props.cancel}
               disabled={disabeButtons}
          type="submit"
          style={{
            border: "0.50px solid #124251",
            backgroundColor: "white",
            color: "#124251",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "6px",
            flexGrow: "1",
          }}
        >
          
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#124251",
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              Cancelar
            </p>
           
          </div>
        </button>
              {/* <Button
                id="sendButton"
                disabled={disabeButtons}
                name="confirm"
                onClick={()=>passwordRecovery()}
              >
                Enviar
              </Button> */}
              <button
               id="sendButton"
               disabled={disabeButtons}
               name="confirm"
               onClick={()=>passwordRecovery()}
          type="submit"
          style={{
            border: "0.50px solid #EBEBEB",
            backgroundColor: "#124251",
            color: "#FFF",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "6px",
            flexGrow: "1",
          }}
        >
          
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              Enviar
            </p>
           
          </div>
        </button>
            </div>
            
          </div>
        ) : (
          <div className="body">
            {resultState === 500 ? (
              <div
                style={{ display: "flex", height: "7vh", margin: "0 0 5vh 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#C04040",
                    width: "10px",
                    borderRadius: "6px 0  0 6px",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F0D0D0",
                    flexGrow: "1",
                    borderRadius: "0 6px 6px 0 ",
                  }}
                >
                  <Alarm style={{ margin: "0 10px" }} />
                  <div className="flex">
                    <h3 style={{ margin: "0" }}>Error!</h3><p style={{ margin: "0" }}>{error.message || 'Algo deu errado.'}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ display: "flex", height: "70px" }}
              >
                <div
                  style={{
                    backgroundColor: "#118751",
                    width: "10px",
                    borderRadius: "6px 0  0 6px",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#DEE9E4",
                    flexGrow: "1",
                    borderRadius: "0 6px 6px 0 ",
                  }}
                >
                  <Ok style={{ margin: "0 10px" }} />
                  <div>
                    <h3 style={{ margin: "10px" }}>Email enviado com sucesso</h3>
                  </div>
                </div>
              </div>
            )}

            <div className="buttonsModal" style={{ justifyContent: "center" }}>
              {/* <Button
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                disabled={disabeButtons}
              >
                Fechar
              </Button> */}
              <button
              id="cancelButton"
              name="cancel"
              onClick={props.cancel}
              disabled={disabeButtons}
          type="submit"
          style={{
            border: "0.50px solid #EBEBEB",
            backgroundColor: "#124251",
            color: "#FFF",
            marginTop: "50px",
            padding: "10px",
            borderRadius: "6px",
            flexGrow: "1",
          }}
        >
          
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              Fechar
            </p>
           
          </div>
        </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FeedbackModal;
