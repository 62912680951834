import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { useEffect, useMemo, useState } from "react";
import { Paper } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { getIsDarkTheme } from "../../store/slices/themeSlice";

interface CardBarraProps {
  elemento: any;
  w:  number | null;
  h:  number | null;

}

const CardBarra = (props: CardBarraProps) => {
  const isDarkTheme = useSelector(getIsDarkTheme);

  const [resmock, setResMock] = useState<any>([]);
  const [dataset, setDataset] = useState<any>([
    ["turno/date", "Turno 1", "Turno 2", "Turno 3", "Dia"],
  ]);

  useEffect(() => {
    const deviceId = props.elemento.y_axis_1[0].device_id;
    const sensorId = props.elemento.y_axis_1[0].number;
    const date2 = moment().format("YYYY-MM-DD");
    const date1 = moment().subtract(10, "day").format("YYYY-MM-DD");

    axios
      .get(
        `/newApi/get_raw_info?&deviceId=${deviceId}&dayofweek=1&sensorId=${sensorId}&start_datetime=${date1}T00:00:00.000Z&end_datetime=${date2}T${moment().format(
          "HH:mm:ss"
        )}.000Z`
      )
      .then((response) => {
        setResMock(
          response.data.map((element) => {
            const turno = element.timestamp.slice(11, 19);
            const date = element.timestamp.slice(0, 10);
            const value = element.value;
            return turno === "13:50:00"
              ? [date, value, NaN, NaN, NaN]
              : turno === "22:10:00"
              ? [date, NaN, value, NaN, NaN]
              : turno === "05:30:00" && [
                  moment(date).subtract(1, "day").format("YYYY-MM-DD"),
                  NaN,
                  NaN,
                  value,
                  NaN,
                ];
          })
        );
      });
  }, [props.elemento.y_axis_1]);

  useEffect(() => {
    const result = Object.values(
      resmock.reduce((acc, [date, ...values]) => {
        if (!acc[date]) {
          acc[date] = {
            date,
            counts: 0,
            sum: 0,
          };
        }

        values.forEach((value) => {
          if (!isNaN(value)) {
            acc[date].sum += value;
            acc[date].counts++;
          }
        });

        return acc;
      }, {})
    ).map((elemento: any) => [
      elemento.date,
      NaN,
      NaN,
      NaN,
      elemento.sum /
        (props.elemento.name === "Eficiencia" ? elemento.counts : 1),
    ]);

    setDataset((dataset) => dataset.concat(resmock).concat(result));
  }, [resmock, props.elemento.name]);

  echarts.registerTheme("my_theme", {
    backgroundColor: "",
  });
  echarts.registerTheme("another_theme", {
    backgroundColor: "rgb(0,0,0,0.5)",
  });

  let type = useMemo<any>(
    () => props.elemento.default_visualization,
    [props.elemento.default_visualization]
  );
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);
  return (
    <>
      <Paper
        sx={{
          opacity: started ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          backgroundColor: isDarkTheme ? "#555" : "white",
          color: !isDarkTheme ? "#323238" : "white",
          display: "flex-column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          listStyle: "none",
          p: "0px",
          m: "0px",
          height: "100%",
        }}
      >
        <div
          className="draggableHandle"
          style={{
            cursor: "grab",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "400",
            fontSize: "larger",
            width: "calc(100% - 60px)",
            height: "50px",
            margin: "0",
            paddingTop: "12px",
            zIndex: 100,
          }}
        ></div>

        <div
          style={{
            height: '100%',
            position: "relative",
            display: "flex",
            top: "-50px",
            right: "0",
            alignItems: "center",
            width: "100%",
            zIndex: 0,
          }}
        >
          {type === "bar" && (
            <>
              <div className="echartsBar">
                <ReactEcharts
                  style={{
                    display: "flex",
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                    width: '100%',
                    top: 10,
                  }}
                  option={{
                    title: {
                      text: ` ${props.elemento.name}`,
                      subtext: ` (${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()})  `,
                      left: 0,
                      top: 0,
                      textStyle: {
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "Roboto",
                        color: "rgb(18, 66, 81)",
                      },
                      subtextStyle: {
                        fontWeight: "300",
                        fontSize: 13,
                        align: "left",
                      },
                      series: {},
                    },
                    grid: { top: 80, right: 20, bottom: 50, left: 70 },
                    toolbox: {
                      top: 0,
                      right: 15,
                      show: true,
                      feature: {
                        saveAsImage: {},
                      },
                    },
                    legend: { bottom: 0 },
                    tooltip: {},
                    dataset: { source: dataset },
                    xAxis: { type: "category" },
                    yAxis: {
                      name: props.elemento.y_axis_1[0].unit.abbreviation,
                      type: "value",
                      alignTicks: true,
                      scale: true,
                      min:
                        props.elemento.name === "Eficiencia"
                          ? 0.12
                          : props.elemento.name === "Vazão de GN"
                          ? 0
                          : null,
                      axisTick: {
                        show: true,
                        alignWithLabel: true,
                      },
                      axisLine: {
                        show: false,
                        lineStyle: {
                          color: "#979797",
                        },
                      },
                      axisLabel: {
                        formatter: "{value}",
                        color: "#4D4D4D",
                      },
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [
                      { type: "bar", color: "#98cbff" },
                      { type: "bar", color: "#548bbb" },
                      { type: "bar", color: "#236390" },
                      { type: "bar", color: "#9eebe5" },
                    ],
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Paper>
    </>
  );
};

export default CardBarra;
