import React, { useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewChartForm.css";
import Select from "react-select";
import { Button, CircularProgress } from "@mui/material";
import PositionInput from "../../Inputs/PositionInput";

interface newChartInterface {
  name: string;
  position: string;
  time1: string;
  time2: number;

  // defaultVisualization: "line" | "speedometer" | "pie" | "bar";
  defaultVisualization: string;
  lineVisualization: boolean;
  speedometerVisualization: boolean;
  pieVisualization: boolean;
  barVisualization: boolean;
  //line data
  xSensorLine: number | null;
  ySensors1Line: Array<any> | null;
  ySensors2Line: Array<any> | null;
  zSensorLine: number | null;
  tooltipSensors: Array<any> | null;
  alertsAlarmsSensors: Array<any> | null;
  areasSensors: Array<any> | null;
  //speedometerData
  mainSensorSpeedometer: number | null;
  secondarySensorsSpeedometer: Array<any> | null;
  speedometerMax: number | null;
  speedometerMin: number | null;
  speedometerBreak1: number | null;
  speedometerBreak2: number | null;
  //pieData
  pieSensors: Array<any> | null;
  //barData
  xSensorBar: number | null;
  ySensors1Bar: Array<any> | null;
}

const initialState: newChartInterface = {
  name: "",
  time1: "",
  time2: 1,
  position: "",
  xSensorLine: null,
  ySensors1Line: null,
  ySensors2Line: null,
  zSensorLine: null,
  tooltipSensors: null,
  alertsAlarmsSensors: null,
  areasSensors: null,
  defaultVisualization: "line",
  lineVisualization: true,
  speedometerVisualization: false,
  pieVisualization: false,
  barVisualization: false,
  mainSensorSpeedometer: null,
  secondarySensorsSpeedometer: null,
  speedometerMax: null,
  speedometerMin: null,
  speedometerBreak1: null,
  speedometerBreak2: null,
  //pieData
  pieSensors: null,
  //barData
  xSensorBar: null,
  ySensors1Bar: null,
};

const NewChartForm = (props: any) => {
  // console.log(props.graph);
  // console.log(initialState);

  const [newChart, setNewChart] = useState<newChartInterface>(initialState);

  const [defaultState, setDefaultState] = useState<Array<boolean>>([
    true,
    false,
    false,
    false,
  ]);

  //Esses stados armazenam os campos selecionados para os input do tipo multselect
  //Cada multiselect utiliza um estado do react para fazer o controle da filtragem dos possiveis
  //valores a serem selecionados(Sensores que possuem as mesmas unidades de medida).
  const [array, setArray] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [array2, setArray2] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arraySpeedometer, setArraySpeedometer] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arrayPie, setArrayPie] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arrayBar, setArrayBar] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arrayTooltipLine, setArrayTooltipLine] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arrayAlertsAlarms, setArrayAlertsAlarms] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );
  const [arrayAreas, setArrayAreas] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );

  const [loadingRequest, setLoadingRequest] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingRequest(true);
    event.preventDefault();

    // console.log(newChart);

    let z: null | number;
    if (newChart.zSensorLine === -1) {
      z = null;
    } else {
      z = newChart.zSensorLine;
    }
    let x: null | number;
    if (newChart.xSensorLine === -1) {
      x = null;
    } else {
      x = newChart.xSensorLine;
    }
    let xBar: null | number;
    if (newChart.xSensorBar === -1) {
      xBar = null;
    } else {
      xBar = newChart.xSensorBar;
    }

    const response = await DbService.store_graph(
      props.digitalTwinId,
      newChart.name,
      newChart.position,
      newChart.time1,
      newChart.time2,
      //line
      x,
      newChart.ySensors1Line,
      newChart.ySensors2Line,
      z,
      newChart.tooltipSensors,
      newChart.alertsAlarmsSensors,
      newChart.areasSensors,
      //speed
      newChart.mainSensorSpeedometer,
      newChart.secondarySensorsSpeedometer,
      newChart.speedometerMin,
      newChart.speedometerMax,
      newChart.speedometerBreak1,
      newChart.speedometerBreak2,
      //pie
      newChart.pieSensors,
      //bar
      xBar,
      newChart.ySensors1Bar,

      newChart.defaultVisualization,

      newChart.lineVisualization,
      newChart.speedometerVisualization,
      newChart.pieVisualization,
      newChart.barVisualization
    ); //.then(() => (navigate("/groups")));
    setLoadingRequest(false);
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  let options = props.sensors.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  //Função que é chamada quando tem mudança em aglgum
  //campo do formulario menos dos multiselect
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (name === "xSensor" || name === "zSensor") {
      setNewChart({ ...newChart, [name]: parseInt(value) });
    } else {
      setNewChart({ ...newChart, [name]: value });
    }
  };

  //Funcao que atualiza o campos das possiveis visualizacoes dos graficos
  const togleVisualization = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    if (
      !(name.replace("Visualization", "") === newChart.defaultVisualization)
    ) {
      setNewChart({ ...newChart, [name]: !newChart[name] });
    }
    // console.log(newChart);
  };

  //Funcao que atualiza o campo de vizualicao padrao do grafico
  const togleDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    switch (name) {
      case "line":
        setDefaultState([true, false, false, false]);
        if (newChart.lineVisualization === false) {
          const newChartCopy = newChart;
          newChartCopy.lineVisualization = true;
          setNewChart(newChartCopy);
        }
        break;
      case "speedometer":
        setDefaultState([false, true, false, false]);
        if (newChart.speedometerVisualization === false) {
          const newChartCopy = newChart;
          newChartCopy.speedometerVisualization = true;
          setNewChart(newChartCopy);
        }
        break;
      case "pie":
        setDefaultState([false, false, true, false]);
        if (newChart.pieVisualization === false) {
          const newChartCopy = newChart;
          newChartCopy.pieVisualization = true;
          setNewChart(newChartCopy);
        }
        break;
      case "bar":
        setDefaultState([false, false, false, true]);
        if (newChart.barVisualization === false) {
          const newChartCopy = newChart;
          newChartCopy.barVisualization = true;
          setNewChart(newChartCopy);
        }
        break;
    }
    setNewChart({ ...newChart });
  };

  //As funções a seguir são utilizadas quando tem uma mudança em algum multiselect
  // Por alguma limitação que eu não soube superar cada multiselect precisou ter a sua propria
  //funcao de atualizacao
  //-------------------------------------------------------------------------------------------------------------------
  const multiSelectChange = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensors(x);
    } else if (event.length === 0) {
      setArray(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      ySensors1Line: event.map((event: any) => event.value),
    });
  };

  const multiSelectChange2 = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensors2(x);
    } else if (event.length === 0) {
      setArray2(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      ySensors2Line: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangeSpeedometer = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsSpeedometer(x);
    } else if (event.length === 0) {
      setArraySpeedometer(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      secondarySensorsSpeedometer: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangePie = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsPie(x);
    } else if (event.length === 0) {
      setArrayPie(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      pieSensors: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangeBar = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsBar(x);
    } else if (event.length === 0) {
      setArrayBar(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      ySensors1Bar: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangeTooltip = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsTooltip(x);
    } else if (event.length === 0) {
      setArrayTooltipLine(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      tooltipSensors: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangeAlertsAlarms = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsAlertsAlarms(x);
    } else if (event.length === 0) {
      setArrayAlertsAlarms(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      alertsAlarmsSensors: event.map((event: any) => event.value),
    });
  };

  const multiSelectChangeAreas = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      // console.log(aux);
      const x = aux.unit.id;
      //unit = unit.unit;
      // console.log(x);
      filterSensorsAreas(x);
    } else if (event.length === 0) {
      setArrayAreas(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setNewChart({
      ...newChart,
      areasSensors: event.map((event: any) => event.value),
    });
  };
  //-------------------------------------------------------------------------------------------------------------------

  // As funcoes a seguir sao utilizadas para filtrar as opcoes disponiveis
  // do multiselect. Pelo mesmo motivo das funcoes de atualizacao cada multiselect
  // precisa de uma funcao de filtragem diferente
  //=================================================================================================================================
  const filterSensors = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArray(options);
  };

  const filterSensors2 = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArray2(options);
  };

  const filterSensorsSpeedometer = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArraySpeedometer(options);
  };

  const filterSensorsPie = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArrayPie(options);
  };

  const filterSensorsBar = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArrayBar(options);
  };

  const filterSensorsAlertsAlarms = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArrayBar(options);
  };

  const filterSensorsTooltip = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArrayBar(options);
  };

  const filterSensorsAreas = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit.id === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    // console.log(options);
    setArrayAreas(options);
  };
  //=================================================================================================================================

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "92vh",
      }}
    >
      <div
        className="inputs"
        style={{ overflowY: "scroll", maxHeight: "80vh" }}
      >
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            placeholder="Digite o nome do Grafico"
          />
          <label htmlFor="name">Nome</label>
        </div>

        <PositionInput
          positionString={newChart.position}
          onChange={(updatedPosition) =>
            setNewChart({ ...newChart, position: updatedPosition })
          }
        ></PositionInput>

        <div className="form-div-select">
          <select id="time1" name="time1" onChange={onChange} required>
            <option
              className="placeholderOption"
              value=""
              disabled
              selected
              hidden
            >
              Selecione o sensor exibido no eixo x
            </option>
            <option value={"minutes"}>Minutos</option>
            <option value={"hours"}>Horas</option>
            <option value={"days"}>Dias</option>
          </select>
          <label htmlFor="time1">Medida de Tempo</label>
        </div>

        <div className="form-div-input">
          <input
            id="time2"
            name="time2"
            type="number"
            onChange={onChange}
            placeholder="Digite o nome do Grafico"
          />
          <label htmlFor="time2">Quantidade de Tempo</label>
        </div>

        <div className="tickBoxes">
          <div className="tickBoxGroup">
            <div>
              <input
                onChange={togleVisualization}
                checked={newChart.lineVisualization}
                id="lineVisualization"
                name="lineVisualization"
                type="checkbox"
              />
              <label htmlFor="lineVisualization">Linha</label>
            </div>
            <div>
              <input
                id="line"
                name="line"
                type="checkbox"
                checked={defaultState[0]}
                onChange={togleDefault}
              />
              <label htmlFor="line">Visualização padrão</label>
            </div>
          </div>
          {newChart.lineVisualization && (
            <div className="axis">
              <div className="form-div-select">
                <select
                  id="xSensor"
                  name="xSensor"
                  onChange={onChange}
                  required
                  // value={newChart.xSensor || -1}
                >
                  <option
                    className="placeholderOption"
                    value=""
                    disabled
                    selected
                    hidden
                  >
                    Selecione o sensor exibido no eixo x
                  </option>
                  <option value={-1}>Tempo</option>
                  {props.sensors.map((sensor: any) => {
                    return <option value={sensor.id}>{sensor.name}</option>;
                  })}
                </select>
                <label htmlFor="xSensor">Eixo x</label>
              </div>

              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="ySensors1"
                  name="ySensors1"
                  options={array}
                  isMulti
                  onChange={multiSelectChange}
                  placeholder="Selecione o(s) sensore(s) exibido(s) no eixo y da esquerda"
                />
                <label htmlFor="ySensors1">Eixo y 1</label>
              </div>
              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="ySensors2"
                  name="ySensors2"
                  options={array2}
                  isMulti
                  onChange={multiSelectChange2}
                  placeholder="Selecione o(s) sensore(s) exibido(s) no eixo y da direita"
                />
                <label htmlFor="ySensors2">Eixo y 2</label>
              </div>
              <div className="form-div-select">
                <select
                  id="zSensor"
                  name="zSensor"
                  onChange={onChange}
                  required
                  //value={newChart.zSensor || -1}
                >
                  <option
                    className="placeholderOption"
                    value=""
                    disabled
                    selected
                    hidden
                  >
                    Selecione o sensor exibido no eixo z
                  </option>
                  <option value={-1}>nenhum</option>
                  {props.sensors.map((sensor: any) => {
                    return <option value={sensor.id}>{sensor.name}</option>;
                  })}
                </select>
                <label htmlFor="zSensor">Eixo z</label>
              </div>

              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="tooltipSensors"
                  name="tooltipSensors"
                  options={arrayTooltipLine}
                  isMulti
                  onChange={multiSelectChangeTooltip}
                  placeholder="Selecione o(s) sensore(s) exibido(s) na tooltip"
                />
                <label htmlFor="tooltipSensors">...1</label>
              </div>

              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="alertsAlarms"
                  name="alertsAlarms"
                  options={arrayAlertsAlarms}
                  isMulti
                  onChange={multiSelectChangeAlertsAlarms}
                  placeholder="Selecione o(s) sensore(s) ..."
                />
                <label htmlFor="alertsAlarms">...2</label>
              </div>

              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="areas"
                  name="areas"
                  options={arrayAreas}
                  isMulti
                  onChange={multiSelectChangeAreas}
                  placeholder="Selecione o(s) sensore(s) ..."
                />
                <label htmlFor="areas">...3</label>
              </div>
            </div>
          )}
          <div className="tickBoxGroup">
            <div>
              <input
                onChange={togleVisualization}
                checked={newChart.speedometerVisualization}
                id="speedometerVisualization"
                name="speedometerVisualization"
                type="checkbox"
              />
              <label htmlFor="speedometerVisualization">Velocimetro</label>
            </div>
            <div>
              <input
                id="speedometer"
                name="speedometer"
                type="checkbox"
                checked={defaultState[1]}
                onChange={togleDefault}
              />
              <label htmlFor="speedometer">Visualização padrão</label>
            </div>
          </div>
          {newChart.speedometerVisualization && (
            <div className="axis">
              <div className="form-div-select">
                <select
                  id="mainSensorSpeedometer"
                  name="mainSensorSpeedometer"
                  onChange={onChange}
                  required
                  // value={newChart.xSensor || -1}
                >
                  <option
                    className="placeholderOption"
                    value=""
                    disabled
                    selected
                    hidden
                  >
                    Selecione o sensor exibido no eixo x
                  </option>
                  <option value={-1}>Tempo</option>
                  {props.sensors.map((sensor: any) => {
                    return <option value={sensor.id}>{sensor.name}</option>;
                  })}
                </select>
                <label htmlFor="mainSensorSpeedometer">Eixo x</label>
              </div>

              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="secondarySensorsSpeedometer"
                  name="secondarySensorsSpeedometer"
                  options={arraySpeedometer}
                  isMulti
                  onChange={multiSelectChangeSpeedometer}
                  placeholder="Selecione o(s) .."
                />
                <label htmlFor="secondarySensorsSpeedometer">Secundarios</label>
              </div>

              <div className="form-div-input">
                <input
                  id="speedometerMin"
                  name="speedometerMin"
                  type="number"
                  onChange={onChange}
                  placeholder="Digite o ..."
                />
                <label htmlFor="name">Minimo</label>
              </div>
              <div className="form-div-input">
                <input
                  id="speedometerMax"
                  name="speedometerMax"
                  type="number"
                  onChange={onChange}
                  placeholder="Digite o ..."
                />
                <label htmlFor="speedometerMax">Maximo</label>
              </div>
              <div className="form-div-input">
                <input
                  id="speedometerBreak1"
                  name="speedometerBreak1"
                  type="number"
                  onChange={onChange}
                  placeholder="Digite o ..."
                />
                <label htmlFor="speedometerBreak1">Break 1</label>
              </div>
              <div className="form-div-input">
                <input
                  id="speedometerBreak2"
                  name="speedometerBreak2"
                  type="number"
                  onChange={onChange}
                  placeholder="Digite o ..."
                />
                <label htmlFor="speedometerBreak2">Break 2</label>
              </div>
            </div>
          )}
          <div className="tickBoxGroup">
            <div>
              <input
                onChange={togleVisualization}
                checked={newChart.pieVisualization}
                id="pieVisualization"
                name="pieVisualization"
                type="checkbox"
              />
              <label htmlFor="pieVisualization">Pizza</label>
            </div>
            <div>
              <input
                id="pie"
                name="pie"
                type="checkbox"
                checked={defaultState[2]}
                onChange={togleDefault}
              />
              <label htmlFor="pie">Visualização padrão</label>
            </div>
          </div>
          {newChart.pieVisualization && (
            <div className="axis">
              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="pieSensors"
                  name="pieSensors"
                  options={arrayPie}
                  isMulti
                  onChange={multiSelectChangePie}
                  placeholder="Selecione ..."
                />
                <label htmlFor="pieSensors">Sensores</label>
              </div>
            </div>
          )}
          <div className="tickBoxGroup">
            <div>
              <input
                onChange={togleVisualization}
                checked={newChart.barVisualization}
                id="barVisualization"
                name="barVisualization"
                type="checkbox"
              />
              <label htmlFor="barVisualization">Barras</label>
            </div>
            <div>
              <input
                id="bar"
                name="bar"
                type="checkbox"
                checked={defaultState[3]}
                onChange={togleDefault}
              />
              <label htmlFor="bar">Visualização padrão</label>
            </div>
          </div>
          {newChart.barVisualization && (
            <div className="axis">
              <div className="form-div-select">
                <select
                  id="xSensorBar"
                  name="xSensorBar"
                  onChange={onChange}
                  required
                  // value={newChart.xSensor || -1}
                >
                  <option
                    className="placeholderOption"
                    value=""
                    disabled
                    selected
                    hidden
                  >
                    Selecione o sensor exibido no eixo x
                  </option>
                  <option value={-1}>Tempo</option>
                  {props.sensors.map((sensor: any) => {
                    return <option value={sensor.id}>{sensor.name}</option>;
                  })}
                </select>
                <label htmlFor="xSensorBar">Eixo x</label>
              </div>
              <div className="test" style={{ marginTop: "15px" }}>
                <Select
                  id="ySensors1Bar"
                  name="ySensors1Bar"
                  options={arrayBar}
                  isMulti
                  onChange={multiSelectChangeBar}
                  placeholder="Selecione o(s) sensore(s) exibido(s) no eixo y"
                />
                <label htmlFor="ySensors1Bar">Eixo y</label>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          // marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.cancel}
          style={{
            backgroundColor: "#FFF",
            color: "#124251",
            marginRight: "10px",
          }}
          variant="contained"
        >
          Cancelar
        </Button>
        {loadingRequest ? (
          <Button
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            <CircularProgress style={{ color: "white" }} size={20} />
          </Button>
        ) : (
          <Button
            type="submit"
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            Salvar Gráfico
          </Button>
        )}
      </div>
    </form>
  );
};
export default NewChartForm;
