import { useMemo, useState } from "react";

import "./FeedbackModal.css";
import { uploadSensors } from "../../Services/uploadSensors";
import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";
// import { flexbox } from "@mui/system";
import axios, { AxiosRequestConfig } from "axios";

const FeedbackModal = (props: any) => {
  const [file, setFile] = useState<any>(null);
  // const [disabeButtons, setDisabeButtons] = useState(false);
  let disabeButtons = useMemo<any>(() => false, []);
  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // const [openBackdrop, setOpenBackdrop] = useState(true);

  const handleDownload = async () => {
    const headers = { "Content-Type": "blob" };
    const config: AxiosRequestConfig = {
      method: "GET",
      url: "/getSheet",
      responseType: "arraybuffer",
      headers,
    };
    try {
      const response = await axios(config);
      const outputFilename = `teste.xls`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="FeedbackModal">
      <div className="mainModal">
        <div className="head">
          <p>Cadastro por Planilha</p>
        </div>
        {result === null ? (
          <div className="body" style={{ position: "relative" }}>
            {loading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.2)", // Make sure this color has an opacity of less than 1
                  backdropFilter: " blur(2px)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  borderRadius: "0 0 10px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}

            <p>TEXTO:</p>

            <input
              type="file"
              onChange={(event: any) => {
                setFile(event.target.files[0]);
              }}
            />
            <p
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleDownload}
            >
              planilha base
            </p>

            <div className="buttonsModal">
              <button
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                disabled={disabeButtons}
              >
                Cancelar
              </button>
              <button
                id="sendButton"
                disabled={disabeButtons || file === null}
                name="confirm"
                onClick={async () => {
                  setLoading(true);
                  // const wb = read(file); // parse the array buffer
                  var reader = new FileReader();
                  reader.onload = async (e: any) => {
                    const bufferArray = e?.target.result;

                    const response = await uploadSensors(
                      bufferArray,
                      props.digital_twind_id
                    );
                    setResult(response);
                  };
                  await reader.readAsArrayBuffer(file);
                }}
              >
                Enviar
              </button>
            </div>
          </div>
        ) : (
          <div className="body">
            {result[0].length > 0 && (
              <>
                <b style={{ marginBottom: "0" }}>Sensores Cadastrados:</b>
                <ul
                  style={{
                    maxHeight: "200px",
                    overflowY: "scroll",
                    marginBottom: "15px",
                  }}
                >
                  {result[0].map((element) => {
                    return <li>{element}</li>;
                  })}
                </ul>
              </>
            )}
            {result[1].length > 0 && (
              <>
                <b style={{ marginBottom: "0" }}>Sensores Não Cadastrados</b>
                <ul
                  style={{
                    maxHeight: "200px",
                    overflowY: "scroll",
                    marginBottom: "15px",
                  }}
                >
                  {result[1].map((element) => {
                    let error: string = "";
                    if (element[1].includes("missing")) {
                      error = `Coluna obrigatoria Faltando valor`;
                    } else if (
                      element[1] === "malformed value in some column"
                    ) {
                      error = "Valor incorreto em alguma Coluna";
                    } else if (element[1] === "name alredy in use") {
                      error = "Nome já Cadastrado";
                    } else if (element[1] === "invalid datatype") {
                      error = "Valor incorreto para data type";
                    } else if (element[1] === "missing datatype") {
                      error = "faltando valor para data type";
                    } else {
                      error = "Erro desconhecido";
                    }
                    return <li>{`${element[0]} - ${error}`}</li>;
                  })}
                </ul>
              </>
            )}

            <div className="buttonsModal" style={{ justifyContent: "center" }}>
              <button
                id="cancelButton"
                name="cancel"
                onClick={props.cancel}
                disabled={disabeButtons}
              >
                Fechar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FeedbackModal;
