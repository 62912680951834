import { ReactComponent as Time } from "../../../Assets/Icons/time.svg";
import Icon from "../../Icon/Icon";
import "../LastRecomendation/LastRecommendationCard.css";

const LastRecommendationCard = (props: any) => {
  return (
    <div
      id="container"
      style={{
        // opacity: started ? 1 : 0 ,transition: "opacity 0.3s ease-in-out",
        width: "100%",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "45px",
        backgroundColor:
          props.type === "recomendação"
            ? "rgba(192, 64, 64, 0.38)"
            : props.type === "Solucionado"
            ? "rgba(131, 171, 81, 0.38) "
            : props.type === "alerta"
            ? "rgba(255, 203, 5, 0.38) "
            : props.type === ""
            ? "gray"
            : "rgba(255, 203, 5, 0.38)",
        borderRadius: "4px",
      }}
    >
      <div
        style={{
          zIndex: "999",
          minWidth: "10px",
          backgroundColor:
            props.type === "recomendação"
              ? "#C04040"
              : props.type === "Solucionado"
              ? "#83AB51"
              : props.type === "alerta"
              ? "rgba(255, 203, 5) "
              : props.type === ""
              ? "#444"
              : "#FFCB05",
          borderRadius: "4px 0 0 4px",
        }}
      ></div>

      <div
        id="text"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            paddingLeft: "10px",
            width: props.type === "Solucionado" ? "150px" : "120px",
          }}
        >
          <Icon type={props.type ? props.type : ""} />

          <span
            style={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {props.type === "recomendação"
              ? "ALARME!"
              : props.type === "Solucionado"
              ? "SOLUCIONADO"
              : props.type === "alerta"
              ? "ALERTA!"
              : props.type === "" && " "}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
        >
          {props.type === "recomendação" && (
            <div
              className="mensagem"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <span>
                {props.descrição} <span>({props.eficiencia} Nm³/ton)</span>
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Time /> <b> Iniciado em: </b>
                {props.periodo}
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <b>Tempo decorrido: </b>
                {props.timer}
              </span>
            </div>
          )}
          {props.type === "recomendaçãoAtualizada" && (
            <div
              className="mensagem"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <span>
                {props.descrição} <span>({props.eficiencia} Nm³/ton)</span>
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Time /> <b>Iniciado em: </b>
                {props.periodo}
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <>
                  {!props.alerta.finished && <b>Tempo decorrido: </b>}
                  {props.timer}
                </>
              </span>
            </div>
          )}
          {props.type === "Solucionado" && (
            <div
              className="mensagem"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <span>
                {props.descrição} <span>({props.eficiencia} Nm³/ton)</span>
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Time /> <b>Concluído em: </b>
                {props.periodo}
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <b>Tempo decorrido: </b>
                {props.timer}
              </span>
            </div>
          )}
          {props.type === "alerta" && (
            <div
              className="mensagem"
              style={{
                width: "inherit",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <span>
                {props.descrição} <span>({props.eficiencia} Nm³/ton)</span>
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Time /> <b>Iniciado em: </b>
                {props.periodo} ,
              </span>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <b>
                  {!props.alerta.finished && <b>Tempo decorrido: </b>}
                  {props.timer}
                </b>
              </span>
            </div>
          )}
          {props.type === "" && (
            <div
              className="mensagem"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <b>{props.descrição}</b>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LastRecommendationCard;
