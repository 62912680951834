import { Button, CircularProgress } from "@mui/material";
// const buttonStyle = {
//   backGroundColor: "#124251",
// };

const LoadingButton = ({ loading, text, onClick }) => {
  if (loading) {
    return (
      <Button
        style={{
          backgroundColor: "#124251",
          marginLeft: "10px",
          height: "25px",
          color: "white",
          textDecoration: "none",
        }}
        variant="contained"
      >
        <CircularProgress style={{ color: "white" }} size={10} />
      </Button>
    );
  } else {
    return (
      <Button
        style={{
          backgroundColor: "#124251",
          height: "25px",
          padding: "15px 10px",
          marginLeft: "14%",
          color: "white",
          textTransform: "none",
        }}
        variant="contained"
        onClick={onClick}
      >
        {text}
        {" ✔ "}
      </Button>
    );
  }
};

export default LoadingButton;
