import { useEffect, useState } from "react";
import DbService from "../../Services/LocalDataService";
import GroupCard from "../../Components/Cards/GroupCard/GroupCard";
import "./GroupsView.css";
// import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";
import NewGroupForm from "../../Components/Forms/NewGroupForm/NewGroupForm";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { Button } from "@material-ui/core";

interface IGroup {
  id: number;
  name: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
}

const GroupsView = (props: any) => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [listedGroups, setListedGroups] = useState<IGroup[]>([]);
  const [addingGroup, setAddingGroup] = useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredSensors = groups.filter((sensor) => {
      if (sensor.name.includes(value)) {
        return sensor;
      } else {
        return null;
      }
    });
    setListedGroups(filteredSensors);
  };

  const handleResponse = () => {
    DbService.get_groups().then((data) => {
      setGroups(data);
      setListedGroups(data);
      setAddingGroup(false);
    });
  };

  useEffect(() => {
    // console.log("in use effect");
    DbService.get_groups().then((data) => {
      setGroups(data);
      setListedGroups(data);
    });
  }, []);

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText("#124251"),
    backgroundColor: "#124251",
    "&:hover": {
      backgroundColor: "#124251",
    },
  }));

  return (
    <  
    >
      
      <div >
        <Stack spacing="10px" direction="row">
      
            <ColorButton
              variant="contained"
              onClick={() => setAddingGroup(!addingGroup)}
            >
              {addingGroup ? "Cancelar" : "Adicionar Organização"}
            </ColorButton>
       
        </Stack>

     

        {!addingGroup && (
          <FormControl
            id="sensorsSearch"
            variant="outlined"
            onChange={handleSearch}
          >
            <OutlinedInput
              id="outlined-adornment-weight"
              endAdornment={
                <InputAdornment position="end">
                  <Icon />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
        )}
      </div>
      {addingGroup ? (
        <div className="form">
          <NewGroupForm handleResponse={handleResponse} />
        </div>
      ) : (
        <div className="playground">
          {groups.length === 0 ? (
            <span>Carregando...</span>
          ) : (
            listedGroups.map((group) => {
              // // console.log(groups)
              return (
                <Link to={`./${group.id}`}>
                  <GroupCard
                    key={group.id}
                    group={group}
                    style={{
                    
                    }}
                  />
                </Link>
              );
            })
          )}
        </div>
      )}
      
    </>
  );
};

export default GroupsView;
