import axios from "axios";
import { read, utils } from "xlsx";

interface Sensor {
  nome: string;
  id: number;
  deviceId: string;
  "tipo de variavel": string;
  TAG: string;
  tipo: string;
  unidade: string;
  sigla: string;
  "casas decimais": string;
  precedencia: string;
  "valor maximo sensor": number | null;
  "valor minimo sensor": number | null;
  "valor fixo sensor": number | null;
  "valor maximo processo": number | null;
  "valor minimo processo": number | null;
  "valor fixo processo": number | null;
  "data type": string;
}

export const uploadSensors = async (
  bufferArray: ArrayBuffer,
  digital_twin_id: number
) => {
  const wb = read(bufferArray, { type: "buffer" });
  const data = utils.sheet_to_json<Sensor>(wb.Sheets[wb.SheetNames[0]]);
  // console.log("sending to backend")
  const response = await axios.post("/uploadSensors", {
    sensors: data,
    digital_twin_id,
  });
  // console.log(response.data)
  return response.data;
};
