import axios from "axios";
const headers = {
  "access-control-allow-origin": "*",
};
const AuthService = {
  login: async function (email: string, password: string) {
    return await axios.post("/auth/signin", { email, password }, { headers });
  },
  singup: async function (data: any) {
    return await axios.post("/auth/singup", data, { headers });
  },
  activationCode: function (token) {
    return axios.post("/auth/singup/activation-code/" + token);
  },
  forgotPassword: async function (email: string) {
    return await axios.post("/auth/forgot-password", { email });
  },
  resetPassword: async function (newPassword: string, resetToken: string) {
    return await axios.post("/auth/reset-password", {
      newPassword,
      resetToken,
    });
  },
};
export default AuthService;
