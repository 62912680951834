import { createSlice } from "@reduxjs/toolkit";

interface AnalyticsData {
  results: any;
}

interface AnalyticsState {
  historicalData: AnalyticsData;
  frequencyData: AnalyticsData;
  alarmData: AnalyticsData;
  chartsOn: boolean;
}

const initialState: AnalyticsState = {
  historicalData: {
    results: []
  },

  frequencyData: {
    results:[]
  },

  alarmData: {
    results: []
  },

  chartsOn: false
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsHistoricalData: (state, action) => {
      state.historicalData = action.payload;
    },
    setAnalyticsFrequencyData: (state, action) => {
      state.frequencyData = action.payload;
    },
    setAnalyticsAlarmData: (state, action) => {
      state.alarmData = action.payload;
    },
    setAnalyticsChartsOn: (state, action) => {
      state.chartsOn = action.payload;
    },
    resetAnalyticsData: (state) => {
      state.historicalData = { ...initialState.historicalData };
    },
  },
});

export const getAnalyticsHistoricalData = (state) => state.analytics.historicalData;
export const getAnalyticsFrequencyData = (state) => state.analytics.frequencyData;
export const getAnalyticsAlarmData = (state) => state.analytics.alarmData;
export const getAnalyticsChartsOn = (state) => state.analytics.chartsOn;

export const { setAnalyticsHistoricalData, setAnalyticsFrequencyData, setAnalyticsAlarmData, setAnalyticsChartsOn, resetAnalyticsData } = analyticsSlice.actions;
export default analyticsSlice.reducer;
