

const getGASTECHomologationNormal = (
  frequencys1: any,
  frequencys2: any,
  values: any,
  range1: string,
  range2: string | null,
  freqRange1: string | null,
  freqRange2: string | null
) => {
  let aux: any = null;
  if (frequencys2 !== null) {
    aux = {
      data: frequencys2,
      name: range2,
      type: "line",
      smooth: true,
      color: "red",
    };
  }

  return {
    height: "auto",
  
    
    backgroundColor: "transparent",
    
    xAxis: {
      name: "Nm³/ton",
      type: "category",
      data: values,
    },
    yAxis: {
      type: "value",
      scale: true,
      data: [frequencys1, frequencys2],
      name: "Frequência",
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    toolbox: {
      top: 0,
      right: 0,
      show: true,
      feature: {
        saveAsImage: {
          title: "Baixar .png",
         
          pixelRatio: 4,
        },
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Voltar",
          },
          yAxisIndex: "all",
          brushStyle: {
            borderWidth: 1,
            color: "rgba(255, 173, 17, 0.4)",
            borderColor: "rgba(255, 173, 17, 1)",
          },
        },
        magicType: {
          title: {
            line: "Linha",
            bar: "Barra",
          },
          type: ["line", "bar"],
        },
      },
    },

    tooltip: {
      trigger: "axis",
      formatter: function (params: any) {
        const test = `${freqRange1}: ${frequencys1[params[0].dataIndex]}`;
        let test2 = "";
        if (frequencys2)
          test2 = `${freqRange2}: ${frequencys2[params[0].dataIndex]}`;

        return (
          `Valor de Eficiência: ${params[0].axisValue} Nm³/ton <br/>` +
          test +
          `<br>` +
          test2
        );
      },
      axisPointer: {
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      bottom: 0,
    },
    grid: {
      left: 20,
      right: 80,
      top: 40,
      bottom: 25,
      containLabel: true,
    },
    series: [
      {
        data: frequencys1,
        name: range1,
        type: "line",
        smooth: true,
        
      },
      aux,
    ],
  };
};

export default getGASTECHomologationNormal;