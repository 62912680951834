import DataFormatService from "../Services/DataFormatService";
import NewDi2winDataService from "../Services/NewDi2winDataService";

interface GetChartTooltipsProps {
  chart: any;
}

const GetChartTooltips = ({
  chart,
}: GetChartTooltipsProps): Promise<any[]> | undefined => {
  let tooltip: any = [];

  tooltip = chart.tooltip.map((element) => {
    return {
      name: element.name,
      number: element.number,
      deviceId: element.device_id,
      data: [],
      unit: element.unit.abbreviation,
    };
  });

  if (chart.default_visualization === "line") {
    //checa se o eixo x deve ser o tempo
    if (chart.x_axis_sensor_id === null) {
      return fetchTooltip(tooltip, chart);
    }
  }
};

const fetchTooltip = async (tooltip: any, chart): Promise<any[]> => {
  for (const element of tooltip) {
    const response: any =
      await NewDi2winDataService.fetchMonitoringHistoricData(
        element.number,
        element.deviceId,
        chart.time,
        chart.time_range
      );

    if (response.name !== "Error") {
      const mappedAndFormattedResponse = response.map((element) => {
        return [
          element.timestamp,
          DataFormatService.floatInput(element.value, 2),
        ];
      });
      element.data = mappedAndFormattedResponse;
    }
  }
  return tooltip;
};

export default GetChartTooltips;
