import LocalDataService from "../../../Services/LocalDataService";
import { useState } from "react";
import DeleteConfirmationDialog from "../../DeleteConfirmatioDialog/DeleteConfirmationDialog";
import EditForm from "../../Forms/NewDigitalTwinForm/EditDigitalTwinForm";

const DigitalTwinDetailsCard = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleDelete = async () => {
    setModal(true);
  };

  const handleAction = async (event: any) => {
    const { name } = event.target;

    if (name === "confirm") {
      await LocalDataService.deleteDigitalTwin(props.dt.id);
      props.handleResponse();
    } else {
      setModal(false);
    }
  };
  return (
    <div className="GraphDetailsCard">
      <EditForm
        dts={props.dts}
        dt={props.dt}
        handleResponse={props.handleResponse}
        onClickDelete={handleDelete}
      />
      {modal && (
        <DeleteConfirmationDialog
          text="Certeza que quer deletar?"
          handleAction={handleAction}
        />
      )}
    </div>
  );
};
export default DigitalTwinDetailsCard;
