import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDarkTheme: false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
    },
  },
});

export const getIsDarkTheme = (state) => state.theme.isDarkTheme;

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
