import "./Icon.css";
import { ReactComponent as Check } from "../../Assets/Icons/check_white.svg";
import { ReactComponent as Alert } from "../../Assets/Icons/alert_sidebar.svg";
import { ReactComponent as Alarm } from "../../Assets/Icons/alertaRed.svg";
import { ReactComponent as Wrench } from "../../Assets/Icons/wrench.svg";

const Icon = ({ type, size }: any) => {
  if (type === "recommendationCardGreen") {
    return <Check className={`recommendationCardIcon ${size}`} />;
  }
  if (type === "alerta") {
    return <Alert className={`alerta ${size}`} />;
  }
  if (type === "recommendationCardRed") {
    return <Alarm className={`recommendationCardIcon ${size}`} />;
  }
  if (type === "recomendação") {
    return <Alarm className={`recommendationCardIcon ${size}`} />;
  }
  if (type === "Solucionado") {
    return <Check className={`recommendationCardIcon ${size}`} />;
  }
  if (type === "") {
    return null;
  } else {
    return <Wrench className={`recommendationCardIcon ${size}`} />;
  }
};
export default Icon;
